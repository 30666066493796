<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<!-- <el-card> -->
			<el-row :gutter="20">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
			</el-row>
			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" :label-width="formLabelWidth" class="demo-table-expand">
							<el-row>
								<el-col :xs="24" :sm="12">
									<el-form-item label="医院">
										<span>{{ props.row.keyperson_hospital__hospital_name }}</span>
									</el-form-item>
									<el-form-item label="科室">
										<span>{{ props.row.keyperson_dept }}</span>
									</el-form-item>
									<el-form-item label="姓名">
										<span>{{ props.row.keyperson_name }}</span>
									</el-form-item>
									<el-form-item label="性别">
										<span>{{ props.row.keyperson_gender }}</span>
									</el-form-item>
									<el-form-item label="年龄">
										<span>{{ props.row.keyperson_age }}</span>
									</el-form-item>
									<el-form-item label="职称">
										<span>{{ props.row.keyperson_job_title }}</span>
									</el-form-item>
									<el-form-item label="主要职务">
										<span>{{ props.row.keyperson_title }}</span>
									</el-form-item>
									<el-form-item label="联系方式">
										<span>{{ props.row.keyperson_contact }}</span>
									</el-form-item>
								</el-col>
								<el-col :xs="24" :sm="12">
									<el-form-item label="身份证号">
										<span>{{ props.row.keyperson_id_card }}</span>
									</el-form-item>
									<el-form-item label="学历">
										<span>{{ props.row.keyperson_education_background }}</span>
									</el-form-item>
									<el-form-item label="工作履历">
										<span>{{ props.row.keyperson_work_experience }}</span>
									</el-form-item>
									<el-form-item label="负责事务">
										<span>{{ props.row.keyperson_incharge }}</span>
									</el-form-item>
									<el-form-item label="办事风格">
										<span>{{ props.row.keyperson_work_style }}</span>
									</el-form-item>
									<el-form-item label="客情记录">
										<span>{{ props.row.keyperson_relationship_record }}</span>
									</el-form-item>
									<el-form-item label="备注">
										<span>{{ props.row.keyperson_remark }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</template>
				</el-table-column>
				
				<el-table-column label="医院" prop="keyperson_hospital__hospital_name" sortable show-overflow-tooltip />
				<el-table-column label="科室" prop="keyperson_dept" sortable show-overflow-tooltip />
				<el-table-column label="姓名" prop="keyperson_name" sortable />
				<el-table-column label="性别" prop="keyperson_gender" sortable />
				<el-table-column label="职务" prop="keyperson_title" sortable show-overflow-tooltip />
				<el-table-column label="职称" prop="keyperson_job_title" sortable show-overflow-tooltip />
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>

	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			ifFullScreen: false,
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false,
			formLabelWidth:"100px"
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/querykeyperson/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
