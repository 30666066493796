<template>
	<div>
		<el-row :gutter="10">
			<el-col :xs="24" :sm="6">
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card style="padding-bottom: 20px;">
					<el-col :xs="24" :sm="24">
						<el-input v-model="keywords" size="mini" placeholder="搜索同业公司" clearable style="margin:5px" maxlength="30" @clear="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe >
					<el-table-column label="公司">
						<template slot-scope="scope">
							<el-button v-if="scope.row.pk!=$route.params.id" type="text" @click="jumpTo('/gadashboard/', scope.row.pk, scope.row )">{{ scope.row.ga_name }}</el-button>
							<div v-else class="active">{{ scope.row.ga_name }}</div>
						</template>		
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="total, sizes, prev, pager, next"
				:total="totalItems"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="prev, pager, next"
				:total="totalItems"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				prev-text="上一页"
				next-text="下一页"
				:pager-count="5"
			/>
		</el-row>
	</div>		
			</el-col>
			<el-col :xs="24" :sm="18">
				<el-card>
					<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
						<el-tab-pane name="first">
							<span slot="label">
								<i class="el-icon-s-shop"></i>
								经销商
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="a" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="second">
							<span slot="label">
								<i class="el-icon-s-flag"></i>
								市场活动
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="b" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="third">
							<span slot="label">
								<i class="el-icon-user-solid"></i>
								市场人员
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="c" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			activeName: 'first',
			isRouterAlive: true
		};
	},
	methods: {

	}
};
</script>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				ga_name: '',
				ga_area: '',
				ga_type: '',
				ga_brand: [],
				ga_organization: '',
				ga_remark: ''
			},
			formUpdateData: {
				pk: '',
				ga_name: '',
				ga_area: '',
				ga_type: '',
				ga_brand: [],
				ga_organization: '',
				ga_remark: ''
			},
			formLabelWidth: '100px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false,
			visible: false,
			activeName: 'first',
			isRouterAlive: true,
			gaDetails:{}
			};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/generalagent/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//路由跳转
		jumpTo(name, id, data) {
			var fullpath = name + id + '/';
			this.$router.push({ path: fullpath });
			this.gaDetails = data;
			
		},
		handleClick(tab, event) {
			// console.log(tab, event);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>
