<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card>
					<el-col :xs="24" :sm="6">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" clearable style="margin:5px"
							maxlength="30" @clear="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="6" style="margin-bottom: 20px;">
						<el-button style="margin: 5px;" size="mini" type="success"
							@click.prevent="addData()">新增用户</el-button>
						<!-- <el-button style="margin: 5px;" size="mini" type="warning" @click="batchChangeAuthDate(UsermultipleSelection)">批量更新</el-button>		 -->
					</el-col>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe @selection-change="handleSelectionChange">
					<!-- <el-table-column type="selection" width="55"></el-table-column> -->
					<el-table-column label="账号" prop="username" sortable />
					<el-table-column label="姓名" prop="person_name" sortable />
					<el-table-column label="部门" prop="market_area__name" sortable />
					<el-table-column label="权限" prop="privilege" sortable>
						<template slot-scope="scope">
							<el-tag size="small" effect="dark" :type="formatTags(scope.row.privilege).type"
								disable-transitions>{{ formatTags(scope.row.privilege).text }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="已激活" prop="is_banned" sortable>
						<template slot-scope="scope">
							<el-tag size="small" effect="dark" :type="formatBannedTags(scope.row.is_banned).type"
								disable-transitions>
									<i :class="formatBannedTags(scope.row.is_banned).class"></i>
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column width="150" label="操作" align="center">
						<template slot-scope="scope">
							<el-button-group v-if="scope.row.privilege != '4'">
								<el-button size="mini" @click="updateData(scope.$index, scope.row)">修 改</el-button>
								<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删
									除</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
				:total="totalItems" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				@current-change="handleCurrentChange" @size-change="handleSizeChange" />
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next" :total="totalItems"
				:page-size="pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange"
				prev-text="上一页" next-text="下一页" :pager-count="5" />
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog title="新增用户" :visible.sync="formAddDialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :width="'60%'" :fullscreen="ifFullScreen">
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="账号名" prop="username"><el-input v-model="formAddData.username"
						clearable /></el-form-item>
				<el-form-item label="密码" prop="password"><el-input v-model="formAddData.password"
						clearable /></el-form-item>
				<el-form-item label="姓名" prop="person_name"><el-input v-model="formAddData.person_name"
						clearable /></el-form-item>
				<el-form-item label="所在部门" prop="market_area">
					<el-select v-model="formAddData.market_area" filterable placeholder="部门名称">
						<el-option v-for="item in marketAreaOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户权限" prop="privilege">
					<el-radio-group placeholder="客户类型" v-model="formAddData.privilege" size="small">
						<el-radio-button label="1">市场专员</el-radio-button>
						<el-radio-button label="1.5">大区副理</el-radio-button>
						<el-radio-button label="2">大区经理</el-radio-button>
						<el-radio-button label="3">管理员</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="账号封禁" prop="is_banned">
					<el-radio-group placeholder="客户类型" v-model="formAddData.is_banned" size="small">
						<el-radio-button label="true">是</el-radio-button>
						<el-radio-button label="false">否</el-radio-button>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog title="修改用户信息" :visible.sync="formUpdateDialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :fullscreen="ifFullScreen">
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth"
				size="mini">
				<el-form-item label="账号名" prop="username"><el-input v-model="formUpdateData.username"
						readonly="" /></el-form-item>
				<el-form-item label="密码" prop="password"><el-input v-model="formUpdateData.password"
						show-password /></el-form-item>
				<el-form-item label="姓名" prop="person_name"><el-input
						v-model="formUpdateData.person_name" /></el-form-item>
				<el-form-item label="所在部门" prop="market_area">
					<el-select v-model="formUpdateData.market_area" filterable placeholder="部门名称">
						<el-option v-for="item in marketAreaOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户权限" prop="privilege">
					<el-radio-group placeholder="客户类型" v-model="formUpdateData.privilege" size="small">
						<el-radio-button label="1">市场专员</el-radio-button>
						<el-radio-button label="1.5">大区副理</el-radio-button>
						<el-radio-button label="2">大区经理</el-radio-button>
						<el-radio-button label="3">管理员</el-radio-button>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="账号封禁" prop="is_banned">
					<el-radio-group placeholder="客户类型" v-model="formUpdateData.is_banned" size="small">
						<el-radio-button label="true">是</el-radio-button>
						<el-radio-button label="false">否</el-radio-button>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formUpdateDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				keywords: '',
				formAddDialogVisible: false,
				formUpdateDialogVisible: false,
				ifFullScreen: false,
				formAddData: {
					pk: '',
					username: '',
					password: '',
					person_name: '',
					privilege: '1',
					market_area: '',
					is_banned: false
				},
				formUpdateData: {
					pk: '',
					username: '',
					password: '',
					person_name: '',
					privilege: '',
					market_area: '',
					is_banned: ''
				},
				//新增和更新表单的验证规则
				rules: {
					username: [{
						required: true,
						message: '请输入用户账号',
						trigger: 'blur'
					}, {
						max: 40,
						message: '长度不超过20个字符',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入用户密码',
						trigger: 'blur'
					}, {
						max: 40,
						message: '长度不超过20个字符',
						trigger: 'blur'
					}],
					person_name: [{
						required: true,
						message: '请输入用户姓名',
						trigger: 'blur'
					}, {
						max: 40,
						message: '长度不超过20个字符',
						trigger: 'blur'
					}],
					market_area: [{
						required: true,
						message: '请选择用户所属部门',
						trigger: 'blur'
					}],
					privilege: [{
						required: true,
						message: '请选择用户权限',
						trigger: 'change'
					}],
					is_banned: [{
						required: true,
						message: '请选择账号状态',
						trigger: 'change'
					}]
				},
				formLabelWidth: '80px',
				totalItems: 100,
				currentPage: 1,
				pageSize: 20,
				deleteItemPk: '',
				loading: false,
				marketAreaOptions: [],
				UsermultipleSelection: [],
				UserAreaBatchChangeDialogVisible: false,
				userPrivilege: localStorage.getItem('userPrivilege')
			};
		},
		methods: {
			//通用函数-获取数据
			getData(keywords, currentPage, pageSize) {
				var that = this;
				that.loading = true;
				this.$axios
					.get('/api/get/users/', {
						params: {
							keywords: keywords,
							currentpage: currentPage,
							pagesize: pageSize
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.tableData = response.data.data;
							that.totalItems = response.data.totalItemNum;
							that.loading = false;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//通用功能 - 关键字搜索
			Search() {
				var that = this;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//新增数据-对话框
			addData() {
				this.formAddDialogVisible = true;
			},
			//新增数据-提交
			submitAddForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formAddData;
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						this.$axios({
								url: '/api/add/users/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.reload();
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//新增数据 - 重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.formAddDialogVisible = false;
			},
			//更新数据 - 对话框
			updateData(index, row) {
				//解除双向绑定
				this.formUpdateData = JSON.parse(JSON.stringify(row));
				this.formUpdateDialogVisible = true;
				console.log(this.formUpdateData);
			},
			//更新数据-提交
			submitUpdateForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formUpdateData;
						//遍历添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						this.$axios({
								url: '/api/update/users/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.getData(that.keywords, that.currentPage, that.pageSize);
									that.formUpdateDialogVisible = false;
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//删除数据 - 对话框
			deleteData(index, row) {
				let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
				this.$confirm('此操作将永久删除该用户账号及所有相关工作日志记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.submitDeleteData(deleteItemPk);
					})
					.catch(() => {});
			},
			//删除数据-提交
			submitDeleteData(dataPk) {
				var that = this;
				var fd = new FormData();
				fd.append('pk', dataPk);
				this.$axios({
						url: '/api/delete/user/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res.data);
						if (res.data.code == '200') {
							that.reload();
						} else {
							that.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//分页跳转
			handleCurrentChange(val) {
				var that = this;
				this.currentPage = val;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//每页显示条数更改
			handleSizeChange(val) {
				var that = this;
				this.pageSize = val;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			// 获取用户市场列表
			getMarketAreaOptions() {
				var that = this;
				this.$axios
					.get('/api/get/userlistoption/')
					.then(function(response) {
						that.marketAreaOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//数据表格多选状态修改
			handleSelectionChange(val) {
				this.UsermultipleSelection = val;
				// console.log(val);
			},
			//标签格式化
			formatTags(value) {
				let rlt = {};
				if (value == 1) {
					rlt = {
						type: 'info',
						text: '市场专员'
					};
				} else if (value == 1.5) {
					rlt = {
						type: 'success',
						text: '大区副理'
					};
				} else if (value == 2) {
					rlt = {
						type: '',
						text: '大区经理'
					};
				} else if (value == 3) {
					rlt = {
						type: 'warning',
						text: '管理员'
					};
				} else if (value == 4) {
					rlt = {
						type: 'danger',
						text: '超级管理员'
					};
				}
				return rlt;
			},
			//
			formatBannedTags(value) {
				let rlt = {};
				if (value == true) {
					rlt = {
						type: 'danger',
						class: 'el-icon-error'
					};
				} else if (value == false) {
					rlt = {
						type: 'success',
						class: 'el-icon-success'
					};
				} 
				return rlt;
			},
		},
		mounted() {
			var that = this;
			this.getData('', that.currentPage, that.pageSize);
			this.getMarketAreaOptions();
			that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
		}
	};
</script>

<style></style>