<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="10">
			<el-col>
				<el-card>
					<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
						<el-form-item prop="user"><el-cascader v-model="searchData.user" :options="userListOptions"
								clearable placeholder="用户名单" /></el-form-item>
						<el-form-item prop="date_range">
							<el-date-picker v-model="searchData.date_range" type="daterange" align="right" unlink-panels
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
								:picker-options="pickerOptions" value-format="yyyy-MM-dd" />
						</el-form-item>
						<el-form-item prop="task_type"><el-cascader v-model="searchData.task_type"
								:options="taskTypeOptions" clearable placeholder="日志类型" /></el-form-item>
						<el-form-item prop="keywords"><el-input v-model="searchData.keywords" placeholder="关键字"
								clearable maxlength="20" /></el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitFormSearchData()">搜索</el-button>
							<el-button @click="resetSearchForm('formSearch')">重置</el-button>
							<el-button type="success"
								@click="exportSubTaskData(searchData, '导出日志','/api/get/subtasks/excel/')">分条导出</el-button>
							<el-button type="danger"
								@click="exportSubTaskData(searchData, '导出日志','/api/get/maintasks/excel/')">合并导出</el-button>
						</el-form-item>
					</el-form>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); margin-top: 15px; " class="hidden-sm-and-down">
			<el-col>
				<el-table :data="tableData" style="width: 100%" stripe
					:default-sort="{ prop: 'date', order: 'descending' }" tooltip-effect="dark">
					<el-table-column label="发生日期" sortable prop="task_time" />
					<el-table-column prop="person_name" sortable label="报告人" />
					<el-table-column prop="itinerary" label="行程" />
					<el-table-column prop="customer_visit_num" label="拜访客户数" />
					<el-table-column label="任务" min-width="300">
						<template slot-scope="props">
							<div v-for="(activity, index) in props.row.subtasks" :key="index">
								<p>
									{{ index + 1 }}. {{ activity.maintask_type }} - {{ activity.subtask_type }}
									<br />
									{{ activity.description }}
								</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="附件">
						<template slot-scope="props">
							<el-link v-for="(item, idx) in props.row.files" :key="item.id" icon="el-icon-download"
								:underline="false" type="primary" @click="handleFileClick(item)"
								style="display: block;">
								文件{{ idx + 1 }}
							</el-link>
							<el-dialog :visible.sync="dialogVisible" width="80%">
								<el-image :src="previewImages" alt="" fit="scale-down" style="max-height: 800px; width: auto;" />
							</el-dialog>
						</template>
					</el-table-column>
					<el-table-column label="提交时间" min-width="90">
						<template slot-scope="scope">
							<i class="el-icon-time" />
							<span style="margin-left: 5px">{{ scope.row.create_date }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="create_date" label="状态">
						<template slot-scope="scope">
							<el-tag size="small" :type="getSubmitState(scope.row.submit_state).tag_type"
								disable-transitions effect="dark">
								<i :class="getSubmitState(scope.row.submit_state).i_class" />
								<span style="margin-left: 5px;" v-html="getSubmitState(scope.row.submit_state).mes" />
							</el-tag>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>

		<el-row :gutter="10" class="hidden-sm-and-up">
			<el-col v-for="item in tableData" :key="item.id" style="margin-top: 5px;">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>{{ item.person_name }}</span>
						<el-tag style="float: right; margin-left: 2px;" size="small"
							:type="dateCompare(item.task_time, item.create_date).tag_type" disable-transitions
							effect="dark">
							<i :class="dateCompare(item.task_time, item.create_date).i_class" />
							<span style="margin-left: 5px;"
								v-html="dateCompare(item.task_time, item.create_date).mes" />
						</el-tag>
						<el-tag style="float: right;" size="small" disable-transitions effect="dark"
							type="">{{ item.task_time }}</el-tag>
					</div>
					<p>行程：{{ item.itinerary }}</p>
					<p v-for="subitem in item.subtasks" :key="subitem.pk">
						{{ subitem.maintask_type }} - {{ subitem.subtask_type }}
						<br />
						{{ subitem.description }}
					</p>
				</el-card>
			</el-col>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
				:total="totalItems" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				@current-change="handleCurrentChange" @size-change="handleSizeChange" />
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next" :total="totalItems"
				:page-size="pageSize" prev-text="上一页" next-text="下一页" :pager-count="5"
				@current-change="handleCurrentChange" @size-change="handleSizeChange" />
		</el-row>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				dialogVisible: false,
				previewImages: '',
				ifExpandAll: false,
				loading: false,
				totalItems: 100,
				currentPage: 1,
				pageSize: 20,
				searchData: {
					user: [],
					task_type: [],
					date_range: [],
					keywords: ''
				},
				taskTypeOptions: [],
				userListOptions: [],
				pickerOptions: {
					shortcuts: [{
							text: '最近一周',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: '最近一个月',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: '最近三个月',
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
								picker.$emit('pick', [start, end]);
							}
						}
					]
				},
				tableData: [],
				userList: ''
			};
		},
		mounted() {},
		created() {
			var that = this;
			that.getTaskTypeOptions();
			that.getUserListOptions();
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		methods: {
			// 图片文件预览
			handleFileClick(item) {
				const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
				const fileExtension = item.file.split('.').pop().toLowerCase();

				if (imageExtensions.includes(fileExtension)) {
					this.previewImages = this.$domainPrefix + item.file
					this.dialogVisible = true;
				} else {
					window.open(this.$domainPrefix + item.file, '_blank');
				}
			},
			// 关闭图片预览
			closelightbox() {
				this.dialogVisible = false;
			},
			// 获取工作任务分类列表
			getTaskTypeOptions() {
				let that = this;
				this.$axios
					.get('/api/get/tasktypeoption/')
					.then(function(response) {
						that.taskTypeOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 获取用户列表
			getUserListOptions() {
				var that = this;
				this.$axios
					.get('/api/get/userlistoption/')
					.then(function(response) {
						that.userListOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//分页跳转
			handleCurrentChange(val) {
				var that = this;
				this.currentPage = val;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//每页显示条数更改
			handleSizeChange(val) {
				var that = this;
				this.pageSize = val;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//通用函数-获取数据
			getData(queryData, currentPage, pageSize) {
				document.body.scrollTop = 0
				document.documentElement.scrollTop = 0
				window.pageYOffset = 0
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}
				fd.append('currentpage', currentPage);
				fd.append('pagesize', pageSize);

				this.$axios({
						url: '/api/get/maintask/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.tableData = response.data.data;
							that.totalItems = response.data.totalItemNum;
							that.loading = false;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//提交搜索
			submitFormSearchData() {
				var that = this;
				this.currentPage = 1;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//重置搜索表单
			resetSearchForm(formName) {
				var that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//日期比较 - 判断日志是否晚交
			dateCompare(report_date, submit_date) {
				// 当前时间
				let formated_report_day = new Date(report_date);
				let formated_submit_day = new Date(submit_date);
				// 3天后的24点前
				let late_day = formated_report_day.valueOf() + 3600 * 1000 * (24 * 4 - 8);
				// 15天后的24点前
				let absent_day = formated_report_day.valueOf() + 3600 * 1000 * (24 * 16 - 8);
				let formated_late_day = new Date(late_day);
				let formated_absent_day = new Date(absent_day);

				//格式化时间
				let rlt = {};
				if (formated_submit_day > formated_absent_day) {
					//旷交
					rlt = {
						tag_type: 'danger',
						i_class: 'el-icon-error',
						mes: '旷交'
					};
				} else if (formated_submit_day > formated_late_day) {
					//晚交
					rlt = {
						tag_type: 'warning',
						i_class: 'el-icon-warning',
						mes: '迟交'
					};
				} else {
					//准时提交
					rlt = {
						tag_type: 'success',
						i_class: 'el-icon-success',
						mes: '准时'
					};
				}
				return rlt;
			},
			//提交状态标签展示
			getSubmitState(submit_state_code) {
				let rlt = {};
				if (submit_state_code === 'absense') {
					//旷交
					rlt = {
						tag_type: 'danger',
						i_class: 'el-icon-error',
						mes: '旷交'
					};
				}
				if (submit_state_code === 'delay') {
					//晚交
					rlt = {
						tag_type: 'warning',
						i_class: 'el-icon-warning',
						mes: '迟交'
					};
				}
				if (submit_state_code === 'ontime') {
					//准时提交
					rlt = {
						tag_type: 'success',
						i_class: 'el-icon-success',
						mes: '准时'
					};
				}
				return rlt;
			},

			//下载数据
			exportSubTaskData(queryData, filename, url) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}

				let fileName = filename + Date.now();

				this.$axios({
						url: url,
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style></style>