<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-col>
				<el-table :data="tableData" style="width: 100%" stripe :default-sort="{ prop: 'date', order: 'descending' }" tooltip-effect="dark">
					<el-table-column label="日期" sortable prop="task_time" />
					<el-table-column prop="person_name" label="人员" sortable/>
					<el-table-column prop="itinerary" label="行程" />
					<el-table-column label="服务内容"  min-width="300">
						<template slot-scope="props">
							<div v-for="(activity, index) in props.row.subtasks" :key="index">
								<p>
									{{ index +1 }}. {{ activity.maintask_type }} - {{ activity.subtask_type }}
									<br />
									{{ activity.description }}
								</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="total, sizes, prev, pager, next"
				:total="totalItems"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="prev, pager, next"
				:total="totalItems"
				:page-size="pageSize"
				prev-text="上一页"
				next-text="下一页"
				:pager-count="5"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			ifExpandAll: false,
			loading: false,
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			searchData: {
				user: [],
				task_type: [],
				date_range: [],
				keywords: ''
			},
			tableData: [],
			userList: ''
		};
	},
	mounted() {},
	created() {
		var that = this;
		this.getData(that.currentPage, that.pageSize);
	},
	methods: {
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.currentPage, that.pageSize);
		},
		//通用函数-获取数据
		getData(currentPage, pageSize) {
			var that = this;
			that.loading = true;	
			this.$axios
				.get('/apim/get/csrecord/', {
					params: {
						currentpage: currentPage,
						pagesize: pageSize,
						cm_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		}
	}
};
</script>

<style></style>
