<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<!-- <el-card> -->
			<el-row :gutter="20">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success" @click="addData()">新增市场活动</el-button>
					<!-- <el-button style="margin: 5px;" size="mini" @click="exportExcel()">导出</el-button> -->
				</el-col>
			</el-row>
			<el-table :data="tableData" style="width: 100%; " stripe :default-sort="{ prop: 'date', order: 'descending' }">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" class="demo-table-expand">
							<el-row>
								<el-col :xs="24" :sm="12">
									<el-form-item label="开始日期">
										<span>{{ props.row.ms_start_date }}</span>
									</el-form-item>
									<el-form-item label="结束日期">
										<span>{{ props.row.ms_end_date }}</span>
									</el-form-item>
									<el-form-item label="开展地区">
										<span>{{ props.row.ms_area }}</span>
									</el-form-item>
									<el-form-item label="开展内容">
										<span>{{ props.row.ms_events }}</span>
									</el-form-item>
								</el-col>
								<el-col :xs="24" :sm="12">
									<el-form-item label="物资投入">
										<span>{{ props.row.ms_goods }}</span>
									</el-form-item>
									<el-form-item label="人员投入">
										<span>{{ props.row.ms_people }}</span>
									</el-form-item>
									<el-form-item label="资金投入">
										<span>{{ props.row.ms_amount }}</span>
									</el-form-item>
									<el-form-item label="上报人">
										<span>{{ props.row.ms_user__person_name }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</template>
				</el-table-column>

				<el-table-column label="开始日期" prop="ms_start_date" show-overflow-tooltip sortable />
				<el-table-column label="结束日期" prop="ms_end_date" sortable />
				<el-table-column label="开展地区" prop="ms_area" sortable />
				<el-table-column label="开展内容" prop="ms_events" show-overflow-tooltip />
				<el-table-column label="上报人" prop="ms_user__person_name" show-overflow-tooltip />
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" @click="updateData(scope.$index, scope.row)">编 辑</el-button>
							<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增市场活动记录"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="起始日期" prop="ms_start_date">
					<el-date-picker v-model="formAddData.ms_start_date" type="date" placeholder="选择起始日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束日期" prop="ms_end_date">
					<el-date-picker v-model="formAddData.ms_end_date" type="date" placeholder="选择结束日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="开展地区" prop="ms_area"><el-input v-model="formAddData.ms_area" clearable /></el-form-item>
				<el-form-item label="开展事项" prop="ms_events"><el-input v-model="formAddData.ms_events" clearable /></el-form-item>
				<el-form-item label="物资投入" prop="ms_goods"><el-input v-model="formAddData.ms_goods" type="text" clearable /></el-form-item>
				<el-form-item label="人员投入" prop="ms_people"><el-input v-model="formAddData.ms_people" type="text" clearable /></el-form-item>
				<el-form-item label="资金投入" prop="ms_amount">
					 <el-input-number v-model="formAddData.ms_amount" :precision="2" :step="0.1" :max="1000000"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改市场活动记录"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="起始日期" prop="ms_start_date">
					<el-date-picker v-model="formUpdateData.ms_start_date" type="date" placeholder="选择起始日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束日期" prop="ms_end_date">
					<el-date-picker v-model="formUpdateData.ms_end_date" type="date" placeholder="选择结束日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="开展地区" prop="ms_area"><el-input v-model="formUpdateData.ms_area" clearable /></el-form-item>
				<el-form-item label="开展事项" prop="ms_events"><el-input v-model="formUpdateData.ms_events" clearable /></el-form-item>
				<el-form-item label="物资投入" prop="ms_goods"><el-input v-model="formUpdateData.ms_goods" clearable /></el-form-item>
				<el-form-item label="人员投入" prop="ms_people"><el-input v-model="formUpdateData.ms_people" clearable /></el-form-item>
				<el-form-item label="资金投入" prop="ms_amount">
					<el-input-number v-model="formUpdateData.ms_amount" :precision="2" :step="0.1" :max="1000000"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateData')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { exportToExcel } from '@/api/utils.js';
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			fileList: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				ms_user__person_name: '',
				ms_start_date: '',
				ms_end_date: '',
				ms_area: '',
				ms_events: '',
				ms_goods: '',
				ms_people: '',
				ms_amount: ''
			},
			formUpdateData: {
				pk: '',
				ms_user__person_name: '',
				ms_start_date: '',
				ms_end_date: '',
				ms_area: '',
				ms_events: '',
				ms_goods: '',
				ms_people: '',
				ms_amount: ''
			},
			//新增和更新表单的验证规则
			rules: {
				ms_area: [{ required: true, message: '请填写市场活动地区', trigger: 'blur' }],
				ms_events: [{ required: true, message: '请填写市场活动内容', trigger: 'blur' }],
				ms_start_date: [{ required: true, message: '请选起始日期', trigger: 'change' }],
				ms_end_date: [{ required: true, message: '请选择结束日期', trigger: 'change' }]
			},
			formLabelWidth: '150px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/marketsupport/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						ga_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
			this.fileList = [];
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
						// console.log(formData[key])
					}
					fd.append('ga_pk', that.$route.params.id);
					this.$axios({
						url: '/apim/add/marketsupport/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							// console.log(res.data);
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.resetForm('formAddData');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增或更新数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.fileList = [];
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/marketsupport/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/marketsupport/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.getData(that.keywords, that.currentPage, that.pageSize);
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//导出excel
		exportExcel() {
			exportToExcel('distributor', '经销商', this);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
