<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="user_dist">
						<el-select v-model="searchData.user_dist" multiple placeholder="请选择部门">
							<el-option v-for="item in userListOptions" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="month" align="right" placeholder="选择月"
							value-format="yyyy-MM" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看统计</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<el-button type="success" @click="exportStaticData(searchData, '日志提交统计表')">导出</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>
		<el-row :gutter="5" class="margin-bottom-sm">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-table :data="tableData" style="width: 100%"
						:default-sort="{ prop: 'ontime_submit_rate', order: 'descending' }" stripe height="300">
						<el-table-column prop="user_name" label="姓名"></el-table-column>
						<el-table-column prop="total_customer_visit_num" label="拜访客户数" sortable></el-table-column>
						<el-table-column label="提交次数" sortable>
							<template slot-scope="scope">
								{{ scope.row.total_submit }}/ {{ scope.row.month_working_day_num }}
							</template>
						</el-table-column>
						<el-table-column prop="late_submit" label="迟交次数" sortable></el-table-column>
						<el-table-column prop="absent_submit" label="旷交次数" sortable></el-table-column>
						<el-table-column prop="submit_complete_rate" label="提交完成率" sortable>
							<template slot-scope="scope">
								<el-progress
									:percentage="scope.row.submit_complete_rate > 100? 100: scope.row.submit_complete_rate"
									:text-inside="true" :stroke-width="20" :color="customColors"
									text-color="#fff"></el-progress>
							</template>
						</el-table-column>

						<el-table-column prop="ontime_submit_rate" label="准时提交率" sortable>
							<template slot-scope="scope">
								<el-progress
									:percentage="scope.row.ontime_submit_rate > 100? 100: scope.row.ontime_submit_rate"
									:text-inside="true" :stroke-width="20" :color="customColors"
									text-color="#fff"></el-progress>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
		<el-row>
			<el-col :sm="24" :xs="24">
				<div class="plate-card">
					<v-chart :options="bar" autoresize theme="light" />
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				loading: false,
				searchData: {
					user_dist: [],
					date_range: ''
				},
				tableData: [],
				userListOptions: [],
				percentage: 80,
				customColors: [{
						color: '#ff0000',
						percentage: 20
					},
					{
						color: '#e6a23c',
						percentage: 40
					},
					{
						color: '#5cb87a',
						percentage: 60
					},
					{
						color: '#1989fa',
						percentage: 80
					},
					{
						color: '#6f7ad3',
						percentage: 100
					},
					{
						color: '#f56c6c',
						percentage: 150
					}
				],
				bar: {
					title: {
						text: '月准时提交率一览',
						top: 20,
						left: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: '{b}:{c}%'
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['line', 'bar']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					xAxis: {
						type: 'category',
						data: ['3/1', '3/2', '3/3', '3/4', '3/5', '3/6', '3/7', '3/8', '3/9', '3/10', '3/11', '3/12',
							'3/13', '3/14', '3/15', '3/16'
						]
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: '准时提交率',
						type: 'bar',
						label: {
							show: true,
							position: 'top',
							distance: 10,
							align: 'center',
							verticalAlign: 'middle',
							rotate: 0,
							formatter: '{c}%',
							fontSize: 12,
							rich: {
								name: {
									textColor: '#55aaff'
								}
							}
						},
						data: [2, 3, 1, 0, 7, 11, 13, 22, 12, 20, 15, 8, 7, 11, 13, 2],
						markPoint: {
							data: [{
								type: 'max',
								name: '最大值'
							}, {
								type: 'min',
								name: '最小值'
							}]
						},
						markLine: {
							data: [{
								type: 'average',
								name: '平均值'
							}]
						}
					}],
					animationDuration: 2000
				}
			};
		},
		mounted() {
			// 获取数据
			this.getUserListOptions();
		},
		created() {
			this.getDashboardData(this.searchData);
		},
		methods: {
			// 获取用户列表
			getUserListOptions() {
				let that = this;
				this.$axios
					.get('/api/get/userlistoption/')
					.then(function(response) {
						that.userListOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//重置搜索表单
			resetSearchForm(formName) {
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.getDashboardData(this.searchData);
			},

			//提交搜索
			submitFormSearchData() {
				console.log(this.searchData);
				this.getDashboardData(this.searchData);
			},

			//获取仪表板数据
			getDashboardData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				this.$axios({
						url: '/api/get/taskdashboard/muti/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						if (res.data.code == '401') {
							that.logout();
						} else {
							that.bar.xAxis.data = res.data.xAxis_data;
							that.bar.series[0].data = res.data.series_data;
							that.bar.title.text = res.data.current_month + '月准时提交率';
							that.tableData = res.data.static_data;
							that.loading = false;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//下载数据
			exportStaticData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}

				const today = new Date()
				const formated_today = today.getFullYear() + '-' + (today.getMonth() + 1)
				let fileName = filename + (that.searchData.date_range || formated_today);

				this.$axios({
						url: '/api/get/taskdashboard/excel/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.ms-excel'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						setTimeout(() => {
							that.loading = false;
						}, 1000);
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {

						setTimeout(() => {
							that.loading = false;
						}, 1000);
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 500px;
	}

	.margin-bottom-sm {
		margin-bottom: 15px;
	}
</style>