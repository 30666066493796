<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini"  @click="showAreaPriceDialog()">区域统计</el-button>
				</el-col>
				<el-col :xs="24" :sm="10" :md="10" style="margin-bottom: 20px;">
					<el-tag type="danger" size="medium">最高：{{ static_data.marketshare_price__max }} 元</el-tag>
					<el-tag type="success" size="medium">最低：{{ static_data.marketshare_price__min }} 元</el-tag>
					<el-tag size="medium">平均：{{ toDecimal(static_data.marketshare_price__avg) }} 元</el-tag>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe max-height="600">
				<el-table-column label="地区" prop="marketshare_hospital__hospital_area" show-overflow-tooltip sortable />
				<el-table-column label="医院" prop="marketshare_hospital__hospital_name" show-overflow-tooltip sortable />
				<el-table-column label="科室" prop="marketshare_dept" sortable />
				<el-table-column label="单价(元)" prop="marketshare_price" sortable />
				<el-table-column label="供货商" prop="marketshare_supplier" show-overflow-tooltip />
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>
		<!-- 区域价格主对话框 -->
		<el-dialog title="地区价格统计" :visible.sync="areaPriceDialogVisible" :fullscreen="ifFullScreen">
			<el-row>
				<el-col>
					<el-table :data="areaPriceTableData" style="width: 100%; " stripe max-height="500">
						<el-table-column label="地区" prop="prod_area" />
						<el-table-column label="最高" prop="marketshare_price__max" sortable />
						<el-table-column label="最低" prop="marketshare_price__min" sortable />
						<el-table-column label="均价" prop="marketshare_price__avg" sortable>
							<template slot-scope="props">
								{{ toDecimal(props.row.marketshare_price__avg) }}
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formLabelWidth: '150px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false,
			static_data: {},
			areaPriceDialogVisible: false,
			areaPriceTableData: [],
			ifFullScreen: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/productprices/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						prod_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.static_data = response.data.static_data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		showAreaPriceDialog() {
			this.areaPriceDialogVisible = true;
			var that = this;
			this.$axios
				.get('/apim/get/areaprices/', {
					params: {
						prod_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.areaPriceTableData = response.data.data;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		toDecimal(x) {
			// console.log(x)
			if (typeof x == 'undefined' || x ==null) {
				return x;
			} 
			else {
				return Number(x).toFixed(2);
			}
		}
	},
	mounted() {
		let that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
