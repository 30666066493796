<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-card>
				<el-row :gutter="15">
					<el-col>
						<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
							<el-form-item prop="area">
								<el-select v-model="searchData.area" filterable multiple collapse-tags placeholder="省份">
									<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item prop="dept">
								<el-select v-model="searchData.dept" filterable multiple collapse-tags placeholder="科室">
									<el-option v-for="item in deptOptions" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item prop="brand">
								<el-select v-model="searchData.brand" placeholder="品牌">
									<el-option v-for="item in prodBrandOptions" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item prop="keywords"><el-input v-model="searchData.keywords" placeholder="医院/产品"
									clearable maxlength="20" />
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitFormSearchData()">搜索</el-button>
								<el-button @click="resetSearchForm('formSearch')">重置</el-button>
								<el-button type="success" @click="exportData(searchData, '医院使用产品数据')">导出</el-button>
							</el-form-item>
						</el-form>
					</el-col>
				</el-row>

				<el-table :data="tableData" style="width: 100%; margin: 10px 0;" stripe>
					<!-- 				<el-table-column type="expand">
					<template slot-scope="scope">
						<el-table :data="scope.row.product_usage" style="width: 100%; " border>
							<el-table-column label="品牌" prop="productusage_product_brand" />
							<el-table-column label="品类" prop="productusage_product_category" />
							<el-table-column label="品名" prop="productusage_product_name" show-overflow-tooltip />
							<el-table-column label="用量(桶/月)" prop="productusage_monthly_usage" />
							<el-table-column label="市场价格(元)" prop="productusage_market_price" />
							<el-table-column label="备注" prop="productusage_remark" />
							<el-table-column label="最后更新" prop="update_date">
								<template slot-scope="scope">
									{{
										scope.row.update_date
											.split('.')
											.slice(0, 1)
											.join('')
											.replace('T',' ')
									}}
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column> -->
					<el-table-column label="省份" prop="dept_hospital__hospital_area" show-overflow-tooltip sortable />
					<el-table-column label="市/地区" prop="dept_hospital__hospital_city" show-overflow-tooltip sortable />
					<el-table-column label="医院" prop="dept_hospital__hospital_name" show-overflow-tooltip sortable
						min-width="120" />
					<el-table-column label="科室" prop="dept_name" sortable />
					<el-table-column label="手术量" prop="dept_workload" show-overflow-tooltip />
					<el-table-column label="品牌" prop="productusage_product_brand" />
					<el-table-column label="品类" prop="productusage_product_category" />
					<el-table-column label="品名" prop="productusage_product_name" show-overflow-tooltip />
					<el-table-column label="用量(桶/月)" prop="productusage_monthly_usage" />
					<el-table-column label="市场价格(元)" prop="productusage_market_price" />
					<el-table-column label="备注" prop="productusage_remark" />
					<el-table-column label="填报人" prop="productusage_user__person_name" />
					<el-table-column label="最后更新" prop="update_date">
						<template slot-scope="scope">
							{{
							scope.row.update_date
								.split('.')
								.slice(0, 1)
								.join('')
								.replace('T',' ')
						}}
						</template>
					</el-table-column>
				</el-table>

				<!-- 分页 -->
				<el-row class="paginator hidden-sm-and-down">
					<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
						:total="totalItems" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
						@current-change="handleCurrentChange" @size-change="handleSizeChange" />
				</el-row>

				<el-row class="paginator hidden-sm-and-up">
					<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next"
						:total="totalItems" :page-size="pageSize" @current-change="handleCurrentChange"
						@size-change="handleSizeChange" prev-text="上一页" next-text="下一页" :pager-count="5" />
				</el-row>
			</el-card>
		</el-row>

		<el-row>
			<el-col :xs="24" :sm="24">
				<el-card style="margin-top: 10px;">
					<el-col :xs="24" :sm="24">
						<el-tabs v-model="barChartActiveName">
							<el-tab-pane label="填报数据统计-按地区" name="bar_area">
								<div class="plate-card">
									<v-chart :options="bar_chart_area" autoresize></v-chart>
								</div>
							</el-tab-pane>
							<el-tab-pane label="填报数据统计-按用户" name="bar_user">
								<div class="plate-card"><v-chart :options="bar_chart_user" autoresize></v-chart>
								</div>
							</el-tab-pane>
						</el-tabs>
					</el-col>
				</el-card>
			</el-col>
		</el-row>

	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				ifFullScreen: false,
				totalItems: 100,
				currentPage: 1,
				pageSize: 20,
				loading: false,
				formLabelWidth: '100px',

				searchData: {
					area: [],
					dept: [],
					category: [],
					keywords: '',
					brand: '',
				},
				barChartActiveName: 'bar_area',
				prodTypeOptions: [],
				prodBrandOptions: [{
					value: '鲁沃夫',
					label: '鲁沃夫'
				}, {
					value: '竞品',
					label: '竞品'
				}, ],
				provinceOptions: [{
						value: '北京',
						label: '北京'
					},
					{
						value: '天津',
						label: '天津'
					},
					{
						value: '上海',
						label: '上海'
					},
					{
						value: '重庆',
						label: '重庆'
					},
					{
						value: '河北',
						label: '河北'
					},
					{
						value: '山西',
						label: '山西'
					},
					{
						value: '辽宁',
						label: '辽宁'
					},
					{
						value: '吉林',
						label: '吉林'
					},
					{
						value: '黑龙江',
						label: '黑龙江'
					},
					{
						value: '江苏',
						label: '江苏'
					},
					{
						value: '浙江',
						label: '浙江'
					},
					{
						value: '安徽',
						label: '安徽'
					},
					{
						value: '福建',
						label: '福建'
					},
					{
						value: '江西',
						label: '江西'
					},
					{
						value: '山东',
						label: '山东'
					},
					{
						value: '河南',
						label: '河南'
					},
					{
						value: '湖北',
						label: '湖北'
					},
					{
						value: '湖南',
						label: '湖南'
					},
					{
						value: '广东',
						label: '广东'
					},
					{
						value: '甘肃',
						label: '甘肃'
					},
					{
						value: '四川',
						label: '四川'
					},
					{
						value: '贵州',
						label: '贵州'
					},
					{
						value: '海南',
						label: '海南'
					},
					{
						value: '云南',
						label: '云南'
					},
					{
						value: '青海',
						label: '青海'
					},
					{
						value: '陕西',
						label: '陕西'
					},
					{
						value: '广西',
						label: '广西'
					},
					{
						value: '西藏',
						label: '西藏'
					},
					{
						value: '宁夏',
						label: '宁夏'
					},
					{
						value: '新疆',
						label: '新疆'
					},
					{
						value: '内蒙',
						label: '内蒙'
					},
					{
						value: '澳门',
						label: '澳门'
					},
					{
						value: '香港',
						label: '香港'
					},
					{
						value: '台湾',
						label: '台湾'
					}
				],
				deptOptions: [{
						"item": "CSSD",
						"value": "CSSD"
					},
					{
						"item": "内镜中心",
						"value": "内镜中心"
					},
					{
						"item": "其他",
						"value": "其他"
					},
				],
				bar_chart_area: {},
				bar_chart_user: {},
				//标准条形图设置
				bar_chart_record: {
					title: {
						text: '填报数量统计（按地区）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
					},
					toolbox: {
						show: true,
						orient: 'horizontal',
						left: 'right',
						top: 'left',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					yAxis: [{
						type: 'value',
					}],
					xAxis: [{
						type: 'category',
						data: ['上海', '江苏', '北京', '浙江', '河南', '河北']
					}],
					series: [{
							name: '填报数量',
							type: 'bar',
							emphasis: {
								focus: 'series'
							},
							data: [200, 444, 666, 123, 321, 555]
						},

					]
				},
			};
		},
		methods: {
			//通用函数-获取数据
			getData(queryData, currentPage, pageSize) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				fd.append('currentpage', currentPage);
				fd.append('pagesize', pageSize);

				this.$axios({
						url: '/apim/query/product_usage/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.tableData = response.data.data;
							that.totalItems = response.data.totalItemNum;
							that.loading = false;
							that.bar_chart_area.title.text = "填报数据统计-按地区"
							that.bar_chart_area.xAxis[0].data = response.data.bar_chart_area_xAxis_0_data;
							that.bar_chart_area.series[0].data = response.data.bar_chart_area_series_0_data;

							that.bar_chart_user.title.text = "填报数据统计-按地区"
							that.bar_chart_user.xAxis[0].data = response.data.bar_chart_user_xAxis_0_data;
							that.bar_chart_user.series[0].data = response.data.bar_chart_user_series_0_data

						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//通用功能 - 关键字搜索
			Search() {
				let that = this;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},

			//分页跳转
			handleCurrentChange(val) {
				let that = this;
				this.currentPage = val;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//每页显示条数更改
			handleSizeChange(val) {
				let that = this;
				this.pageSize = val;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			// 获取品类列表
			getProdTypeOptions() {
				let that = this;
				this.$axios
					.get('/apim/retrieve/product_category/')
					.then(function(response) {
						that.prodTypeOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//提交搜索
			submitFormSearchData() {
				let that = this;
				this.getData(that.searchData, that.currentPage, that.pageSize);
			},
			//导出医院产品使用数据
			exportData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				let fileName = filename + Date.now();
				this.$axios({
						url: '/apim/export/product_usage/',
						responseType: 'blob',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		},
		mounted() {
			let that = this;
			// this.getProdTypeOptions();
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		created() {
			this.bar_chart_area = JSON.parse(JSON.stringify(this.bar_chart_record));
			this.bar_chart_user = JSON.parse(JSON.stringify(this.bar_chart_record));
		}
	};
</script>

<style scoped>
	.plate-card {
		height: 350px;
		width: 100%;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}
</style>