<template>
	<div>
		<el-row class="center-row">
			<el-col :xs="24" :sm="6">
				<el-card>
					<el-form ref="formLogin" :rules="rules" :model="formLogin" :label-width="'60'" size="medium" :disabled="formLoginDisabled">
						<el-form-item prop="username">
							<el-input placeholder="请输入用户名" v-model="formLogin.username" prefix-icon="el-icon-user" maxlength="20"></el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input placeholder="请输入密码" v-model="formLogin.password" prefix-icon="el-icon-edit" show-password maxlength="20"></el-input>
						</el-form-item>
						<el-button type="primary" icon="el-icon-loading" @click="loginSubmit('formLogin')">登录</el-button>
					</el-form>
				</el-card>
				<strong></strong>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex';
	export default {
		data() {
			return {
				formLogin: {
					username: '',
					password: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				formLoginDisabled: false
			};
		},
		methods: {
			//登录提交
			...mapMutations(['changeLogin']),
			loginSubmit(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						that.formLoginDisabled = true;
						let fd = new FormData();
						let formData = this.formLogin;
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						this.$axios({
								url: '/api/user/login/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								// console.log(res.data);
								if (res.data.code == '200') {
									that.$message({
										message: res.data.message,
										type: 'success'
									});
									that.userToken = res.data.token;
									that.personName = res.data.name;
									that.userPrivilege = res.data.privilege;
									that.userID = res.data.userid
									that.changeLogin({
										Authorization: that.userToken,
										personName: that.personName,
										userPrivilege: that.userPrivilege,
										userID: that.userID
									});
									that.$router.push('/myrecord');
								} else {
									that.$message({
										message: res.data.message || '服务器无响应',
										type: 'warning',
										onClose: function() {
											that.formLoginDisabled = false;
										}
									});

								}
							})
							.catch(function(error) {
								that.$message({
									message: error || '服务器无响应',
									type: 'warning',
									onClose: function() {
										that.formLoginDisabled = false;
									}
								});
							});
					} else {
						return false;
					}
				});
			}
		}
	};
</script>

<style>
	.center-row {
	  display: flex;
	  justify-content: center; /* 水平居中对齐 */
	  min-height: 60vh; /* 设置最小高度，使内容在垂直方向上居中 */
	}
	
</style>
