<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable
						style="margin:5px" @clear="fetchData">
						<el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success" @click="openDialog()"  :disabled="$route.query.id == undefined" >新增产品使用记录</el-button>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe size="large">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<el-table :data="scope.row.product_usage" style="width: 100%; " border>
							<el-table-column label="品牌" prop="productusage_product_brand" />
							<el-table-column label="品类" prop="productusage_product_category" />
							<el-table-column label="品名" prop="productusage_product_name" show-overflow-tooltip />
							<el-table-column label="用量(桶/月)" prop="productusage_monthly_usage" />
							<el-table-column label="市场价格(元)" prop="productusage_market_price" />
							<el-table-column label="备注" prop="productusage_remark" />
							<el-table-column label="最后更新" prop="update_date">
								<template slot-scope="scope">
									{{
										scope.row.update_date
											.split('.')
											.slice(0, 1)
											.join('')
											.replace('T',' ')
									}}
								</template>
							</el-table-column>
							<el-table-column label="变更记录" align="center">
								<template slot-scope="scope">
									<el-button type="text" size="mini"
										@click="showProductHistory(scope.row.id)">查看</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="科室" prop="dept_name" sortable />
				<el-table-column label="手术量" prop="dept_workload" show-overflow-tooltip />
				<el-table-column label="填报人" prop="dept_user__person_name" />
				<el-table-column label="最后更新" prop="update_date">
					<template slot-scope="scope">
						{{
							scope.row.update_date
								.split('.')
								.slice(0, 1)
								.join('')
								.replace('T',' ')
						}}
					</template>
				</el-table-column>
				<el-table-column label="变更记录" align="center">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="showDeptHistory(scope.row.id)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" icon="el-icon-edit" @click="openDialog(scope.row)">改</el-button>
							<el-button size="mini" icon="el-icon-delete" type="danger"
								@click="deleteproductInfo(scope.row)">删
							</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
					:total="total" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
					@current-change="handleCurrentChange" @size-change="handleSizeChange" />
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next" :total="total"
					:page-size="pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange"
					prev-text="上一页" next-text="下一页" :pager-count="5" />
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 数据处理表单 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :width="'60%'" :fullscreen="ifFullScreen">
			<el-form ref="form" :rules="rules" :model="formData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="科室" prop="dept_name">
					<el-select v-model="formData.dept_name" filterable allow-create default-first-option
						placeholder="请选择科室">
						<el-option v-for="item in deptOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="手术量" prop="dept_workload">
					<el-input-number v-model="formData.dept_workload" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="产品使用记录">
					<el-button type="success" size="small" @click="addTab('formData','ruhof')">新增鲁沃夫</el-button>
					<el-button type="warning" size="small" @click="addTab('formData','other')">新增竞品</el-button>
				</el-form-item>
				<div v-for="(subitem, index) in formData.product_usage" :key="index">
					<el-divider />
					<div v-if="subitem.hasOwnProperty('productusage_ruhof_product')">
						<el-form-item label="鲁沃夫产品" :prop="'product_usage.' + index + '.productusage_ruhof_product'"
							:rules="{ required: true, message: '请选择鲁沃夫产品', trigger: 'blur' }">
							<el-select v-model="subitem.productusage_ruhof_product" filterable remote reserve-keyword
								placeholder="请输入关键词查询" :remote-method="remoteMethod" :loading="optionLoading">
								<el-option
									v-if="subitem.productusage_ruhof_product !== null && subitem.productusage_product_name !==''"
									:label="subitem.productusage_product_name"
									:value="subitem.productusage_ruhof_product"></el-option>
								<el-option v-for="item in productOption" :key="item.pk" :label="item.prod_name"
									:value="item.pk">
									<span style="float: left">{{ item.prod_name }}</span>
									<span
										style="float: right; color: #8492a6; font-size: 13px">{{ item.prop_serial }}</span>
								</el-option>
							</el-select>
						</el-form-item>
					</div>

					<div v-else>
						<el-form-item label="竞品名称" :prop="'product_usage.' + index + '.productusage_product_name'"
							:rules="{ required: true, message: '请填写竞品名称', trigger: 'blur' }">
							<el-input v-model="subitem.productusage_product_name" clearable />
						</el-form-item>

						<el-form-item label="竞品品牌" :prop="'product_usage.' + index + '.productusage_product_brand'"
							:rules="{ required: true, message: '请填写竞品品牌', trigger: 'blur' }">
							<el-input v-model="subitem.productusage_product_brand" clearable />
						</el-form-item>

						<el-form-item label="竞品分类" :prop="'product_usage.' + index + '.productusage_product_category'"
							:rules="{ required: true, message: '请填写竞品分类', trigger: 'blur' }">
							<el-input v-model="subitem.productusage_product_category" clearable />
						</el-form-item>
					</div>

					<el-form-item label="月用量" :precision="2"
						:prop="'product_usage.' + index + '.productusage_monthly_usage'">
						<el-input-number v-model="subitem.productusage_monthly_usage" :min="1"></el-input-number>
					</el-form-item>
					<el-form-item label="销售价格" :precision="2"
						:prop="'product_usage.' + index + '.productusage_market_price'">
						<el-input-number v-model="subitem.productusage_market_price" :min="1"></el-input-number>
					</el-form-item>

					<el-form-item label="备注" :prop="'product_usage.' + index + '.productusage_remark'">
						<el-input v-model="subitem.productusage_remark" type="textarea" clearable maxlength="100"
							show-word-limit autosize />
						<el-button type="danger" size="small" @click="removeTab(subitem, 'formData')">删除</el-button>
					</el-form-item>
				</div>
				<el-divider />
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('form')">取 消</el-button>
				<el-button type="danger" @click="saveData">提 交</el-button>
			</div>
		</el-dialog>
		<!-- 历史记录弹窗1 -->
		<el-dialog title="科室历史记录" :visible.sync="dialogDeptHistoryVisible" :width="'80%'" :fullscreen="ifFullScreen">
			<el-table :data="tableDeptHistoryData">
				<el-table-column label="操作时间" prop="history_date" />
				<el-table-column label="操作" prop="history_type" />
				<el-table-column label="数据">
					<el-table-column label="科室" prop="dept_name" sortable />
					<el-table-column label="手术量" prop="dept_workload" show-overflow-tooltip sortable />
				</el-table-column>
			</el-table>
		</el-dialog>

		<!-- 历史记录弹窗 2-->
		<el-dialog title="产品历史记录" :visible.sync="dialogProductHistoryVisible" :width="'80%'" :fullscreen="ifFullScreen">
			<el-table :data="tableProductHistoryData">
				<el-table-column label="操作时间" prop="history_date" />
				<el-table-column label="操作" prop="history_type" />
				<el-table-column label="数据">
					<el-table-column label="产品品牌" prop="productusage_product_brand" sortable />
					<el-table-column label="产品名称" prop="productusage_product_name" show-overflow-tooltip sortable />
					<el-table-column label="产品品类" prop="productusage_product_category" sortable />
					<el-table-column label="用量(桶/月)" prop="productusage_monthly_usage" sortable />
					<el-table-column label="市场价格" prop="productusage_market_price" sortable />
					<el-table-column label="备注" prop="productusage_remark" show-overflow-tooltip sortable />
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				tableDeptHistoryData: [],
				tableProductHistoryData: [],
				keywords: '',
				dialogVisible: false,
				dialogDeptHistoryVisible: false,
				dialogProductHistoryVisible: false,
				dialogHistoryVisible: false,
				ifFullScreen: false,
				optionLoading: false,
				formData: {
					id: null,
					dept_name: '',
					dept_workload: '',
					product_usage: [{
						id: '',
						productusage_ruhof_product: '',
						productusage_product_name: '',
						productusage_product_brand: '',
						productusage_product_category: '',
						productusage_monthly_usage: '',
						productusage_market_price: 0,
						productusage_remark: ''
					}],
					hospital_id: null
				},
				//新增和更新表单的验证规则
				rules: {
					dept_name: [{
						required: true,
						message: '请选择科室名称',
						trigger: 'change'
					}],
					dept_workload: [{
						required: true,
						message: '请填写手术量',
						trigger: 'change'
					}]
				},
				prodTypeOptions: [],
				deptOptions: [{
						"item": "CSSD",
						"value": "CSSD"
					},
					{
						"item": "内镜中心",
						"value": "内镜中心"
					},
					{
						"item": "其他",
						"value": "其他"
					},
				],
				productOption: [],
				dialogTitle: "新增数据",
				formLabelWidth: '100px',
				total: 100,
				currentPage: 1,
				pageSize: 20,
				loading: false,
				hospital_id: this.$route.query.id
			};
		},
		methods: {
			// 获取品类列表
			getProdTypeOptions() {
				let that = this;
				this.$axios
					.get('/apim/retrieve/product_category/')
					.then(function(response) {
						that.prodTypeOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 远程搜索鲁沃夫产品数据
			remoteMethod(query) {
				if (query !== '') {
					let that = this;
					this.optionLoading = true;
					setTimeout(() => {
						this.$axios
							.get('/apim/get/productoptions/', {
								params: {
									keywords: query,
									currentpage: 1,
									pagesize: 50
								}
							})
							.then(function(response) {
								if (response.data.code == '401') {
									that.logout();
								} else {
									that.productOption = response.data.data;
									that.optionLoading = false;
								}
							})
							.catch(function(error) {
								that.optionLoading = false;
							});
					}, 200);
				} else {
					this.productOption = [];
				}
			},
			//新增子任务输入框
			addTab(formdata, type) {
				if (type == 'ruhof') {
					this.$data[formdata].product_usage.push({
						id: '',
						productusage_ruhof_product: null,
						productusage_product_name: '',
						productusage_product_brand: '',
						productusage_product_category: '',
						productusage_monthly_usage: '',
						productusage_market_price: 0,
						productusage_remark: '',
						is_ruhof: true
					});
				} else {
					this.$data[formdata].product_usage.push({
						id: '',
						// productusage_ruhof_product: 'null',
						productusage_product_name: '',
						productusage_product_brand: '',
						productusage_product_category: '',
						productusage_monthly_usage: '',
						productusage_market_price: 0,
						productusage_remark: ''
					});
				}
			},
			//删除子任务输入框
			removeTab(item, formdata) {
				let index = this.$data[formdata].product_usage.indexOf(item);
				if (index !== 0) {
					this.$data[formdata].product_usage.splice(index, 1);
				}
			},
			// 重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false;
				this.productOption = [];
			},
			// 获取数据
			fetchData() {
				let that = this;
				this.loading = true;
				this.$axios.get('/apim/retrieve/product_usage/', {
					params: {
						keywords: that.keywords,
						page: that.currentPage,
						pagesize: that.pageSize,
						// hospital_id: that.$route.params.id,
						hospital_id: that.hospital_id
					}
				}).then(response => {
					if (response.data.code == '401') {
						that.logout();
					}
					if (response.data.data) {
						this.tableData = response.data.data;
						this.total = response.data.totalItemNum;
					} else {
						that.$message({
							message: response.errors,
							type: 'warning',
							onClose: function() {
								that.loading = false;
							}
						});
					}
				}).finally(() => {
					this.loading = false;
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.fetchData();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.fetchData();
			},
			openDialog(row = null) {
				let that = this;
				this.dialogVisible = true;
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});

				if (row) {
					this.dialogTitle = '修改产品使用记录';
					this.formData = {
						...row,
					};
				} else {
					this.dialogTitle = '新增产品使用记录';
					this.formData = {
						id: null,
						dept_name: '',
						dept_workload: '',
						product_usage: [{
							id: null,
							productusage_ruhof_product: '',
							productusage_product_name: '',
							productusage_product_brand: '',
							productusage_product_category: '',
							productusage_monthly_usage: '',
							productusage_market_price: 0,
							productusage_remark: ''
						}],
						hospital_id: that.hospital_id
					};
				}
			},
			// 查看科室历史记录
			showDeptHistory(id) {
				let that = this;
				this.dialogDeptHistoryVisible = true;
				this.$axios.get('/apim/history/department/', {
					params: {
						id: id,
					}
				}).then(response => {
					if (response.data.history_data) {
						this.tableDeptHistoryData = response.data.history_data;
					} else {
						that.$message({
							message: response.data.errors,
							type: 'warning',
						});
					}
				}).finally(() => {

				});
			},
			// 查看产品历史记录
			showProductHistory(id) {
				let that = this;
				this.dialogProductHistoryVisible = true;
				this.$axios.get('/apim/history/product_usage/', {
					params: {
						id: id,
					}
				}).then(response => {
					if (response.data.history_data) {
						this.tableProductHistoryData = response.data.history_data;
					} else {
						that.$message({
							message: response.data.errors,
							type: 'warning',
						});
					}
				}).finally(() => {

				});
			},
			//保存记录
			saveData() {
				let that = this;
				this.$refs.form.validate((valid) => {
					if (valid) {
						const url = this.formData.id ?
							'/apim/update/product_usage/' : '/apim/create/product_usage/';
						let fd = new FormData();
						let formData = that.formData;
						console.log(this.formData)
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						fd.set('product_usage', JSON.stringify(formData.product_usage))

						this.$axios({
								url: url,
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(response => {
								this.dialogVisible = false;
								this.fetchData();
							})
							.catch(error => {
								if (error.response && error.response.status === 400) {
									console.log(error.response.data.non_field_errors)
									that.$message({
										message: error.response.data.non_field_errors.join(','),
										type: 'warning',
									});
								} else {
									that.$message({
										message: '保存失败，服务器错误',
										type: 'warning',
									});
								}
							});
					}
				});
			},
			// 单个删除
			deleteproductInfo(row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						let that = this;
						let fd = new FormData();
						fd.append('id', row.id);
						this.$axios({
								url: '/apim/delete/product_usage/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.fetchData();
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					})
					.catch(() => {});
			},
		},
		mounted() {
			this.fetchData();
			this.ifFullScreen = document.body.clientWidth > 720 ? false : true;
		}
	};
</script>

<style></style>