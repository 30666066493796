import Vue from 'vue'
import VueRouter from 'vue-router'

//工作日志管理
import myrecord from '../views/workrecord/myrecord.vue'
import recordmanage from '../views/workrecord/recordmanage.vue'
import recorddashboard from '../views/workrecord/recorddashboard.vue'
import recorddashboardmuti from '../views/workrecord/recorddashboardmuti.vue'

//账号登录及管理
import login from '../views/manage/login.vue'
import usermanage from '../views/manage/usermanage.vue'
import deptmanage from '../views/manage/deptmanage.vue'

// 医院客户相关
import customer from '../views/marketinfo/customer/customer.vue'
import customerdashboard from '../views/marketinfo/customer/customerdashboard.vue'
import cmkeyperson from '../views/marketinfo/customer/customerkeyperson.vue'
import csrecord from '../views/marketinfo/customer/customerservicerecord.vue'
import marketshare from '../views/marketinfo/customer/marketshare.vue'
import equipment from '../views/marketinfo/customer/equipment.vue'

//产品相关
import product from '../views/marketinfo/product/product.vue'
import productdashboard from '../views/marketinfo/product/productdashboard.vue'
import productprice from '../views/marketinfo/product/productprice.vue'
import productusage from '../views/marketinfo/product/productuseage.vue'

//同业公司相关
import generalagent from '../views/marketinfo/company/generalagent.vue'
import distributor from '../views/marketinfo/company/distributor.vue'
import marketsupport from '../views/marketinfo/company/MarketSupport.vue'
import marketer from '../views/marketinfo/company/marketer.vue'
import gadashboard from '../views/marketinfo/company/generalagentdashboard.vue'

//销售统计
import statdashboard from '../views/marketinfo/salestatistic/statdashboard.vue'
import statbrandshare from '../views/marketinfo/salestatistic/statbrandshare.vue'
import statemonthlysale from '../views/marketinfo/salestatistic/statmonthlysale.vue'

//数据查询及导出
import intequerydashboard from '../views/marketinfo/queryandexport/intequerydashboard.vue'
import querykeyperson from '../views/marketinfo/queryandexport/querykeyperson.vue'
import queryequipment from '../views/marketinfo/queryandexport/queryequipment.vue'
import querymarketer from '../views/marketinfo/queryandexport/querymarketer.vue'
import querycustomeruseproducts from '../views/marketinfo/queryandexport/querycustomeruseproducts.vue'

//医院相关-新版
import hospitalpanel from '../views/marketinfo/hospital/hospitalpanel.vue'
import hospitalproductusage from '../views/marketinfo/hospital/hospitalproductusage.vue'
import hospitalequipment from '../views/marketinfo/hospital/hospitalequipment.vue'
import hospitalexperts from '../views/marketinfo/hospital/hospitalexperts.vue'
import hospitalsupplyment from '../views/marketinfo/hospital/hospitalsupplyment.vue'
import hospitalcompetingproducts from '../views/marketinfo/hospital/hospitalcompetingproducts.vue'

// 数据查询与导出 - 新版
import query_panel from '../views/marketinfo/dashboard/query_panel.vue'
import query_product_usage from '../views/marketinfo/dashboard/query_product_usage.vue'
import query_experts from '../views/marketinfo/dashboard/query_experts.vue'
import query_equipment  from '../views/marketinfo/dashboard/query_equipment.vue'
import query_competing_products  from '../views/marketinfo/dashboard/query_competing_products.vue'
import query_supplyment from '../views/marketinfo/dashboard/query_supplyment.vue'


// 订货、商品、地区、销售目标管理
import orderrecordmanage from '../views/marketsale/marketdata/orderrecordmanagement.vue'
import yearly_sale_target from '../views/marketsale/marketdata/yearly_sale_target.vue'
import product_manage from '../views/marketsale/marketdata/product_info.vue'
import distributor_region from '../views/marketsale/marketdata/distributor_region.vue'

import monthly_area_brand from '../views/marketsale/marketreport/monthly_area_brand.vue'
import monthly_area_product from '../views/marketsale/marketreport/monthly_area_product.vue'
import monthly_product_area from '../views/marketsale/marketreport/monthly_product_area.vue'
import monthly_area_compare from '../views/marketsale/marketreport/monthly_area_compare.vue'
import monthly_product_compare from '../views/marketsale/marketreport/monthly_product_compare.vue'
import yearly_area_brand from '../views/marketsale/marketreport/yearly_area_brand.vue'
import yearly_area_product from '../views/marketsale/marketreport/yearly_area_product.vue'
import yearly_sales_progress from '../views/marketsale/marketreport/yearly_sales_progress.vue'
import monthly_area_summary from '../views/marketsale/marketreport/monthly_area_summary.vue'
import yearly_area_compare from '../views/marketsale/marketreport/yearly_area_compare.vue'
import yearly_product_compare from '../views/marketsale/marketreport/yearly_product_compare.vue'


Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};


const routes = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/myrecord',
		name: 'myrecord',
		component: myrecord
	},
	{
		path: '/recordmanage',
		name: 'recordmanage',
		component: recordmanage
	},
	{
		path: '/recorddashboard',
		name: 'recorddashboard',
		component: recorddashboard
	},
	{
		path: '/recorddashboardmuti',
		name: 'recorddashboardmuti',
		component: recorddashboardmuti
	},
	{
		path: '/usermanage',
		name: 'usermanage',
		component: usermanage

	},
	{
		path: '/deptmanage',
		name: 'deptmanage',
		component: deptmanage
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/ga',
		name: 'generalagent',
		component: generalagent
	},
	{
		path: '/product',
		name: 'product',
		component: product
	},
	{
		path: '/distributor/:id',
		name: 'distributor',
		component: distributor
	},
	{
		path: '/gadashboard/:id',
		component: gadashboard,
		children: [{
			path: '',
			components: {
				a: distributor,
				b: marketsupport,
				c: marketer,
			}
		}, ]
	},
	{
		path: '/cmdashboard/:id',
		component: customerdashboard,
		children: [{
			path: '',
			components: {
				a: equipment,
				b: cmkeyperson,
				c: marketshare,
				d: csrecord
			}
		}, ]
	},
	{
		path: '/customer',
		name: 'customer',
		component: customer
	},
	{
		path: '/prodashboard/:id',
		component: productdashboard,
		children: [{
			path: '',
			components: {
				a: productprice,
				b: productusage
			}
		}, ]
	},
	{
		path: '/statistics/',
		component: statdashboard,
		children: [{
			path: '',
			components: {
				a: statbrandshare,
				b: statemonthlysale
			}
		}, ]
	},
	{
		path: '/intequery/',
		component: intequerydashboard,
		children: [{
			path: '',
			components: {
				a: querykeyperson,
				b: queryequipment,
				c: querymarketer,
				d: querycustomeruseproducts
			}
		}, ]
	},
	{
		path: '/hospital_query_panel/',
		component: query_panel,
		children: [{
			path: '',
			components: {
				a: query_product_usage,
				b: query_equipment,
				c: query_experts,
				d: query_supplyment,
				e: query_competing_products
			}
		}, ]
	},
	{
		path: '/orderdata/',
		component: orderrecordmanage,
	},
	{
		path: '/monthly_area_brand/',
		component: monthly_area_brand,
	},
	{
		path: '/monthly_area_product/',
		component: monthly_area_product,
	},
	{
		path: '/monthly_product_area/',
		component: monthly_product_area,
	},	
	{
		path: '/monthly_area_compare/',
		component: monthly_area_compare,
	},	
	{
		path: '/monthly_product_compare/',
		component: monthly_product_compare,
	},	
	{
		path: '/yearly_area_brand/',
		component: yearly_area_brand,
	},	
	{
		path: '/yearly_area_product/',
		component: yearly_area_product,
	},	
	{
		path: '/yearly_sales_progress/',
		component: yearly_sales_progress,
	},	
	{
		path: '/monthly_area_summary/',
		component: monthly_area_summary,
	},	
	{
		path: '/yearly_area_compare/',
		component: yearly_area_compare,
	},	
	{
		path: '/yearly_product_compare/',
		component: yearly_product_compare,
	},
	{
		// path: '/hospital_panel/:id',
		path: '/hospital_panel/',
		name: 'hospitalpanel',
		component: hospitalpanel,
		children: [{
			path: '',
			components: {
				a: hospitalproductusage,
				b: hospitalequipment,
				c: hospitalexperts,
				d: hospitalsupplyment,
				e: hospitalcompetingproducts
			}
		}, ]
	},
	{
		path: '/yearly_sale_target/',
		component: yearly_sale_target,
	},
	{
		path: '/product_manage/',
		component: product_manage,
	},
	{
		path: '/distributor_region/',
		component: distributor_region,
	},
		
	{
		path: '*',
		redirect: '/'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: routes
})

// 判断登录状态的全局路由守卫
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	next()

	let token = localStorage.getItem('Authorization');
	if (to.path != '/login' && !token) {
		next({
			path: '/login'
		})
	} else {
		if (to.path == '/login' && token) {
			next('/myrecord')
		} 
		// else {
		// 	next()
		// }
	}
});

export default router
