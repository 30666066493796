<template>
	<div>
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="year" placeholder="选择年" value-format="yyyy" size="mini"></el-date-picker>
					</el-form-item>
					<el-form-item prop="product_brand">
						<el-select v-model="searchData.product_brand" placeholder="请选择品牌">
							<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="product_type">
						<el-select v-model="searchData.product_type" placeholder="请选择产品类型">
							<el-option v-for="item in prodTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区">
							<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看统计</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>
		<el-row :gutter="5" class="margin-bottom-sm">
			<el-col :sm="24" :xs="24">
				<div class="plate-card"><v-chart :options="bar_monthly_sale" autoresize theme="light">/></v-chart></div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import ECharts from 'vue-echarts';
export default {
	inject: ['logout'],
	data() {
		return {
			searchData: {
				product_type: '',
				area: '',
				date_range: '',
				product_brand: ''
			},
			brandOptions: [],
			prodTypeOptions: [],
			BrandOptions: [],
			provinceOptions: [
				{
					value: '北京',
					label: '北京'
				},
				{
					value: '天津',
					label: '天津'
				},
				{
					value: '上海',
					label: '上海'
				},
				{
					value: '重庆',
					label: '重庆'
				},
				{
					value: '河北',
					label: '河北'
				},
				{
					value: '山西',
					label: '山西'
				},
				{
					value: '辽宁',
					label: '辽宁'
				},
				{
					value: '吉林',
					label: '吉林'
				},
				{
					value: '黑龙江',
					label: '黑龙江'
				},
				{
					value: '江苏',
					label: '江苏'
				},
				{
					value: '浙江',
					label: '浙江'
				},
				{
					value: '安徽',
					label: '安徽'
				},
				{
					value: '福建',
					label: '福建'
				},
				{
					value: '江西',
					label: '江西'
				},
				{
					value: '山东',
					label: '山东'
				},
				{
					value: '河南',
					label: '河南'
				},
				{
					value: '湖北',
					label: '湖北'
				},
				{
					value: '湖南',
					label: '湖南'
				},
				{
					value: '广东',
					label: '广东'
				},
				{
					value: '甘肃',
					label: '甘肃'
				},
				{
					value: '四川',
					label: '四川'
				},
				{
					value: '贵州',
					label: '贵州'
				},
				{
					value: '海南',
					label: '海南'
				},
				{
					value: '云南',
					label: '云南'
				},
				{
					value: '青海',
					label: '青海'
				},
				{
					value: '陕西',
					label: '陕西'
				},
				{
					value: '广西',
					label: '广西'
				},
				{
					value: '西藏',
					label: '西藏'
				},
				{
					value: '宁夏',
					label: '宁夏'
				},
				{
					value: '新疆',
					label: '新疆'
				},
				{
					value: '内蒙',
					label: '内蒙'
				},
				{
					value: '澳门',
					label: '澳门'
				},
				{
					value: '香港',
					label: '香港'
				},
				{
					value: '台湾',
					label: '台湾'
				}
			],
			bar_monthly_sale: {
				title: {
					text: '2021年各品牌销量',
					left: 'center',
					top: 20
				},
				tooltip: {
					trigger: 'axis'
				},
				toolbox: {
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						dataView: { show: true, readOnly: true },
						// magicType: { show: true, type: ['line', 'bar'] },
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				legend: {
					left: 'center',
					top: 'bottom',
					data: ['销售金额', '销量']
				},
				xAxis: [
					{
						type: 'category',
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
						axisPointer: {
							type: 'shadow'
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						name: '销量',
						splitNumber: 5,
						axisLabel: {
							formatter: '{value} 件'
						}
					},
					{
						type: 'value',
						name: '销售金额',
						splitNumber: 5,
						axisLabel: {
							formatter: '{value}万元'
						}
					},
				],
				series: [
					{
						name: '销售金额',
						type: 'bar',
						yAxisIndex: 1,
						data: [200000, 490000, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b} : {c} 万元'
						}
					},
					{
						name: '销量',
						type: 'line',
						yAxisIndex: 0,
						data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
						tooltip: {
							trigger: 'item',
							formatter: '{a} <br/>{b} : {c} 件'
						}
					},

				],
				animationDuration: 2000
			}
		};
	},
	mounted() {
		// 获取数据
		this.getProdTypeOptions();
		this.getBrandOptions();
	},
	created() {
		this.getDashboardData(this.searchData);
	},
	methods: {
		//重置搜索表单
		resetSearchForm(formName) {
			var that = this;
			this.$refs[formName].resetFields();
			this.$refs[formName].clearValidate();
			this.getDashboardData(that.searchData);
		},

		//提交搜索
		submitFormSearchData() {
			this.getDashboardData(this.searchData);
		},

		// 获取品牌列表
		getBrandOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/brandoptions/')
				.then(function(response) {
					that.brandOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		// 获取产品分类列表
		getProdTypeOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/prodtypeoptions/')
				.then(function(response) {
					that.prodTypeOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		//获取仪表板数据
		getDashboardData(queryData) {
			var that = this;
			that.loading = true;
			//添加表单数据
			let fd = new FormData();
			let formData = queryData;
			for (let key in formData) {
				fd.append(key, formData[key]);
			}

			this.$axios({
				url: '/apim/get/statmonthlysale/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					if (res.data.code == '401') {
						that.logout();
					} else {
						that.bar_monthly_sale.series[1].data = res.data.line_sale_volumn;
						that.bar_monthly_sale.series[0].data = res.data.bar_sale_amount;
						that.bar_monthly_sale.xAxis[0].data = res.data.date_list;
						that.bar_monthly_sale.title.text = res.data.date_range +'年'+ res.data.brand_name + res.data.area + '地区销售情况';
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		}
	}
};
</script>

<style scoped>
.title-text {
	color: #00a6dd;
	font-size: 18px;
	font-weight: 800;
}

.item {
	margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: '';
}
.clearfix:after {
	clear: both;
}

.box-card {
	width: auto;
	margin: 0;
}

.echarts {
	width: 100%;
	height: 100%;
}

.plate-card {
	height: 500px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.margin-bottom-sm {
	margin-bottom: 10px;
}
</style>
