<template>
	<div>
		<el-row :gutter="10">
			<el-col :xs="24" :sm="5">
				<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
					<el-row :gutter="10">
						<el-col :xs="24" :sm="24">
							<el-card>
								<el-form size="mini" :inline="true">
									<el-form-item>
										<el-input v-model="keywords" placeholder="医院名称/省份" clearable maxlength="30"
											@clear="resetSearch()">
											<el-button slot="append" type="success" @click="Search()">搜索</el-button>

										</el-input>
									</el-form-item>
									<!-- 									<el-form-item>
										<el-button  type="success" @click="Search()">新增医院</el-button>
									</el-form-item> -->
								</el-form>
							</el-card>
						</el-col>
					</el-row>

					<el-card style="margin-top:10px;">
						<el-row>
							<el-table :data="tableData" style="width: 100%; " max-height="500">
								<el-table-column label="医院名称" prop="hospital_name" show-overflow-tooltip min-width="120"
									sortable>
									<template slot-scope="scope">
										<el-button v-if="scope.row.pk != $route.query.id" type="text"
											@click="jumpTo('/hospital_panel/', scope.row)">
											{{ scope.row.hospital_name }}
										</el-button>
										<div v-else class="active">{{ scope.row.hospital_name }}</div>
									</template>
								</el-table-column>
								<el-table-column label="地区" prop="hospital_area" sortable width="80"></el-table-column>
							</el-table>
						</el-row>

						<!-- 分页 -->
						<el-row class="paginator hidden-sm-and-down">
							<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev,next"
								:total="totalItems" :page-sizes="[20, 50, 100]" :page-size="pageSize"
								@current-change="handleCurrentChange" @size-change="handleSizeChange" />
						</el-row>

						<el-row class="paginator hidden-sm-and-up">
							<el-pagination background :current-page.sync="currentPage" layout="total, prev, next"
								:total="totalItems" :page-size="pageSize" @current-change="handleCurrentChange"
								@size-change="handleSizeChange" prev-text="上一页" next-text="下一页" />
						</el-row>
					</el-card>
				</div>
			</el-col>
			<el-col :xs="24" :sm="19">
				<el-row>
					<el-col :xs="24" :sm="24">
						<el-card style="margin-bottom: 5px;">
							<el-descriptions size="medium" :title="hospital_info.hospital_name" :column="6" border
								direction="vertical">
								<el-descriptions-item label="省份">{{ hospital_info.hospital_area}}</el-descriptions-item>
								<el-descriptions-item label="市/地区">
									{{ hospital_info.hospital_city}}
								</el-descriptions-item>
								<el-descriptions-item
									label="等级">{{ hospital_info.hospital_level}}</el-descriptions-item>
								<el-descriptions-item label="类型">{{ hospital_info.hospital_type}}</el-descriptions-item>
								<el-descriptions-item label="床位数"><el-tag
										size="small">{{ hospital_info.hospital_bed_num}}</el-tag></el-descriptions-item>
								<el-descriptions-item label="备注">
									{{ hospital_info.hospital_remark}}
								</el-descriptions-item>
							</el-descriptions>
						</el-card>
					</el-col>
					<el-col :xs="24" :sm="24">
						<el-card>
							<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
								<el-tab-pane name="first">
									<span slot="label">
										<i class="el-icon-s-order"></i>
										清洗剂使用情况
									</span>
									<div>
										<transition name="el-fade-in-linear"><router-view :key="$route.fullPath"
												name="a" v-if="isRouterAlive" /></transition>
									</div>
								</el-tab-pane>
								<el-tab-pane name="second">
									<span slot="label">
										<i class="el-icon-s-platform"></i>
										科室设备及运转情况
									</span>
									<div>
										<transition name="el-fade-in-linear"><router-view :key="$route.fullPath"
												name="b" v-if="isRouterAlive" /></transition>
									</div>
								</el-tab-pane>
								<el-tab-pane name="third">
									<span slot="label">
										<i class="el-icon-user-solid"></i>
										专家基础信息
									</span>
									<div>
										<transition name="el-fade-in-linear"><router-view :key="$route.fullPath"
												name="c" v-if="isRouterAlive" /></transition>
									</div>
								</el-tab-pane>
								<el-tab-pane name="fourth">
									<span slot="label">
										<i class="el-icon-s-order"></i>
										医院供货情况
									</span>
									<div>
										<transition name="el-fade-in-linear"><router-view :key="$route.fullPath"
												name="d" v-if="isRouterAlive" /></transition>
									</div>
								</el-tab-pane>
								<!-- 								<el-tab-pane name="fifth">
									<span slot="label">
										<i class="el-icon-s-order"></i>
										竞争品牌信息统计
									</span>
									<div>
										<transition name="el-fade-in-linear"><router-view :key="$route.fullPath"
												name="e" v-if="isRouterAlive" /></transition>
									</div>
								</el-tab-pane> -->

							</el-tabs>
						</el-card>
					</el-col>
				</el-row>

			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				ifFullScreen: false,
				totalItems: 100,
				currentPage: 1,
				pageSize: 20,
				loading: false,
				visible: false,
				activeName: 'first',
				isRouterAlive: true,
				keywords: '',
				hospital_info: {
					hospital_area: 'N/A',
					hospital_level: 'N/A',
					hospital_name: '医院名称',
					hospital_remark: 'N/A',
					hospital_type: 'N/A',
					hospital_bed_num: '0'
				}
			}
		},
		methods: {
			//通用函数-获取医院名单
			getData(keywords, currentPage, pageSize) {
				let that = this;
				that.loading = true;
				this.$axios
					.get('/apim/get/customer/', {
						params: {
							keywords: keywords,
							currentpage: currentPage,
							pagesize: pageSize
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.tableData = response.data.data;
							// console.log(response.data.data);
							that.totalItems = response.data.totalItemNum;
							that.loading = false;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//通用功能 - 关键字搜索
			Search() {
				let that = this;
				this.currentPage = 1;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			resetSearch() {
				this.$router.push({
					path: '/hospital_panel/'
				})
				let that = this;
				this.currentPage = 1;
				this.keywords = "";
				this.getData(that.keywords, that.currentPage, that.pageSize);
				this.hospital_info = {
					hospital_area: '待获取',
					hospital_city: '待获取',
					hospital_level: '待获取',
					hospital_name: '医院名称',
					hospital_remark: '待获取',
					hospital_type: '待获取',
					hospital_bed_num: '待获取'
				};
			},
			//分页跳转
			handleCurrentChange(val) {
				let that = this;
				this.currentPage = val;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//每页显示条数更改
			handleSizeChange(val) {
				let that = this;
				this.pageSize = val;
				this.currentPage = 1;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//路由跳转
			jumpTo(name, row) {
				this.$router.push({
					path: name,
					query: {
						id: row.pk
					}
				})
				if (this.$route.params.id != '0') {
					this.hospital_info = row;
				}

			},
			handleClick(tab, event) {
				// console.log(tab, event);
			}
		},
		mounted() {
			let that = this;
			this.getData('', that.currentPage, that.pageSize);
			this.hospital_info = {
				hospital_area: '待获取',
				hospital_city: '待获取',
				hospital_level: '待获取',
				hospital_name: '医院名称',
				hospital_remark: '待获取',
				hospital_type: '待获取',
				hospital_bed_num: '待获取'
			};
		}
	};
</script>

<style></style>