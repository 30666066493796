<template>
	<div>
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="user">
						<el-cascader v-model="searchData.user" :options="userListOptions" clearable placeholder="用户" />
					</el-form-item>
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="month" align="right" placeholder="选择月"
							value-format="yyyy-MM" />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看统计</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>
		<el-row :gutter="2" class="margin-bottom-sm">

			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12">
							<el-avatar :src="require('../../assets/dealer.svg')" />
						</el-col>
						<el-col :span="12">
							<div><strong>月提交频次</strong></div>
							<div class="title-text">
								<countTo :start-val="0" :end-val="total_task_submit_count" :duration="3000" />
								/ {{ month_working_day_num}}
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>

			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12">
							<el-avatar :src="require('../../assets/late.svg')" />
						</el-col>
						<el-col :span="12">
							<div><strong>月迟交频次</strong></div>
							<div class="title-text">
								<countTo :start-val="0" :end-val="delayed_task_submit_count" :duration="3000" />
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12">
							<el-avatar :src="require('../../assets/expired.svg')" />
						</el-col>
						<el-col :span="12">
							<div><strong>月旷交频次</strong></div>
							<div class="title-text">
								<countTo :start-val="0" :end-val="absent_task_submit_count" :duration="3000" />
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12">
							<el-avatar :src="require('../../assets/company.svg')" />
						</el-col>
						<el-col :span="12">
							<div><strong>拜访客户数</strong></div>
							<div class="title-text">
								<countTo :start-val="0" :end-val="month_customer_visit_num" :duration="3000" />
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="8">
							<el-avatar :src="require('../../assets/auth.svg')" fit="contain" />
						</el-col>
						<el-col :span="16">
							<div><strong>月提交完成率</strong></div>
							<div class="title-text">
								<el-progress :text-inside="true" :stroke-width="20" text-color="#fff"
									:percentage="submit_complet_rate<100 ? submit_complet_rate: 100" :color="customColors" />
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="8" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="8">
							<el-avatar :src="require('../../assets/auth.svg')" fit="contain" />
						</el-col>
						<el-col :span="16">
							<div><strong>月准时提交率</strong></div>
							<div class="title-text">
								<el-progress :text-inside="true" :stroke-width="20" text-color="#fff"
									:percentage="ontime_submit_rate<100 ? ontime_submit_rate: 100" :color="customColors" />
							</div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="5" class="margin-bottom-sm">
			<el-col :sm="10" :xs="24">
				<div class="plate-card">
					<v-chart :options="pie" autoresize theme="light">/></v-chart>
				</div>
			</el-col>
			<el-col :sm="14" :xs="24">
				<div class="plate-card">
					<v-chart :options="bar" autoresize theme="light" />
				</div>
			</el-col>
		</el-row>

		<el-row />
	</div>
</template>

<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				customColors: [{
						color: '#f56c6c',
						percentage: 20
					},
					{
						color: '#e6a23c',
						percentage: 40
					},
					{
						color: '#5cb87a',
						percentage: 60
					},
					{
						color: '#1989fa',
						percentage: 80
					},
					{
						color: '#6f7ad3',
						percentage: 100
					}
				],
				searchData: {
					user: [],
					date_range: ''
				},
				userListOptions: [],
				ontime_submit_rate: 80,
				total_task_submit_count: 0,
				delayed_task_submit_count: 0,
				absent_task_submit_count: 0,
				month_working_day_num: 22,
				submit_complet_rate: 0,
				month_customer_visit_num:0,
				pie: {
					title: {
						text: '3月任务量统计',
						left: 'center',
						top: 20
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c}次({d}%)'
					},
					series: [{
							name: '主任务',
							type: 'pie',
							center: ['50%', '50%'],
							selectedMode: 'single',
							radius: [0, '30%'],

							label: {
								position: 'inner'
							},
							labelLine: {
								show: false
							},
							data: [{
								value: 335,
								name: '客户服务',
								selected: true
							}, {
								value: 679,
								name: '公司事务'
							}, {
								value: 1548,
								name: '自我提升'
							}]
						},
						{
							name: '子任务',
							type: 'pie',
							radius: ['40%', '55%'],
							center: ['50%', '50%'],
							data: [{
									value: 335,
									name: '客户服务'
								},
								{
									value: 310,
									name: '经销商服务'
								},
								{
									value: 274,
									name: '公司事务'
								},
								{
									value: 274,
									name: '自我提升'
								},
								{
									value: 274,
									name: '市场调研'
								}
							],
							// roseType: 'area',
							label: {
								formatter: '{b|{b}}：{c}次 {per|{d}%}  ',
								rich: {
									b: {
										fontSize: 15,
										lineHeight: 33,
										padding: [2, 4]
									},
									per: {
										color: '#ffffff',
										backgroundColor: '#358dff',
										padding: [4, 4],
										borderRadius: 2
									}
								}
							},
							itemStyle: {
								shadowBlur: 10,
								shadowColor: 'rgba(0, 0, 0, 0.1)'
							},
							animationType: 'scale',
							animationEasing: 'elasticOut',
							animationDelay: function(idx) {
								return Math.random() * 200;
							}
						}
					],
					animationDuration: 3000
				},
				bar: {
					title: {
						text: '月提交情况统计',
						top: 20,
						left: 'center'
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['line', 'bar']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					xAxis: {
						type: 'category',
						data: ['3/1', '3/2', '3/3', '3/4', '3/5', '3/6', '3/7', '3/8', '3/9', '3/10', '3/11', '3/12',
							'3/13', '3/14', '3/15', '3/16'
						]
					},
					yAxis: {
						type: 'value'
					},
					series: [{
						name: '任务量（件）',
						type: 'bar',
						label: {
							show: true,
							position: 'top',
							distance: 10,
							align: 'center',
							verticalAlign: 'middle',
							rotate: 0,
							formatter: '{c}',
							fontSize: 12,
							rich: {
								name: {
									textColor: '#55aaff'
								}
							}
						},
						data: [2, 3, 1, 0, 7, 11, 13, 22, 12, 20, 15, 8, 7, 11, 13, 2]
					}],
					animationDuration: 2000
				}
			};
		},
		mounted() {
			// 获取数据
			this.getUserListOptions();
		},
		created() {
			this.getDashboardData(this.searchData);
		},
		methods: {
			// 获取用户列表
			getUserListOptions() {
				var that = this;
				this.$axios
					.get('/api/get/userlistoption/')
					.then(function(response) {
						that.userListOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.getDashboardData(that.searchData);
			},

			//提交搜索
			submitFormSearchData() {
				this.getDashboardData(this.searchData);
			},

			//获取仪表板数据
			getDashboardData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}

				this.$axios({
						url: '/api/get/taskdashboard/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res.data)
						if (res.data.code == '401') {
							that.logout();
						} else {
							that.total_task_submit_count = res.data.monthly_submit_num;
							that.delayed_task_submit_count = res.data.monthly_late_submit_num;
							that.absent_task_submit_count = res.data.monthly_absent_submit_num;
							that.ontime_submit_rate = res.data.ontime_submit_rate;
							that.submit_complet_rate = res.data.submit_complet_rate;
							that.month_working_day_num = res.data.month_working_day_num || 22;
							that.month_customer_visit_num = res.data.month_customer_visit_num;
							that.pie.series[0].data = res.data.pie_data_inner;
							that.pie.title.text = res.data.current_month + '月任务量统计';
							that.pie.series[1].data = res.data.pie_data_outer;
							that.bar.xAxis.data = res.data.xAxis_data;
							that.bar.series[0].data = res.data.series_data;
							that.bar.title.text = res.data.current_month + '月提交情况统计';
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 500px;
	}

	.margin-bottom-sm {
		margin-bottom: 15px;
	}
</style>
