<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success" @click="addData()">新增产品使用</el-button>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column label="产品" prop="marketshare_product__prod_name" show-overflow-tooltip sortable min-width="150" />
				<el-table-column label="品牌" prop="marketshare_product__prod_brand__brand_name" show-overflow-tooltip sortable />
				<el-table-column label="使用科室" prop="marketshare_dept" sortable />
				<el-table-column label="单价(元)" prop="marketshare_price" sortable />
				<el-table-column label="供货商" prop="marketshare_supplier" show-overflow-tooltip sortable />
				<el-table-column label="上报人" align="center">
					<template slot-scope="scope">
						{{ scope.row.marketshare_user__person_name }}/
						{{
							scope.row.create_date
								.split('T')
								.slice(0, 1)
								.join('')
						}}
					</template>
				</el-table-column>
				<el-table-column width="150" label="月用量" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="getProductUsageData(scope.row.pk)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" icon="el-icon-edit" @click="updateData(scope.$index, scope.row)">改</el-button>
							<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteData(scope.$index, scope.row)">删</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 新增产品使用数据表单 -->
		<el-dialog
			title="新增产品使用"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="使用科室" prop="marketshare_dept"><el-input v-model="formAddData.marketshare_dept" clearable /></el-form-item>
				<el-form-item label="使用产品" prop="marketshare_product">
					<el-select
						v-model="formAddData.marketshare_product"
						filterable
						remote
						reserve-keyword
						placeholder="请输入关键词查询"
						:remote-method="remoteMethod"
						:loading="optionLoading"
					>
						<el-option v-for="item in productOption" :key="item.pk" :label="item.prod_name" :value="item.pk">
							<span style="float: left">{{ item.prod_name }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">{{ item.prop_serial }}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市场价/供货价" prop="marketshare_price">
					<el-input-number v-model="formAddData.marketshare_price" :precision="2" :step="0.1" :max="100000" label="RMB" size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="供货商/配送商" prop="marketshare_supplier"><el-input v-model="formAddData.marketshare_supplier" clearable /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新产品使用数据表单 -->
		<el-dialog
			title="修改产品使用数据"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="使用科室" prop="marketshare_dept"><el-input v-model="formUpdateData.marketshare_dept" clearable /></el-form-item>
				<el-form-item label="使用产品" prop="marketshare_product">
					<el-select
						v-model="formUpdateData.marketshare_product"
						filterable
						remote
						reserve-keyword
						placeholder="请输入关键词查询"
						:remote-method="remoteMethod"
						:loading="optionLoading"
						:key="formUpdateData.marketshare_product" 
					>
						<el-option :label="formUpdateData.marketshare_product__prod_name" :value="formUpdateData.marketshare_product"></el-option>
						<el-option v-for="item in productOption" :key="item.pk" :label="item.prod_name" :value="item.pk">
							<span style="float: left">{{ item.prod_name }}</span>
							<span style="float: right; color: #8492a6; font-size: 13px">{{ item.prop_serial }}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市场价/供货价" prop="marketshare_price">
					<el-input-number v-model="formUpdateData.marketshare_price" :precision="2" :step="0.1" :max="100000" label="RMB" size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="供货商/配送商" prop="marketshare_supplier"><el-input v-model="formUpdateData.marketshare_supplier" clearable /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateData')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 新增月用量数据表单 -->
		<el-dialog
			title="新增月用量数据"
			:visible.sync="MonthlyUseformAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="MonthlyUseformAdd" :rules="rules" :model="MonthlyUseformAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="月份" prop="usage_date">
					<el-date-picker v-model="MonthlyUseformAddData.usage_date" type="month" placeholder="选择月份" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="用量" prop="usage_quantity">
					<el-input-number v-model="MonthlyUseformAddData.usage_quantity" :precision="2" :step="0.1" :max="100000" label="RMB" size="small"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('MonthlyUseformAdd')">取 消</el-button>
				<el-button type="danger" @click="submitMonthlyUseAddForm('MonthlyUseformAdd')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新月用量数据表单 -->
		<el-dialog
			title="修改月用量数据"
			:visible.sync="MonthlyUseformUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="MonthlyUseformUpdate" :rules="rules" :model="MonthlyUseformUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="月份" prop="usage_date">
					<el-date-picker v-model="MonthlyUseformUpdateData.usage_date" type="month" placeholder="选择月份" value-format="yyyy-MM-dd" readonly></el-date-picker>
				</el-form-item>
				<el-form-item label="用量" prop="usage_quantity">
					<el-input-number v-model="MonthlyUseformUpdateData.usage_quantity" :precision="2" :step="0.1" :max="100000" label="RMB" size="small"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('MonthlyUseformUpdate')">取 消</el-button>
				<el-button type="primary" @click="submitMonthlyUserUpdateForm('MonthlyUseformUpdate')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 月用量展示主对话框 -->
		<el-dialog title="产品月用量展示" :visible.sync="MonthlyUseDemoDialogVisible" :fullscreen="ifFullScreen">
			<el-row>
				<el-col>
					<el-date-picker
						v-model="date_range"
						type="monthrange"
						align="right"
						unlink-panels
						range-separator="至"
						start-placeholder="开始月份"
						end-placeholder="结束月份"
						value-format="yyyy-MM-dd"
						size="mini"
					/>
					<el-button size="mini" type="primary" @click="queryProductUsageData()" style="margin-left: 15px;">查询</el-button>
					<el-button size="mini" type="success" @click="addMonthlyUseData()">新增</el-button>
				</el-col>
				<el-col>
					<el-table :data="tableDataUseage" style="width: 100%; " stripe max-height="300" show-summary>
						<el-table-column label="月份" show-overflow-tooltip sortable>
							<template slot-scope="scope">
								{{
									scope.row.usage_date
										.split('-')
										.slice(0, 2)
										.join('-')
								}}
							</template>
						</el-table-column>
						<el-table-column label="月用量" prop="usage_quantity" />
						<el-table-column label="上报人" prop="usage_user__person_name" />
						<el-table-column label="更新时间" prop="update_date" show-overflow-tooltip>
							<template slot-scope="scope">
								{{
									scope.row.update_date
										.split('T')
										.slice(0, 1)
										.join()
								}}
							</template>
						</el-table-column>
						<el-table-column width="150" label="操作" align="center">
							<template slot-scope="scope">
								<el-button-group>
									<el-button size="mini" icon="el-icon-edit" @click="updateMonthlyUseData(scope.$index, scope.row)">改</el-button>
									<el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteMonthlyUseData(scope.$index, scope.row)">删</el-button>
								</el-button-group>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
				<el-col>
					<div style="height: 400px;"><v-chart :options="monthlyUsageBarChart" autoresize theme="light" /></div>
				</el-col>
			</el-row>
			<el-button slot="reference" size="small" @click="getProductUsageData(scope.row.pk)">点击查看</el-button>
		</el-dialog>
	</div>
</template>

<script>
import { exportToExcel } from '@/api/utils.js';
import ECharts from 'vue-echarts';
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			tableDataUseage: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				marketshare_product: '',
				marketshare_product__prod_name: '',
				marketshare_dept: '',
				marketshare_price: '',
				marketshare_supplier: ''
			},
			formUpdateData: {
				pk: '',
				marketshare_product: '',
				marketshare_product__prod_name: '',
				marketshare_dept: '',
				marketshare_price: '',
				marketshare_supplier: ''
			},
			//新增和更新表单的验证规则
			rules: {
				marketshare_product: [{ required: true, message: '请填写产品名称', trigger: 'change' }],
				marketshare_dept: [{ required: true, message: '请选择使用部门', trigger: 'blur' }],
				marketshare_price: [{ required: true, message: '请填写市场价', trigger: 'blur' }],
				usage_date: [{ required: true, message: '请选择月份', trigger: 'blur' }],
				usage_quantity: [{ required: true, message: '请填写产品用量', trigger: 'blur' }]
			},
			formLabelWidth: '150px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false,
			optionLoading: false,
			productOption: [],
			MonthlyUseformAddData: {
				pk: '',
				usage_date: '',
				usage_quantity: '',
				usage_user__person_name: ''
			},
			MonthlyUseformUpdateData: {
				pk: '',
				usage_date: '',
				usage_quantity: '',
				usage_user__person_name: ''
			},
			MonthlyUseformAddDialogVisible: false,
			MonthlyUseformUpdateDialogVisible: false,
			MonthlyUseDemoDialogVisible: false,
			ms_pk: '',
			monthlyUsedeleteItemPk: '',
			date_range: [],
			monthlyUsageBarChart: {
				title: {
					text: '月用量',
					top: 20,
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						dataView: { show: true, readOnly: true },
						magicType: { show: true, type: ['line', 'bar'] },
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				xAxis: {
					type: 'category',
					data: ['3/1', '3/2', '3/3', '3/4', '3/5', '3/6', '3/7', '3/8', '3/9', '3/10', '3/11', '3/12', '3/13', '3/14', '3/15', '3/16']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: '月用量（件）',
						type: 'bar',
						label: {
							show: true,
							position: 'top',
							distance: 10,
							align: 'center',
							verticalAlign: 'middle',
							rotate: 0,
							formatter: '{c}',
							fontSize: 12,
							rich: {
								name: {
									textColor: '#55aaff'
								}
							}
						},
						data: [2, 3, 1, 0, 7, 11, 13, 22, 12, 20, 15, 8, 7, 11, 13, 2]
					}
				],
				animationDuration: 1000
			}
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/marketshare/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						cm_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.$message({
							message: '无访问权限',
							type: 'warning'
						});
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//获取产品清单
		remoteMethod(query) {
			if (query !== '') {
				var that = this;
				this.optionLoading = true;
				setTimeout(() => {
					this.$axios
						.get('/apim/get/productoptions/', {
							params: {
								keywords: query,
								currentpage: 1,
								pagesize: 50
							}
						})
						.then(function(response) {
							if (response.data.code == '401') {
								that.logout();
							} else {
								that.productOption = response.data.data;
								that.optionLoading = false;
							}
						})
						.catch(function(error) {
							that.optionLoading = false;
						});
				}, 200);
			} else {
				this.productOption = [];
			}
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
		},
		//新增月用量数据 - 对话框
		addMonthlyUseData() {
			this.MonthlyUseformAddDialogVisible = true;
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					fd.append('cm_pk', that.$route.params.id);
					this.$axios({
						url: '/apim/add/marketshare/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.resetForm('formAddData');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增月用量数据- 提交
		submitMonthlyUseAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.MonthlyUseformAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					fd.append('ms_pk', that.ms_pk);
					this.$axios({
						url: '/apim/add/monthlyusage/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getProductUsageData(that.ms_pk);
								that.resetForm('MonthlyUseformAdd');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增或更新数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.MonthlyUseformAddDialogVisible = false;
			this.MonthlyUseformUpdateDialogVisible = false;
			this.productOption = [];
			this.formUpdateData= {};
		},
		//更新产品使用数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
			this.productOption = [];
			// console.log(this.formUpdateData)
		},

		//更新月用量数据 - 对话框
		updateMonthlyUseData(index, row) {
			//解除双向绑定
			this.MonthlyUseformUpdateData = JSON.parse(JSON.stringify(row));
			this.MonthlyUseformUpdateDialogVisible = true;
		},
		//更新产品使用数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/marketshare/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
								that.resetForm(formName);
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//更新月用量数据-提交
		submitMonthlyUserUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.MonthlyUseformUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/monthlyusage/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getProductUsageData(that.ms_pk);
								that.MonthlyUseformUpdateDialogVisible = false;
								that.resetForm('formUpdateData')
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该产品使用记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除月用量数据 - 对话框
		deleteMonthlyUseData(index, row) {
			let monthlyUsedeleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该月用量记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteMonthlyUseData(monthlyUsedeleteItemPk);
				})
				.catch(() => {});
		},

		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/marketshare/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.getData(that.keywords, that.currentPage, that.pageSize);
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		//删除月用量数据-提交
		submitDeleteMonthlyUseData(data_Pk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', data_Pk);
			this.$axios({
				url: '/apim/delete/monthlyusage/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					if (res.data.code == '200') {
						that.getProductUsageData(that.ms_pk);
					} else {
						that.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//导出excel
		exportExcel() {
			exportToExcel('distributor', '经销商', this);
		},
		//获取月用量数据
		getProductUsageData(ms_pk) {
			var that = this;
			this.ms_pk = ms_pk;
			this.date_range = [];
			this.$axios
				.get('/apim/get/monthlyusage/', {
					params: {
						ms_pk: ms_pk
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableDataUseage = response.data.data;
						that.MonthlyUseDemoDialogVisible = true;
						that.monthlyUsageBarChart.xAxis.data = response.data.xAxis_data;
						that.monthlyUsageBarChart.series[0].data = response.data.series_data;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//查询时间范围内月用量
		queryProductUsageData() {
			var that = this;
			this.$axios
				.get('/apim/get/monthlyusage/', {
					params: {
						ms_pk: that.ms_pk,
						date_range: that.date_range !== null ? that.date_range.toString() : ''
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableDataUseage = response.data.data;
						that.monthlyUsageBarChart.xAxis.data = response.data.xAxis_data;
						that.monthlyUsageBarChart.series[0].data = response.data.series_data;
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
