import Vue from 'vue';
import axios from "axios";

// 导出数据至EXCEL文件的函数		
export function exportToExcel(type, filename, that) {
	//加载页面
	let fileName = filename + Date.now();
	that.loading = true;
	that.$axios
		.get('api/export/xlsx/', {
			params: {
				type: type,
				keywords: that.keywords,
				ifexpiring: that.ifExpiring
			},
			responseType: 'blob',
			headers: {
				Accept: 'application/json'
			}
		})
		.then(function(res) {
			const _res = res.data;
			let blob = new Blob([_res], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
			});
			let objectUrl = window.URL.createObjectURL(blob);
			let a = document.createElement('a');
			a.href = objectUrl;
			a.download = fileName.replace('.', '');
			a.dispatchEvent(new MouseEvent('click', {
				bubbles: true,
				cancelable: true,
				view: window
			}));
			that.loading = false;
			window.URL.revokeObjectURL(blob);
		})
		.catch(function(err) {
			that.loading = false;
			console.log(err);
		});
}


//对两个数组进行同步排序，按第二个数组的值倒序	
export function descedingSort(nameArray, valueArray) {
	let combined = nameArray.map((name, index) => {
		return {
			name: name,
			value: valueArray[index]
		};
	});
	combined.sort((a, b) => b.value - a.value);
	let sortedNames = combined.map(item => item.name)
	let sortedValues = combined.map(item => item.value);

	return {
		name: sortedNames,
		value: sortedValues
	};
}