import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/echarts.js'
import countTo from 'vue-count-to'
import descedingSort from './api/utils.js'

Vue.component('countTo', countTo)
Vue.prototype.$domainPrefix = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/media/' : '/media/',
Vue.prototype.$domain = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000' : '',
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
