<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="small" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="monthrange" align="right" unlink-panels
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
							value-format="yyyy-MM" />
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区" multiple collapse-tags>
							<el-option v-for="item in areaOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="brand">
						<el-select v-model="searchData.brand" placeholder="请选择品牌">
							<el-option v-for="item in brandOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="products">
						<el-cascader filterable v-model="searchData.products" :options="productOptions"
							:props="{ multiple: true,  expandTrigger: 'hover',}" collapse-tags clearable
							placeholder="请选择产品">
						</el-cascader>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看报表</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<el-button type="success" @click="exportTableData(searchData,'产品销量按地区')">导出</el-button>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item>
						<el-checkbox v-model="quantity_checked">销量</el-checkbox>
						<el-checkbox v-model="amount_checked">订货额</el-checkbox>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item label="图表风格">
						<el-select v-model="echartTheme">
							<el-option v-for="theme in themes" :key="theme.value" :label="theme.label"
								:value="theme.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>

		<el-row style="margin-top:10px;">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="tableActivateName">
						<el-tab-pane label="按品类" name="table_productType">

							<el-table :data="tableDataProductCategory" style="width: 100%; " show-summary max-height="600">
								<el-table-column label="品类" prop="category" sortable fixed
									width="120"></el-table-column>
								<el-table-column label="品牌" prop="brand" sortable fixed width="100"></el-table-column>
								<el-table-column label="产品地区销售明细表 - 按品类">
									<el-table-column :label="item.label"
										v-for="(item,index) in tableHeaderProductCategory" :key="item.label">
										<el-table-column label="销量(桶)" :prop="item.prop_quantity" sortable width="120"
											v-if="quantity_checked" />
										<el-table-column label="订货额(元)" :prop="item.prop_amount" sortable width="120"
											v-if="amount_checked" />
									</el-table-column>
								</el-table-column>
							</el-table>

						</el-tab-pane>

						<el-tab-pane label="按单品" name="table_productDetail">
							<el-table :data="tableDataProductDetail" style="width: 100%; " show-summary max-height="600">
								<el-table-column label="产品" sortable prop="product_name" fixed
									width="180"></el-table-column>
								<el-table-column label="品牌" sortable fixed prop="product_brand"
									width="100"></el-table-column>
								<el-table-column label="产品地区销售明细表 - 按单品">
									<el-table-column :label="item.label"
										v-for="(item, index) in tableHeaderProductDetail" :key="item.label">
										<el-table-column label="销量(桶)" :prop="item.prop_quantity" sortable width="120"
											v-if="quantity_checked" />
										<el-table-column label="订货额(元)" :prop="item.prop_amount" sortable width="120"
											v-if="amount_checked" />
									</el-table-column>

								</el-table-column>
							</el-table>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="barChartActiveName">
						<el-tab-pane label="按品类" name="bar_type">
							<div class="plate-card"><v-chart :options="bar_quantity_type" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
							<div class="plate-card"><v-chart :options="bar_amount_type" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>

						<el-tab-pane label="按单品" name="bar_detail">
							<el-row :gutter="25">
								<el-col :sm="12" :xs="24">
									<div style="height: 800px;"><v-chart :options="bar_quantity_detail" autoresize
											:theme="echartTheme">/></v-chart>
									</div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div style="height: 800px;"><v-chart :options="bar_amount_detail" autoresize
											:theme="echartTheme">/></v-chart>
									</div>
								</el-col>

							</el-row>


						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
		<!-- 		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="pieChartActiveName">
						<el-tab-pane label="按品类" name="pie_ruhof">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_type_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_type_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_detail_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_detail_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

						<el-tab-pane label="按单品" name="pie_anji">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_type_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_type_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_detail_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_detail_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row> -->
	</div>
</template>


<script>
	import ECharts from 'vue-echarts';
	import {
		descedingSort
	} from '../../../api/utils';
	export default {
		inject: ['logout'],
		data() {
			return {
				searchData: {
					area: '',
					date_range: '',
					brand: '',
					products: ''
				},
				quantity_checked: true,
				amount_checked: true,
				echartTheme: 'light',
				brandOptions: [{
					value: 'all',
					label: '国产+进口'
				}, {
					value: '进口鲁沃夫',
					label: '进口鲁沃夫'
				}, {
					value: '国产鲁沃夫',
					label: '国产鲁沃夫'
				}],
				productOptions: [],
				themes: [{
						value: 'light',
						label: '默认'
					},
					{
						value: 'dark',
						label: '黑暗'
					},
					{
						value: 'shine',
						label: '闪亮'
					},
					{
						value: 'vintage',
						label: '复古'
					},
					{
						value: 'helianthus',
						label: '太阳花'
					},
					{
						value: 'tech-blue',
						label: '科技蓝'
					},
					{
						value: 'london',
						label: '伦敦'
					},
					{
						value: 'roma',
						label: '罗马'
					},
					{
						value: 'sakura',
						label: '樱花粉'
					},
					{
						value: 'fresh-cut',
						label: '鲜果切'
					}
					// 你可以继续添加其他主题
				],
				tableDataProductCategory: [],
				tableHeaderProductCategory: [],
				tableDataProductDetail: [],
				tableHeaderProductDetail: [],
				loading: false,
				tableActivateName: 'table_productType',
				barChartActiveName: 'bar_type',
				pieChartActiveName: 'pie_total',
				areaOptions: [],

				bar_quantity_type: {},
				bar_amount_type: {},

				bar_quantity_detail: {},
				bar_amount_detail: {},

				pie_quantity_type_ruhof: {},
				pie_amount_type_ruhof: {},
				pie_quantity_type_anji: {},
				pie_amount_type_anji: {},
				pie_quantity_type_total: {},
				pie_amount_type_total: {},

				pie_quantity_detail_ruhof: {},
				pie_amount_detail_ruhof: {},
				pie_quantity_detail_anji: {},
				pie_amount_detail_anji: {},
				pie_quantity_detail_total: {},
				pie_amount_detail_total: {},

				pie_sale_quantity: {
					title: {
						text: '地区销量构成',
						left: 'center',
						top: 20
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 桶 ({d}%)'
					},
					series: [{
						name: '销量',
						type: 'pie',
						center: ['50%', '50%'],
						selectedMode: 'single',
						radius: [0, '60%'],

						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							rich: {
								b: {
									fontSize: 14,
									lineHeight: 33,
									padding: [2, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						data: []
					}],
					animationDuration: 2000
				},
				pie_sale_amount: {
					title: {
						text: '地区订货额构成',
						left: 'center',
						top: 20
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 元 ({d}%)'
					},
					series: [{
						name: '订货额',
						type: 'pie',
						radius: ['30%', '55%'],
						center: ['50%', '50%'],
						data: [],
						// roseType: 'area',
						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							rich: {
								b: {
									fontSize: 14,
									lineHeight: 33,
									padding: [2, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.1)'
						},
						animationType: 'scale',
						animationEasing: 'elasticOut',
						animationDelay: function(idx) {
							return Math.random() * 200;
						}
					}],
					animationDuration: 2000
				},
				//标准条形图设置
				bar_chart_quantity: {
					title: {
						text: '地区销量(桶)',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							// 初始化 tooltip 文本
							let tooltipText = `${params[0].axisValue}`;
							
							params.sort((a, b) => b.value - a.value);
							// 计算当前分类轴的总值（用于百分比计算）
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							// 遍历所有系列的数据项，构建每一行的文本
							tooltipText += ' ( 总计：' + totalValue.toLocaleString() +  ` 桶 ) <br/>`
							
							params.forEach((item, index) => {
								// 格式化当前数据项的值
								const formattedValue = item.value.toLocaleString();

								// 计算百分比
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';

								// 构建 tooltip 的每一行
								tooltipText +=
									` ${item.marker}${item.seriesName}：${formattedValue}（桶）（${percentage}%）`;

								if ((index + 1) % 3 === 0 && index !== params.length - 1) {
									tooltipText += `<br/>`; // 每隔2个元素加一个换行符
								}
							});
							
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'value',
					}],
					yAxis: [{
						type: 'category',
						data: []
					}],
					series: [{
							name: '地区1',
							type: 'bar',
							stack: 'catalog',
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '地区2',
							type: 'bar',
							stack: 'catalog',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
				bar_chart_amount: {
					title: {
						text: '地区订货额（元）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}`;						
							params.sort((a, b) => b.value - a.value);
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							tooltipText += ' ( 总计：' + totalValue.toLocaleString() +  ` 元 ) <br/>`							
							params.forEach((item, index) => {
								const formattedValue = item.value.toLocaleString();
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';
								tooltipText +=
									` ${item.marker}${item.seriesName}：${formattedValue}（元）（${percentage}%）`;
								if ((index + 1) % 3 === 0 && index !== params.length - 1) {
									tooltipText += `<br/>`; 
								}
							});							
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'value',

					}],
					yAxis: [{
						type: 'category',
						data: []
					}],
					series: [{
							name: '进口鲁沃夫',
							type: 'bar',
							stack: 'product',
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '国产鲁沃夫',
							type: 'bar',
							stack: 'product',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
			};
		},
		mounted() {
			// 获取数据
			this.getAreaOptions();
			this.getProductOptions();
		},
		created() {
			// 深拷贝数据以取消双向绑定

			this.bar_quantity_type = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_quantity_detail = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_amount_type = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};

			this.bar_amount_detail = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};

			// this.pie_quantity_type_ruhof = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_quantity_type_anji = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_quantity_type_total = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_amount_type_ruhof = JSON.parse(JSON.stringify(this.pie_sale_amount));
			// this.pie_amount_type_anji = JSON.parse(JSON.stringify(this.pie_sale_amount));
			// this.pie_amount_type_total = JSON.parse(JSON.stringify(this.pie_sale_amount));

			// this.pie_quantity_detail_ruhof = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_quantity_detail_anji = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_quantity_detail_total = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			// this.pie_amount_detail_ruhof = JSON.parse(JSON.stringify(this.pie_sale_amount));
			// this.pie_amount_detail_anji = JSON.parse(JSON.stringify(this.pie_sale_amount));
			// this.pie_amount_detail_total = JSON.parse(JSON.stringify(this.pie_sale_amount));
		},

		methods: {
			//重置搜索表单
			resetSearchForm(formName) {
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
			},
			//提交搜索
			submitFormSearchData() {
				console.log(this.searchData)
				this.getTableAndChartData(this.searchData);
			},
			// 获取地区列表
			getAreaOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_area_list/')
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.areaOptions = response.data;
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 获取产品列表
			getProductOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_product_category_list/')
					.then(function(response) {
						that.productOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			//获取表格及图表数据
			getTableAndChartData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}
				this.$axios({
						url: '/apis/get_monthly_product_area_sales/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						console.log(res)
						that.loading = false;
						if (res.data.code == '401') {
							that.logout();
						} else {
							let date_range = res.data.date_range;

							that.tableDataProductCategory = res.data.tableDataProductCategory;
							that.tableHeaderProductCategory = res.data.tableHeaderProductCategory;
							that.tableDataProductDetail = res.data.tableDataProductDetail;
							that.tableHeaderProductDetail = res.data.tableHeaderProductDetail;

							that.bar_quantity_type.title.text = date_range + '各地区销量（桶）'
							that.bar_quantity_type.yAxis[0].data = res.data.bar_type_xAxis_data;
							that.bar_quantity_type.series = res.data.bar_quantity_type_data;

							that.bar_amount_type.title.text = date_range + '各地区订货额（元）'
							that.bar_amount_type.yAxis[0].data = res.data.bar_type_xAxis_data;
							that.bar_amount_type.series = res.data.bar_amount_type_data;


							that.bar_quantity_detail.title.text = date_range + '各地区销量（桶）'
							that.bar_quantity_detail.yAxis[0].data = res.data.bar_detail_xAxis_data;
							that.bar_quantity_detail.series = res.data.bar_quantity_detail_data;

							that.bar_amount_detail.title.text = date_range + '各地区订货额（元）'
							that.bar_amount_detail.yAxis[0].data = res.data.bar_detail_xAxis_data;
							that.bar_amount_detail.series = res.data.bar_amount_detail_data;


							// that.pie_quantity_type_ruhof.series[0].data = res.data.pie_quantity_type_ruhof_series;
							// that.pie_amount_type_ruhof.series[0].data = res.data.pie_amount_type_ruhof_series;

							// that.pie_quantity_type_anji.series[0].data = res.data.pie_quantity_type_anji_series;
							// that.pie_amount_type_anji.series[0].data = res.data.pie_amount_type_anji_series;

							// that.pie_quantity_type_total.series[0].data = res.data.pie_quantity_type_total_series;
							// that.pie_amount_type_total.series[0].data = res.data.pie_amount_type_total_series;

							// that.pie_quantity_detail_ruhof.series[0].data = res.data.pie_quantity_detail_ruhof_series;
							// that.pie_amount_detail_ruhof.series[0].data = res.data.pie_amount_detail_ruhof_series;

							// that.pie_quantity_detail_anji.series[0].data = res.data.pie_quantity_detail_anji_series;
							// that.pie_amount_detail_anji.series[0].data = res.data.pie_amount_detail_anji_series;

							// that.pie_quantity_detail_total.series[0].data = res.data.pie_quantity_detail_total_series;
							// that.pie_amount_detail_total.series[0].data = res.data.pie_amount_detail_total_series;

						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},

			//下载数据
			exportTableData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}

				let fileName = filename + Date.now();

				this.$axios({
						url: '/apis/export_monthly_product_area_sales/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 450px;
	}



	.margin-bottom-sm {
		margin-bottom: 10px;
	}
</style>