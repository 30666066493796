<template>
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card>
					<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="8" :md="8" >
						<el-button style="margin: 5px;" size="mini" type="success" @click="addData()" v-if="$store.state.userPrivilege > 2">新增产品</el-button>
						<!-- <el-button style="margin: 5px;" size="mini">导出</el-button> -->
					</el-col>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-tabs v-model="props.row.activeTab" type="card">
								<el-tab-pane label="产品资料" name="prodInfo">
									<el-form label-position="left" inline class="demo-table-expand" label-width="100px">
										<el-row>
											<el-col :xs="24" :sm="6"><img :src="$domainPrefix + props.row.prop_pic" style="height: 150px; width: auto;" /></el-col>
											<el-col :xs="24" :sm="9">
												<el-form-item label="产品品牌">
													<span>{{ props.row.prod_brand__brand_name }}</span>
												</el-form-item>
												<el-form-item label="产品名称">
													<span>{{ props.row.prod_name }}</span>
												</el-form-item>
												<el-form-item label="产品分类">
													<span>{{ props.row.prop_type__type_name }}</span>
												</el-form-item>
												<el-form-item label="产品规格">
													<span>{{ props.row.prop_spec }}</span>
												</el-form-item>
												<el-form-item label="产品编号">
													<span>{{ props.row.prop_serial }}</span>
												</el-form-item>
												<el-form-item label="最小包装单位">
													<span>{{ props.row.prop_unit }}</span>
												</el-form-item>
											</el-col>
											<el-col :xs="24" :sm="9">
												<el-form-item label="装箱规格">
													<span>{{ props.row.prop_packing_spec }}</span>
												</el-form-item>
												<el-form-item label="有效期">
													<span>{{ props.row.prop_shelf_time }}</span>
												</el-form-item>
												<el-form-item label="原产地">
													<span>{{ props.row.prop_origin_place }}</span>
												</el-form-item>
												<el-form-item label="生产商">
													<span>{{ props.row.prop_manufacturer }}</span>
												</el-form-item>
												<el-form-item label="供应商">
													<span>{{ props.row.prop_supplier }}</span>
												</el-form-item>
												<el-form-item label="上市时间">
													<span>{{ props.row.prop_time_to_market }}</span>
												</el-form-item>
											</el-col>
										</el-row>
									</el-form>
								</el-tab-pane>
								<el-tab-pane label="产品说明" name="prodIfu">
									<el-form label-position="left" class="demo-table-expand" label-width="100px">
										<el-row>
											<el-col :xs="24" :sm="24">
												<el-form-item label="主要成分">
													<span>{{ props.row.prop_ingredient }}</span>
												</el-form-item>
												<el-form-item label="产品特性">
													<span>{{ props.row.prop_character }}</span>
												</el-form-item>
												<el-form-item label="使用说明">
													<span>{{ props.row.prop_ifu }}</span>
												</el-form-item>
											</el-col>
										</el-row>
									</el-form>
								</el-tab-pane>
								<el-tab-pane label="相关文件" name="prodFile" style="min-height: 200px;">
									<el-link
										v-for="item in props.row.prod_files"
										:key="item.id"
										icon="el-icon-download"
										:underline="false"
										type="primary"
										:href="$domainPrefix + item.pro_file"
										target="_blank"
										style="display: block;"
									>
										{{ item.pro_file.replace('profiles/', '') }}
									</el-link>
								</el-tab-pane>
							</el-tabs>
						</template>
					</el-table-column>

					<el-table-column label="品牌" prop="prod_brand__brand_name" sortable show-overflow-tooltip />
					<el-table-column label="品名" prop="prod_name" show-overflow-tooltip sortable   min-width="150"/>
					<el-table-column label="类型" prop="prop_type__type_name" show-overflow-tooltip sortable></el-table-column>
					<el-table-column label="规格" prop="prop_spec" show-overflow-tooltip sortable/>
					<el-table-column label="装箱规格" prop="prop_packing_spec" show-overflow-tooltip sortable/>
<!-- 					<el-table-column width="150" label="价格用量" align="center" v-if="$store.state.userPrivilege > 1">
						<template slot-scope="scope">
							<el-button icon="el-icon-data-line" type="text" @click="jumpTo('/prodashboard/', scope.row.pk)">查看</el-button>
						</template>
					</el-table-column> -->
					<el-table-column width="150" label="操作" align="center" v-if="$store.state.userPrivilege > 2">
						<template slot-scope="scope">
							<el-button-group>
								<el-button size="mini" icon="el-icon-edit" @click="updateData(scope.$index, scope.row)">改</el-button>
								<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteData(scope.$index, scope.row)">删</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="total, sizes, prev, pager, next"
				:total="totalItems"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="prev, pager, next"
				:total="totalItems"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				prev-text="上一页"
				next-text="下一页"
				:pager-count="5"
			/>
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增产品"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddProduct" :rules="rules" :model="formAddProduct" :label-width="formLabelWidth" size="mini">
				<el-row>
					<el-col :xs="24" :sm="12">
						<el-form-item label="品名" prop="prod_name"><el-input v-model="formAddProduct.prod_name" clearable /></el-form-item>
						<el-form-item label="规格" prop="prop_spec"><el-input v-model="formAddProduct.prop_spec" clearable /></el-form-item>
						<el-form-item label="品牌" prop="prod_brand">
							<el-select v-model="formAddProduct.prod_brand" placeholder="请选择" filterable allow-create default-first-option >
								<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="类型" prop="prop_type">
							<el-select v-model="formAddProduct.prop_type" placeholder="请选择" filterable allow-create default-first-option >
								<el-option v-for="item in prodTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="编号" prop="prop_serial"><el-input v-model="formAddProduct.prop_serial" clearable /></el-form-item>
						<el-form-item label="最小包装单位" prop="prop_unit"><el-input v-model="formAddProduct.prop_unit" clearable /></el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12">
						<el-form-item label="装箱规格" prop="prop_packing_spec"><el-input v-model="formAddProduct.prop_packing_spec" clearable /></el-form-item>
						<el-form-item label="有效期" prop="prop_shelf_time"><el-input v-model="formAddProduct.prop_shelf_time" clearable /></el-form-item>
						<el-form-item label="原产地" prop="prop_origin_place"><el-input v-model="formAddProduct.prop_origin_place" clearable /></el-form-item>
						<el-form-item label="生产商" prop="prop_manufacturer"><el-input v-model="formAddProduct.prop_manufacturer" clearable /></el-form-item>
						<el-form-item label="供应商" prop="prop_supplier"><el-input v-model="formAddProduct.prop_supplier" clearable /></el-form-item>
						<el-form-item label="上市时间" prop="prop_time_to_market">
							<el-date-picker v-model="formAddProduct.prop_time_to_market" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="主要成分" prop="prop_ingredient">
					<el-input v-model="formAddProduct.prop_ingredient" type="textarea" clearable maxlength="250" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品特性" prop="prop_character">
					<el-input v-model="formAddProduct.prop_character" type="textarea" clearable maxlength="500" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品说明" prop="prop_ifu">
					<el-input v-model="formAddProduct.prop_ifu" type="textarea" clearable maxlength="1000" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品图片" prop="thumb">
					<el-upload
						action="none"
						list-type="picture"
						:auto-upload="false"
						accept="image/png,image/gif,image/jpeg"
						:limit="1"
						:on-change="handleChange"
						:on-remove="handleRemove"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过一张</div>
					</el-upload>
				</el-form-item>

				<el-form-item label="相关文件">
					<el-upload
						action="none"
						list-type="picture"
						:on-change="handleChangeFile"
						:on-remove="handleRemoveFile"
						:auto-upload="false"
						accept="image/*,.pdf,.doc,.docx"
						:file-list="fileList"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">可上传图片\pdf文件\word文档</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddProduct')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddProduct')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改产品"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateProduct" :rules="rules" :model="formUpdateProduct" :label-width="formLabelWidth" size="mini">
				<el-row>
					<el-col :xs="24" :sm="12">
						<el-form-item label="品名" prop="prod_name"><el-input v-model="formUpdateProduct.prod_name" clearable /></el-form-item>
						<el-form-item label="规格" prop="prop_spec"><el-input v-model="formUpdateProduct.prop_spec" clearable /></el-form-item>
						<el-form-item label="品牌" prop="prod_brand">
							<el-select v-model="formUpdateProduct.prod_brand" placeholder="请选择">
								<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="类型" prop="prop_type">
							<el-select v-model="formUpdateProduct.prop_type" placeholder="请选择">
								<el-option v-for="item in prodTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="编号" prop="prop_serial"><el-input v-model="formUpdateProduct.prop_serial" clearable /></el-form-item>
						<el-form-item label="最小包装单位" prop="prop_unit"><el-input v-model="formUpdateProduct.prop_unit" clearable /></el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12">
						<el-form-item label="装箱规格" prop="prop_packing_spec"><el-input v-model="formUpdateProduct.prop_packing_spec" clearable /></el-form-item>
						<el-form-item label="有效期" prop="prop_shelf_time"><el-input v-model="formUpdateProduct.prop_shelf_time" clearable /></el-form-item>
						<el-form-item label="原产地" prop="prop_origin_place"><el-input v-model="formUpdateProduct.prop_origin_place" clearable /></el-form-item>
						<el-form-item label="生产商" prop="prop_manufacturer"><el-input v-model="formUpdateProduct.prop_manufacturer" clearable /></el-form-item>
						<el-form-item label="供应商" prop="prop_supplier"><el-input v-model="formUpdateProduct.prop_supplier" clearable /></el-form-item>
						<el-form-item label="上市时间" prop="prop_time_to_market">
							<el-date-picker v-model="formUpdateProduct.prop_time_to_market" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="主要成分" prop="prop_ingredient">
					<el-input v-model="formUpdateProduct.prop_ingredient" type="textarea" clearable maxlength="250" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品特性" prop="prop_character">
					<el-input v-model="formUpdateProduct.prop_character" type="textarea" clearable maxlength="500" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品说明" prop="prop_ifu">
					<el-input v-model="formUpdateProduct.prop_ifu" type="textarea" clearable maxlength="1000" autosize show-word-limit />
				</el-form-item>
				<el-form-item label="产品图片" prop="thumb">
					<img v-if="formUpdateProduct.prop_pic" style="height:100px;width:auto" :src="$domainPrefix + formUpdateProduct.prop_pic" alt="" />
					<el-upload
						action="none"
						list-type="picture"
						:auto-upload="false"
						accept="image/png,image/gif,image/jpeg"
						:limit="1"
						:on-change="handleChangeOld"
						:on-remove="handleRemoveOld"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过一张</div>
					</el-upload>
				</el-form-item>
				<el-form-item label="相关文件">
					<el-upload
						action="none"
						list-type="picture"
						:on-change="handleChangeUpdateFile"
						:on-remove="handleRemoveUpdateFile"
						:auto-upload="false"
						accept="image/*,.pdf,.doc,.docx"
						:file-list="fileList"
					>
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">可上传图片\pdf文件\word文档</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateProduct')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateProduct')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddProduct: {
				pk: '',
				prod_brand__brand_name: '',
				prop_type__type_name: '',
				prod_brand: '',
				prod_name: '',
				prop_type: '',
				prop_spec: '',
				prop_serial: '',
				prop_unit: '',
				prop_packing_spec: '',
				prop_ingredient: '',
				prop_character: '',
				prop_ifu: '',
				prop_shelf_time: '',
				prop_pic: '',
				prop_origin_place: '',
				prop_manufacturer: '',
				prop_supplier: '',
				prop_time_to_market: '',
				prod_files: []
			},
			formUpdateProduct: {
				pk: '',
				prod_brand__brand_name: '',
				prop_type__type_name: '',
				prod_brand: '',
				prod_name: '',
				prop_type: '',
				prop_spec: '',
				prop_serial: '',
				prop_unit: '',
				prop_packing_spec: '',
				prop_ingredient: '',
				prop_character: '',
				prop_ifu: '',
				prop_shelf_time: '',
				prop_pic: '',
				prop_origin_place: '',
				prop_manufacturer: '',
				prop_supplier: '',
				prop_time_to_market: '',
				prod_files: [],
				ifDeleteAllfiles: false
			},
			//新增和更新表单的验证规则
			rules: {
				prod_name: [{ required: true, message: '请输入产品名称', trigger: 'blur' }, { max: 40, message: '长度不超过40个字', trigger: 'blur' }],
				prop_type: [{ required: true, message: '请选择产品类型', trigger: 'change' }],
				prop_spec: [{ required: true, message: '请输入产品规格', trigger: 'blur' }],
				prod_brand: [{ required: true, message: '请选择产品品牌', trigger: 'change' }]
			},
			formLabelWidth: '80px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			tabActiveName: {},
			brandOptions: [],
			prodTypeOptions: [],
			fileList: [],
			loading: false
		};
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		this.getBrandOptions();
		this.getProdTypeOptions();
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			this.$axios
				.get('/apim/get/product/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						let temp_data = response.data.data;
						temp_data.forEach(el => {
							el.activeTab = 'prodInfo';
						});
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddProduct;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					//单独添加文件数据
					for (let item in formData.prod_files) {
						fd.append('fileList', formData.prod_files[item].raw, formData.prod_files[item].name);
					}

					this.$axios({
						url: '/apim/add/product/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.reload();
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.fileList = [];
		},
		//新增数据 - 上传插件变更图片
		handleChange(file, fileList) {
			this.formAddProduct.thumb = file.raw;
			console.log(file, fileList);
		},
		//新增数据 - 上传插件移除图片操作
		handleRemove(file, fileList) {
			this.formAddProduct.thumb = '';
			console.log(file, fileList);
		},

		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateProduct = JSON.parse(JSON.stringify(row));
			let tempData = this.formUpdateProduct.prod_files;
			//拼接上传插件预览需要的对象数组
			for (let item in tempData) {
				let newObj = Object.create({
					name: tempData[item].pro_file.replace('profiles/', ''),
					url: this.$domainPrefix + tempData[item].pro_file,
					id: tempData[item].id
				});
				this.fileList.push(newObj);
			}
			this.formUpdateDialogVisible = true;
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateProduct;
					console.log(formData);
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					if (formData.prop_time_to_market == null) {
						fd.set('prop_time_to_market', '');
					}
					//单独添加文件数据， fileList 为新增的文件，existFile为之前保存的文件
					for (let item in formData.prod_files) {
						if (formData.prod_files[item].raw) {
							fd.append('fileList', formData.prod_files[item].raw, formData.prod_files[item].name);
						} else {
							fd.append('existFile', formData.prod_files[item].id);
						}
					}
					this.$axios({
						url: '/apim/update/product/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//更新数据 - 上传插件变更图片
		handleChangeOld(file, fileList) {
			this.formUpdateProduct.prop_pic = file.raw;
			// console.log(file, fileList);
		},
		//更新数据 - 上传插件移除图片操作
		handleRemoveOld(file, fileList) {
			this.formUpdateProduct.prop_pic = '';
			// console.log(file, fileList);
		},
		//新增 - 新文件上传
		handleChangeFile(file, fileList) {
			this.formAddProduct.prod_files = fileList;
		},
		//新增 - 文件列表变动
		handleRemoveFile(file, fileList) {
			this.formAddProduct.prod_files = fileList;
		},
		//修改 - 新文件上传
		handleChangeUpdateFile(file, fileList) {
			this.formUpdateProduct.prod_files = fileList;
		},
		//修改 - 文件列表变动
		handleRemoveUpdateFile(file, fileList) {
			this.formUpdateProduct.prod_files = fileList;
			if (fileList.length == 0) {
				this.formUpdateProduct.ifDeleteAllfiles = true;
			}
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/product/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.reload();
					} else {
						that.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		// 获取品牌列表
		getBrandOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/brandoptions/')
				.then(function(response) {
					that.brandOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		// 获取品牌列表
		getProdTypeOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/prodtypeoptions/')
				.then(function(response) {
					that.prodTypeOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//路由跳转
		jumpTo(name, id) {
			let fullpath = name + id + '/';
			this.$router.push({ path: fullpath });
		}
	}
};
</script>

<style>
.demo-table-expand .el-form-item {
	margin: 0;
	padding: 0;
	width: 100%;
}
</style>
