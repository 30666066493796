<template>
	<div>
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker
							v-model="searchData.date_range"
							type="monthrange"
							align="right"
							unlink-panels
							range-separator="至"
							start-placeholder="开始月份"
							end-placeholder="结束月份"
							value-format="yyyy-MM-dd"
							size="mini"
						/>
					</el-form-item>
					<el-form-item prop="product_type">
						<el-select v-model="searchData.product_type" placeholder="请选择产品类型">
							<el-option v-for="item in prodTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区">
							<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看统计</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>
		<el-row :gutter="5" class="margin-bottom-sm">
			<el-col :sm="12" :xs="24">
				<div class="plate-card"><v-chart :options="pie_sale_volumn" autoresize theme="light">/></v-chart></div>
			</el-col>
			<el-col :sm="12" :xs="24">
				<div class="plate-card"><v-chart :options="pie_sale_amount" autoresize theme="light" /></div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import ECharts from 'vue-echarts';
export default {
	inject: ['logout'],
	data() {
		return {
			searchData: {
				product_type: '',
				area: '',
				date_range: ''
			},
			brandOptions: [],
			prodTypeOptions: [],
			provinceOptions: [
				{
					value: '北京',
					label: '北京'
				},
				{
					value: '天津',
					label: '天津'
				},
				{
					value: '上海',
					label: '上海'
				},
				{
					value: '重庆',
					label: '重庆'
				},
				{
					value: '河北',
					label: '河北'
				},
				{
					value: '山西',
					label: '山西'
				},
				{
					value: '辽宁',
					label: '辽宁'
				},
				{
					value: '吉林',
					label: '吉林'
				},
				{
					value: '黑龙江',
					label: '黑龙江'
				},
				{
					value: '江苏',
					label: '江苏'
				},
				{
					value: '浙江',
					label: '浙江'
				},
				{
					value: '安徽',
					label: '安徽'
				},
				{
					value: '福建',
					label: '福建'
				},
				{
					value: '江西',
					label: '江西'
				},
				{
					value: '山东',
					label: '山东'
				},
				{
					value: '河南',
					label: '河南'
				},
				{
					value: '湖北',
					label: '湖北'
				},
				{
					value: '湖南',
					label: '湖南'
				},
				{
					value: '广东',
					label: '广东'
				},
				{
					value: '甘肃',
					label: '甘肃'
				},
				{
					value: '四川',
					label: '四川'
				},
				{
					value: '贵州',
					label: '贵州'
				},
				{
					value: '海南',
					label: '海南'
				},
				{
					value: '云南',
					label: '云南'
				},
				{
					value: '青海',
					label: '青海'
				},
				{
					value: '陕西',
					label: '陕西'
				},
				{
					value: '广西',
					label: '广西'
				},
				{
					value: '西藏',
					label: '西藏'
				},
				{
					value: '宁夏',
					label: '宁夏'
				},
				{
					value: '新疆',
					label: '新疆'
				},
				{
					value: '内蒙',
					label: '内蒙'
				},
				{
					value: '澳门',
					label: '澳门'
				},
				{
					value: '香港',
					label: '香港'
				},
				{
					value: '台湾',
					label: '台湾'
				}
			],
			pie_sale_volumn: {
				title: {
					text: '2021年各品牌销量',
					left: 'center',
					top: 20
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						dataView: { show: true, readOnly: true },
						magicType: {
							show: true,
							type: ['pie', 'funnel']
						},
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				tooltip: {
					trigger: 'item',
					formatter: '{a} <br/>{b} : {c} 件 ({d}%)'
				},
				series: [
					{
						name: '销量',
						type: 'pie',
						center: ['50%', '50%'],
						selectedMode: 'single',
						radius: [0, '60%'],

						label: {
							position: 'inner'
						},
						labelLine: {
							show: false
						},
						data: [{ value: 10, name: '品牌1', selected: true }, { value: 10, name: '品牌2' }, { value: 10, name: '品牌3' }]
					}
				],
				animationDuration: 3000
			},
			pie_sale_amount: {
				title: {
					text: '2021年各品牌销售额',
					left: 'center',
					top: 20
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						dataView: { show: true, readOnly: true },
						magicType: {
							show: true,
							type: ['pie', 'funnel']
						},
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				tooltip: {
					trigger: 'item',
					formatter: '{a} <br/>{b} : {c} 元 ({d}%)'
				},
				series: [
					{
						name: '销售额',
						type: 'pie',
						radius: ['30%', '55%'],
						center: ['50%', '50%'],
						data: [
							{ value: 100, name: '品牌1' },
							{ value: 100, name: '品牌2' },
							{ value: 100, name: '品牌3' },
							{ value: 100, name: '品牌4' },
							{ value: 100, name: '品牌5' }
						],
						// roseType: 'area',
						label: {
							formatter: '{b|{b}}\n{c}  {per|{d}%}  ',
							rich: {
								b: {
									fontSize: 15,
									lineHeight: 33,
									padding: [2, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#358dff',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.1)'
						},
						animationType: 'scale',
						animationEasing: 'elasticOut',
						animationDelay: function(idx) {
							return Math.random() * 200;
						}
					}
				],
				animationDuration: 3000
			}
		};
	},
	mounted() {
		// 获取数据
		this.getProdTypeOptions();
	},
	created() {
		this.getDashboardData(this.searchData);
	},
	methods: {
		//重置搜索表单
		resetSearchForm(formName) {
			var that = this;
			this.$refs[formName].resetFields();
			this.$refs[formName].clearValidate();
			this.getDashboardData(that.searchData);
		},

		//提交搜索
		submitFormSearchData() {
			this.getDashboardData(this.searchData);
		},

		// 获取品牌列表
		getBrandOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/brandoptions/')
				.then(function(response) {
					that.brandOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		// 获取产品分类列表
		getProdTypeOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/prodtypeoptions/')
				.then(function(response) {
					that.prodTypeOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},

		//获取仪表板数据
		getDashboardData(queryData) {
			var that = this;
			that.loading = true;
			//添加表单数据
			let fd = new FormData();
			let formData = queryData;
			for (let key in formData) {
				fd.append(key, formData[key]);
			}

			this.$axios({
				url: '/apim/get/statbrandshare/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					if (res.data.code == '401') {
						that.logout();
					} else {
						that.pie_sale_volumn.series[0].data = res.data.pie_data_volumn;
						that.pie_sale_volumn.title.text = res.data.date_range + res.data.area + '地区销量';
						that.pie_sale_amount.series[0].data = res.data.pie_data_amount;
						that.pie_sale_amount.title.text = res.data.date_range + res.data.area + '地区销售额';
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		}
	}
};
</script>

<style scoped>
.title-text {
	color: #00a6dd;
	font-size: 18px;
	font-weight: 800;
}

.item {
	margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: '';
}
.clearfix:after {
	clear: both;
}

.box-card {
	width: auto;
	margin: 0;
}

.echarts {
	width: 100%;
	height: 100%;
}

.plate-card {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	height: 500px;
}

.margin-bottom-sm {
	margin-bottom: 10px;
}
</style>
