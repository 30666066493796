<template>
	<div>
		<el-row :gutter="5" class="margin-bottom-sm">
			<el-col :sm="6" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12"><el-avatar :src="require('../../../assets/customer.svg')" /></el-col>
						<el-col :span="12">
							<div><strong>客户记录</strong></div>
							<div class="title-text"><countTo :start-val="0" :end-val="customer_record_num" :duration="3000" /></div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>

			<el-col :sm="6" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12"><el-avatar :src="require('../../../assets/equip.svg')" /></el-col>
						<el-col :span="12">
							<div><strong>设备设施</strong></div>
							<div class="title-text"><countTo :start-val="0" :end-val="equip_record_num" :duration="3000" /></div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="6" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12"><el-avatar :src="require('../../../assets/product.svg')" /></el-col>
						<el-col :span="12">
							<div><strong>产品记录</strong></div>
							<div class="title-text"><countTo :start-val="0" :end-val="product_record_num" :duration="3000" /></div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
			<el-col :sm="6" :xs="24">
				<el-card class="box-card">
					<el-row>
						<el-col :span="12"><el-avatar :src="require('../../../assets/company.svg')" /></el-col>
						<el-col :span="12">
							<div><strong>公司记录</strong></div>
							<div class="title-text"><countTo :start-val="0" :end-val="company_record_num" :duration="3000" /></div>
						</el-col>
					</el-row>
				</el-card>
			</el-col>
		</el-row>
		<el-row :gutter="10" style="margin-top: 10px;">
			<el-col>
				<el-card>
					<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
						<el-tab-pane name="first">
							<span slot="label">
								<i class="el-icon-s-cooperation"></i>
								市场占有
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="a" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="second">
							<span slot="label">
								<i class="el-icon-s-platform"></i>
								年度统计
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="b" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
<!-- 						<el-tab-pane name="third">
							<span slot="label">
								<i class="el-icon-s-platform"></i>
								设备使用
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="c" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane> -->
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			activeName: 'first',
			isRouterAlive: true,
			customer_record_num: 0,
			equip_record_num: 0,
			product_record_num: 0,
			company_record_num: 0
		};
	},
	mounted() {
		this.getStatisticData();
	},
	methods: {
		//路由跳转
		jumpTo(name, id, data) {
			var fullpath = name + id + '/';
			this.$router.push({ path: fullpath });
			this.hospitalDetails = data;
		},
		handleClick(tab, event) {
			// console.log(tab, event);
		},
		//获取仪表板数据
		getStatisticData() {
			var that = this;
			this.$axios
				.get('/apim/get/statistics/')
				.then(function(res) {
					if (res.data.code == '401') {
						that.logout();
					} else {
						that.customer_record_num = res.data.customer_record_num;
						that.equip_record_num = res.data.equip_record_num;
						that.product_record_num = res.data.product_record_num;
						that.company_record_num = res.data.company_record_num;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		}
	}
};
</script>

<style>
.title-text {
	color: #00a6dd;
	font-size: 18px;
	font-weight: 800;
}
</style>
