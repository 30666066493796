<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="month" align="right" placeholder="请选择时间"
							value-format="yyyy-MM" size="mini" />
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区" multiple collapse-tags>
							<el-option v-for="item in areaOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看报表</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<!-- <el-button type="success" @click="exportTableData(searchData,'地区销量按品牌')">导出</el-button> -->
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item label="图表风格">
						<el-select v-model="echartTheme">
							<el-option v-for="theme in themes" :key="theme.value" :label="theme.label"
								:value="theme.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>

			</el-card>
		</el-row>

		<el-row style="margin-top:10px;">
			<el-card>
				<el-row :gutter="5">
					<el-col :sm="8" :xs="24">
						<div class="static-box">
							<el-statistic group-separator="," :precision="0"
								:value="dashboardData.this_month_sales_quantity" title="当月销量">
								<template slot="prefix">
									<i class="el-icon-s-marketing" style="color: skyblue"></i>
								</template>
								<template slot="suffix">
									桶
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="8" :xs="12">
						<div class="static-box">
							<el-statistic title="同比上年">
								<template slot="formatter">
									<i v-if="dashboardData.yoy_sales_quantity_rate[0] == '-'"
										class="el-icon-caret-bottom" style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.yoy_sales_quantity_rate }}
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="8" :xs="12">
						<div class="static-box">
							<el-statistic title="环比上月">
								<template slot="formatter">
									<i v-if="dashboardData.mom_sales_quantity_rate[0] == '-'"
										class="el-icon-caret-bottom" style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.mom_sales_quantity_rate }}
								</template>
							</el-statistic>
						</div>
					</el-col>
				</el-row>

				<el-row :gutter="5" style="margin-top: 10px;">
					<el-col :sm="8" :xs="24">
						<div class="static-box">
							<el-statistic group-separator="," :precision="0"
								:value="dashboardData.this_month_sales_amount" title="当月订货额">
								<template slot="prefix">
									<i class="el-icon-s-marketing" style="color: skyblue"></i>
								</template>
								<template slot="suffix">
									元
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="8" :xs="12">
						<div class="static-box">
							<el-statistic title="同比上年">
								<template slot="formatter">
									<i v-if="dashboardData.yoy_sales_amount_rate[0] == '-'" class="el-icon-caret-bottom"
										style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.yoy_sales_amount_rate }}
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="8" :xs="12">
						<div class="static-box">
							<el-statistic title="环比上月">
								<template slot="formatter">
									<i v-if="dashboardData.mom_sales_amount_rate[0] == '-'" class="el-icon-caret-bottom"
										style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.mom_sales_amount_rate }}
								</template>
							</el-statistic>
						</div>
					</el-col>
				</el-row>

				<el-row :gutter="5" style="margin-top: 10px;">
					<el-col :sm="6" :xs="14">
						<div class="static-box">
							<el-statistic group-separator="," :precision="0"
								:value="dashboardData.yearly_sales_quantity" title="年度累计销量">
								<template slot="prefix">
									<i class="el-icon-s-marketing" style="color: skyblue"></i>
								</template>
								<template slot="suffix">
									桶
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="6" :xs="10">
						<div class="static-box">
							<el-statistic title="销量完成率">
								<template slot="formatter">
									<i v-if="dashboardData.yearly_quantity_percentage[0] == '-'"
										class="el-icon-caret-bottom" style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.yearly_quantity_percentage }}
								</template>
								<template slot="suffix">
									%
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="6" :xs="14">
						<div class="static-box">
							<el-statistic group-separator="," :precision="0" :value="dashboardData.yearly_sales_amount"
								title="年度累计订货额">
								<template slot="prefix">
									<i class="el-icon-s-marketing" style="color: skyblue"></i>
								</template>
								<template slot="suffix">
									元
								</template>
							</el-statistic>
						</div>
					</el-col>
					<el-col :sm="6" :xs="10">
						<div class="static-box">
							<el-statistic title="订货额完成率">
								<template slot="formatter">
									<i v-if="dashboardData.yearly_amount_percentage[0] == '-'"
										class="el-icon-caret-bottom" style="color: green"></i>
									<i v-else class="el-icon-caret-top" style="color: red"></i>
									{{ dashboardData.yearly_amount_percentage }}
								</template>
								<template slot="suffix">
									%
								</template>
							</el-statistic>
						</div>
					</el-col>
				</el-row>

			</el-card>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="barChartActiveName">
						<el-tab-pane label="销量同比/环比" name="bar_quantity">

							<div class="plate-card"><v-chart :options="bar_chart_quantity" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>

						<el-tab-pane label="订货额同比/环比" name="bar_amount">
							<div class="plate-card"><v-chart :options="bar_chart_amount" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="pieChartActiveName">
						<el-tab-pane label="按品牌" name="pie_brand">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_brand" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_brand" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>

						</el-tab-pane>

						<el-tab-pane label="按品类" name="pie_category">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_category" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_category" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

						<el-tab-pane label="按单品" name="pie_product">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_product" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_product" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>


<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				searchData: {
					area: '',
					date_range: ''
				},
				loading: false,
				echartTheme: 'light',
				dashboardData: {
					this_month_sales_quantity: 0,
					this_month_sales_amount: 0.00,
					yoy_sales_quantity_rate: '0',
					yoy_sales_amount_rate: '0',
					mom_sales_quantity_rate: '0',
					mom_sales_amount_rate: '0',
					yearly_sales_amount: 0.00,
					yearly_sales_quantity: 0,
					yearly_amount_percentage: '0',
					yearly_quantity_percentage: '0'
				},
				themes: [{
						value: 'light',
						label: '默认'
					},
					{
						value: 'dark',
						label: '黑暗'
					},
					{
						value: 'shine',
						label: '闪亮'
					},
					{
						value: 'vintage',
						label: '复古'
					},
					{
						value: 'helianthus',
						label: '太阳花'
					},
					{
						value: 'tech-blue',
						label: '科技蓝'
					},
					{
						value: 'london',
						label: '伦敦'
					},
					{
						value: 'roma',
						label: '罗马'
					},
					{
						value: 'sakura',
						label: '樱花粉'
					},
					{
						value: 'fresh-cut',
						label: '鲜果切'
					}
					// 你可以继续添加其他主题
				],
				barChartActiveName: 'bar_quantity',
				pieChartActiveName: 'pie_brand',
				areaOptions: [],
				pie_quantity_brand: {},
				pie_amount_brand: {},
				pie_quantity_category: {},
				pie_amount_category: {},
				pie_quantity_product: {},
				pie_amount_product: {},
				bar_chart_quantity: {
					title: {
						text: '销量同比/环比(桶)',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							params.forEach((item) => {
								const formattedValue = item.value.toLocaleString();
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';
								tooltipText +=
									`${item.marker}${item.seriesName}：${formattedValue}（桶）（${percentage}%）<br/>`;
							});
							tooltipText +=
								`合计：${totalValue.toLocaleString()}（桶）`;
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '进口鲁沃夫',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: [],
					}, {
						name: '国产鲁沃夫',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: []
					}, ]
				},
				bar_chart_amount: {
					title: {
						text: '地区订货额同比/环比（元）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}<br/>`;
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							params.forEach((item) => {
								const formattedValue = item.value.toLocaleString();
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';
								tooltipText +=
									`${item.marker}${item.seriesName}：${formattedValue}（元）（${percentage}%）<br/>`;
							});
							tooltipText +=
								`合计：${totalValue.toLocaleString()}（元）`;
							return tooltipText;
						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
						name: '进口鲁沃夫',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: []
					}, {
						name: '国产鲁沃夫',
						type: 'bar',
						stack: 'Ad',
						emphasis: {
							focus: 'series'
						},
						data: []
					}, ]
				},
				pie_sale_quantity: {
					title: {
						text: '地区销量',
						left: 'center',
						top: 5
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'rose']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 桶 ({d}%)'
					},
					series: [{
						name: '销量',
						type: 'pie',
						center: ['50%', '60%'],
						selectedMode: 'single',
						radius: [0, '60%'],
						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							alignTo: 'labelLine',
							margin: 20,
							rich: {
								b: {
									color: '#2f2f2f',
									fontSize: 14,
									lineHeight: 28,
									padding: [4, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						data: []
					}],
					animationDuration: 2000
				},
				pie_sale_amount: {
					title: {
						text: '地区订货额',
						left: 'center',
						top: 5
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 元 ({d}%)'
					},
					series: [{
						name: '订货额',
						type: 'pie',
						radius: ['30%', '60%'],
						center: ['50%', '60%'],
						data: [],
						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							alignTo: 'labelLine',
							margin: 20,
							rich: {
								b: {
									color: '#2f2f2f',
									fontSize: 14,
									lineHeight: 28,
									padding: [4, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						itemStyle: {
							// shadowBlur: 10,
							// shadowColor: 'rgba(0, 0, 0, 0.1)',
							borderRadius: 20,
							borderColor: '#fff',
							borderWidth: 3
						},
						animationType: 'scale',
						animationEasing: 'elasticOut',
						animationDelay: function(idx) {
							return Math.random() * 200;
						}
					}],
					animationDuration: 2000
				}
			};
		},
		mounted() {
			// 获取数据
			this.getAreaOptions();
		},
		created() {
			// 深拷贝数据以取消双向绑定
			this.pie_quantity_brand = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_quantity_category = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_quantity_product = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_amount_brand = JSON.parse(JSON.stringify(this.pie_sale_amount));
			this.pie_amount_category = JSON.parse(JSON.stringify(this.pie_sale_amount));
			this.pie_amount_product = JSON.parse(JSON.stringify(this.pie_sale_amount));
		},
		methods: {
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
			},
			//提交搜索
			submitFormSearchData() {
				console.log(this.searchData)
				this.getTableAndChartData(this.searchData);
			},
			// 获取地区列表
			getAreaOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_area_list/')
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.areaOptions = response.data;
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			//获取表格及图表数据
			getTableAndChartData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}
				this.$axios({
						url: '/apis/get_monthly_area_sales_summary/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						console.log(res)
						that.loading = false;
						if (res.data.code == '401') {
							that.logout();
						} else {

							let date_range = res.data.date_range;
							let area = res.data.area;

							that.dashboardData = res.data.dashboardData;

							that.bar_chart_quantity.xAxis[0].data = res.data.xAxis_data;
							that.bar_chart_quantity.series[0].data = res.data.bar_chart_quantity_series_0_data;
							that.bar_chart_quantity.series[1].data = res.data.bar_chart_quantity_series_1_data;
							that.bar_chart_quantity.title.text = date_range + area + '地区销量同比/环比'

							that.bar_chart_amount.xAxis[0].data = res.data.xAxis_data;
							that.bar_chart_amount.series[0].data = res.data.bar_chart_amount_series_0_data;
							that.bar_chart_amount.series[1].data = res.data.bar_chart_amount_series_1_data;
							that.bar_chart_amount.title.text = date_range + area + '地区订货额同比/环比'

							that.pie_quantity_brand.title.text = date_range + area + '品牌销量（桶）'
							that.pie_quantity_brand.series[0].data = res.data.pie_chart_quantity_brand_series;

							that.pie_amount_brand.title.text = date_range + area + '品牌订货额（元）'
							that.pie_amount_brand.series[0].data = res.data.pie_chart_amount_brand_series;

							that.pie_quantity_category.title.text = date_range + area + '品类销量（桶）'
							that.pie_quantity_category.series[0].data = res.data.pie_chart_quantity_category_series;

							that.pie_amount_category.title.text = date_range + area + '品类订货额（元）'
							that.pie_amount_category.series[0].data = res.data.pie_chart_amount_category_series;

							that.pie_quantity_product.title.text = date_range + area + '单品销量前10（桶）'
							that.pie_quantity_product.series[0].data = res.data.pie_chart_quantity_product_series;

							that.pie_amount_product.title.text = date_range + area + '单品订货额前10（元）'
							that.pie_amount_product.series[0].data = res.data.pie_chart_amount_product_series;

						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},

			//下载数据
			exportTableData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}

				let fileName = filename + Date.now();

				this.$axios({
						url: '/apis/export_monthly_area_sales/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 500px;
	}

	.margin-bottom-sm {
		margin-bottom: 10px;
	}

	.static-box {
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
		padding: 5px;
	}
</style>