<template>
	<div>
		<el-container>
			<el-header :key="$route.fullPath" :height="'70px'" style="text-align: right;">
				<el-row style="padding: 10 20px; text-align: left;">
					<el-col :sm="8" :xs="20">
						<img class="hidden-sm-and-down" style=" height: 50px; padding: 5px; vertical-align:middle;"
							:src="require('./assets/logo.svg')" />
						<span style="font-size: 15px; margin: 0 15px; font-weight: 600;">鲁沃夫市场信息管理平台</span>
					</el-col>
					<el-col :sm="12" :xs="12" class="hidden-sm-and-down" v-if="$store.state.Authorization">
						<el-menu :default-active="$route.fullPath" mode="horizontal" router background-color="#fafafa">
							<el-submenu index="1">
								<template slot="title">
									<i class="el-icon-s-management" />
									<span slot="title">市场日志</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/myrecord">
										<i class="el-icon-s-claim" />
										<span slot="title">写日志</span>
									</el-menu-item>
									<el-menu-item index="/recordmanage">
										<i class="el-icon-s-order" />
										<span slot="title">看日志</span>
									</el-menu-item>
									<el-menu-item index="/recorddashboard">
										<i class="el-icon-s-custom" />
										<span slot="title">个人统计</span>
									</el-menu-item>
									<el-menu-item index="/recorddashboardmuti" v-if="$store.state.userPrivilege > 1.5">
										<i class="el-icon-s-data" />
										<span slot="title">多人统计</span>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>

							<el-submenu index="2">
								<template slot="title">
									<i class="el-icon-s-platform" />
									<span slot="title">市场数据</span>
								</template>
								<el-submenu index="2-1">
									<template slot="title">医院管理</template>
									<el-menu-item index="/customer">
										<i class="el-icon-s-custom" />
										<span slot="title">医院清单</span>
									</el-menu-item>
									<el-menu-item index="/product" v-if="$store.state.userPrivilege > 3">
										<i class="el-icon-s-goods" />
										<span slot="title">产品清单</span>
									</el-menu-item>
									</el-menu-item>
								</el-submenu>
								<el-submenu index="2-2">
									<template slot="title">医院数据（新版）</template>
									<el-menu-item index="/hospital_panel/">
										<i class="el-icon-s-marketing" />
										<span slot="title">产品/设备/专家/供货</span>
									</el-menu-item>
									<el-menu-item index="/hospital_query_panel">
										<i class="el-icon-question" />
										<span slot="title">查询及导出</span>
									</el-menu-item>
								</el-submenu>

								<el-submenu index="2-3" v-if="$store.state.userPrivilege > 1.5 ">
									<template slot="title">旧版数据</template>
									<el-menu-item index="/cmdashboard/0/">
										<i class="el-icon-s-custom" />
										<span slot="title">设备/人员/产品/服务</span>
									</el-menu-item>
									<el-menu-item index="/prodashboard/0/">
										<i class="el-icon-s-goods" />
										<span slot="title">价格用量</span>
									</el-menu-item>
									<el-menu-item index="/statistics">
										<i class="el-icon-s-marketing" />
										<span slot="title">统计报表</span>
									</el-menu-item>
									<el-menu-item index="/intequery">
										<i class="el-icon-question" />
										<span slot="title">查询及导出</span>
									</el-menu-item>
								</el-submenu>

							</el-submenu>
							<!-- 销售数据 -->
							<el-submenu index="3">
								<template slot="title">
									<i class="el-icon-s-finance" />
									<span slot="title">销售数据</span>
								</template>
								<el-submenu index="3-1" v-if="$store.state.userPrivilege > 2">
									<template slot="title">数据管理</template>
									<el-menu-item index="/orderdata">
										<i class="el-icon-s-data" />
										<span slot="title">订货记录</span>
									</el-menu-item>
									<el-menu-item index="/yearly_sale_target">
										<i class="el-icon-s-data" />
										<span slot="title">年度销售目标</span>
									</el-menu-item>
									<el-menu-item index="/product_manage">
										<i class="el-icon-s-data" />
										<span slot="title">产品规格</span>
									</el-menu-item>
									<el-menu-item index="/distributor_region">
										<i class="el-icon-s-data" />
										<span slot="title">经销区域</span>
									</el-menu-item>
								</el-submenu>

								<el-submenu index="3-2">
									<template slot="title">销售进度</template>
									<el-menu-item index="/monthly_area_summary">
										<i class="el-icon-s-marketing" />
										<span slot="title">月度</span>
									</el-menu-item>
									<el-menu-item index="/yearly_sales_progress">
										<i class="el-icon-s-marketing" />
										<span slot="title">年度</span>
									</el-menu-item>
								</el-submenu>

								<el-submenu index="3-3">
									<template slot="title">月报表</template>
									<el-menu-item index="/monthly_area_brand">
										<i class="el-icon-s-grid" />
										<span slot="title">地区按品牌</span>
									</el-menu-item>
									<el-menu-item index="/monthly_area_product">
										<i class="el-icon-s-grid" />
										<span slot="title">地区按产品</span>
									</el-menu-item>
									<el-menu-item index="/monthly_product_area">
										<i class="el-icon-s-goods" />
										<span slot="title">产品按地区</span>
									</el-menu-item>
									<el-menu-item index="/monthly_area_compare">
										<i class="el-icon-s-grid" />
										<span slot="title">地区同比</span>
									</el-menu-item>
									<el-menu-item index="/monthly_product_compare">
										<i class="el-icon-s-goods" />
										<span slot="title">产品同比</span>
									</el-menu-item>
								</el-submenu>

								<el-submenu index="3-4">
									<template slot="title">年报表</template>
									<el-menu-item index="/yearly_area_brand">
										<i class="el-icon-s-grid" />
										<span slot="title">地区按品牌</span>
									</el-menu-item>
									<el-menu-item index="/yearly_area_product">
										<i class="el-icon-s-goods" />
										<span slot="title">产品按品类/单品</span>
									</el-menu-item>
									<el-menu-item index="/yearly_area_compare">
										<i class="el-icon-s-grid" />
										<span slot="title">地区同比</span>
									</el-menu-item>
									<el-menu-item index="/yearly_product_compare">
										<i class="el-icon-s-goods" />
										<span slot="title">产品同比</span>
									</el-menu-item>
								</el-submenu>

							</el-submenu>

							<el-submenu index="4" v-if="$store.state.userPrivilege > 3">
								<template slot="title">
									<i class="el-icon-s-check" />
									<span slot="title">组织架构</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/usermanage">
										<i class="el-icon-user-solid" />
										<span slot="title">用户管理</span>
									</el-menu-item>
									<el-menu-item index="/deptmanage">
										<i class="el-icon-s-cooperation" />
										<span slot="title">大区管理</span>
									</el-menu-item>
								</el-menu-item-group>
							</el-submenu>
						</el-menu>
					</el-col>
					<el-col :sm="4" :xs="12" class="hidden-sm-and-down" v-if="$store.state.Authorization">
						<el-avatar fit="fill" :src="require('./assets/avatar.svg')" style="vertical-align:middle;" />
						<el-dropdown style="vertical-align:middle; margin-left: 10px;" trigger="click"
							@command="handleCommand">
							<el-button type="warning" size="mini">
								欢迎: {{ $store.state.personName }}
								<i class="el-icon-arrow-down el-icon--right" />
							</el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="logout">注销登录</el-dropdown-item>
								<el-dropdown-item command="changePwd">修改密码</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</el-col>
					<el-col :xs="4" class="hidden-sm-and-up" v-if="$store.state.Authorization">
						<el-button type="danger" size="mini" style="vertical-align:middle;  margin-left: 10px;"
							@click="drawer = true">菜单</el-button>
					</el-col>
				</el-row>
			</el-header>

			<el-main style="padding: 15px;">
				<el-row>
					<el-col :span="24">
						<transition :key="$route.fullPath" name="el-fade-in-linear" mode="out-in"><router-view
								v-if="isRouterAlive" /></transition>
					</el-col>
				</el-row>
			</el-main>

			<!-- 修改密码弹窗 -->
			<el-dialog title="修改密码" :visible.sync="formPwdChangeVisible" :close-on-click-modal="false"
				:close-on-press-escape="false" :show-close="false">
				<el-form ref="formChangePwd" :model="formChangePwdData" :rules="rules" :label-width="formLabelWidth">
					<el-form-item label="新密码" prop="newPwd"><el-input v-model="formChangePwdData.newPwd"
							autocomplete="off" show-password /></el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="resetForm('formChangePwd')">取 消</el-button>
					<el-button type="primary" @click="submitPwdChangeForm('formChangePwd')">确 定</el-button>
				</div>
			</el-dialog>
			<el-footer>
				©Copyright 2024 鲁沃夫（杭州）
				<span class="hidden-sm-and-down">
					生物工程有限公司
					<el-link type="primary" :underline="false" href="http://beian.miit.gov.cn/"
						target="_blank">浙ICP备08011139号-1</el-link>
				</span>

			</el-footer>
		</el-container>
		<el-drawer title="弹窗" :visible.sync="drawer" :with-header="false" :size="'200'" :direction="'ttb'">
			<el-menu :key="$route.fullPath" :default-active="$route.fullPath" mode="vertical" background-color="#545c64"
				text-color="#fff" active-text-color="#ffd04b" router @select="hideDrawer">
				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-s-management" />
						<span slot="title">市场日志</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/myrecord">
							<i class="el-icon-s-claim" />
							<span slot="title">写日志</span>
						</el-menu-item>
						<el-menu-item index="/recordmanage">
							<i class="el-icon-s-order" />
							<span slot="title">看日志</span>
						</el-menu-item>
						<el-menu-item index="/recorddashboard">
							<i class="el-icon-s-custom" />
							<span slot="title">个人统计</span>
						</el-menu-item>
						<el-menu-item index="/recorddashboardmuti" v-if="$store.state.userPrivilege > 1.5">
							<i class="el-icon-s-data" />
							<span slot="title">多人统计</span>
						</el-menu-item>
					</el-menu-item-group>
				</el-submenu>

				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-s-platform" />
						<span slot="title">市场数据</span>
					</template>
					<el-submenu index="2-1">
						<template slot="title">医院管理</template>
						<el-menu-item index="/customer">
							<i class="el-icon-s-custom" />
							<span slot="title">医院清单</span>
						</el-menu-item>
						<el-menu-item index="/product" v-if="$store.state.userPrivilege > 3">
							<i class="el-icon-s-goods" />
							<span slot="title">产品清单</span>
						</el-menu-item>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="2-2">
						<template slot="title">医院数据（新版）</template>
						<el-menu-item index="/hospital_panel/0/">
							<i class="el-icon-s-marketing" />
							<span slot="title">产品/设备/专家/供货</span>
						</el-menu-item>
						<el-menu-item index="/hospital_query_panel">
							<i class="el-icon-question" />
							<span slot="title">查询及导出</span>
						</el-menu-item>
					</el-submenu>

					<el-submenu index="2-3" v-if="$store.state.userPrivilege > 1.5 ">
						<template slot="title">旧版数据</template>
						<el-menu-item index="/cmdashboard/0/">
							<i class="el-icon-s-custom" />
							<span slot="title">设备/人员/产品/服务</span>
						</el-menu-item>
						<el-menu-item index="/prodashboard/0/">
							<i class="el-icon-s-goods" />
							<span slot="title">价格用量</span>
						</el-menu-item>
						<el-menu-item index="/statistics">
							<i class="el-icon-s-marketing" />
							<span slot="title">统计报表</span>
						</el-menu-item>
						<el-menu-item index="/intequery">
							<i class="el-icon-question" />
							<span slot="title">查询及导出</span>
						</el-menu-item>
					</el-submenu>

				</el-submenu>
				<!-- 销售数据 -->
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-s-finance" />
						<span slot="title">销售数据</span>
					</template>
					<el-submenu index="3-1" v-if="$store.state.userPrivilege > 2">
						<template slot="title">数据管理</template>
						<el-menu-item index="/orderdata">
							<i class="el-icon-s-data" />
							<span slot="title">订货记录</span>
						</el-menu-item>
						<el-menu-item index="/yearly_sale_target">
							<i class="el-icon-s-data" />
							<span slot="title">年度销售目标</span>
						</el-menu-item>
						<el-menu-item index="/product_manage">
							<i class="el-icon-s-data" />
							<span slot="title">产品规格</span>
						</el-menu-item>
						<el-menu-item index="/distributor_region">
							<i class="el-icon-s-data" />
							<span slot="title">经销区域</span>
						</el-menu-item>
					</el-submenu>

					<el-submenu index="3-2">
						<template slot="title">销售进度</template>
						<el-menu-item index="/monthly_area_summary">
							<i class="el-icon-s-marketing" />
							<span slot="title">月度</span>
						</el-menu-item>
						<el-menu-item index="/yearly_sales_progress">
							<i class="el-icon-s-marketing" />
							<span slot="title">年度</span>
						</el-menu-item>
					</el-submenu>

					<el-submenu index="3-3">
						<template slot="title">月报表</template>
						<el-menu-item index="/monthly_area_brand">
							<i class="el-icon-s-grid" />
							<span slot="title">地区按品牌</span>
						</el-menu-item>
						<el-menu-item index="/monthly_area_product">
							<i class="el-icon-s-grid" />
							<span slot="title">地区按产品</span>
						</el-menu-item>
						<el-menu-item index="/monthly_product_area">
							<i class="el-icon-s-goods" />
							<span slot="title">产品按地区</span>
						</el-menu-item>
						<el-menu-item index="/monthly_area_compare">
							<i class="el-icon-s-grid" />
							<span slot="title">地区同比</span>
						</el-menu-item>
						<el-menu-item index="/monthly_product_compare">
							<i class="el-icon-s-goods" />
							<span slot="title">产品同比</span>
						</el-menu-item>
					</el-submenu>

					<el-submenu index="3-4">
						<template slot="title">年报表</template>
						<el-menu-item index="/yearly_area_brand">
							<i class="el-icon-s-grid" />
							<span slot="title">地区按品牌</span>
						</el-menu-item>
						<el-menu-item index="/yearly_area_product">
							<i class="el-icon-s-goods" />
							<span slot="title">产品按品类/单品</span>
						</el-menu-item>
						<el-menu-item index="/yearly_area_compare">
							<i class="el-icon-s-grid" />
							<span slot="title">地区同比</span>
						</el-menu-item>
						<el-menu-item index="/yearly_product_compare">
							<i class="el-icon-s-goods" />
							<span slot="title">产品同比</span>
						</el-menu-item>
					</el-submenu>

				</el-submenu>

				<el-submenu index="4" v-if="$store.state.userPrivilege > 3">
					<template slot="title">
						<i class="el-icon-s-check" />
						<span slot="title">组织架构</span>
					</template>
					<el-menu-item-group>
						<el-menu-item index="/usermanage">
							<i class="el-icon-user-solid" />
							<span slot="title">用户管理</span>
						</el-menu-item>
						<el-menu-item index="/deptmanage">
							<i class="el-icon-s-cooperation" />
							<span slot="title">大区管理</span>
						</el-menu-item>
					</el-menu-item-group>
				</el-submenu>


				<el-menu-item v-if="$store.state.Authorization" index="/logout/" @click="logout()">
					<i class="el-icon-s-goods" />
					<span slot="title">注销</span>
				</el-menu-item>
			</el-menu>
		</el-drawer>
		<el-backtop />
	</div>
</template>

<script>
	import {
		mapMutations
	} from 'vuex';
	export default {
		provide() {
			return {
				reload: this.reload,
				logout: this.logout
			};
		},
		data() {
			return {
				isCollapse: false,
				isRouterAlive: true,
				drawer: false,
				formPwdChangeVisible: false,
				formChangePwdData: {
					newPwd: ''
				},
				formLabelWidth: '120px',
				rules: {
					newPwd: [{
							required: true,
							message: '请输入新密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 18,
							message: '长度在 6 到 18 个字符',
							trigger: 'blur'
						}
					]
				}
			};
		},
		methods: {
			...mapMutations(['changeLogin']),
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			reload() {
				this.isRouterAlive = false;
				this.$nextTick(function() {
					this.isRouterAlive = true;
				});
			},
			hideDrawer(key, keyPath) {
				this.drawer = false;
			},
			//注销账号
			logout() {
				let that = this;
				this.$axios({
						url: '/api/user/logout/',
						method: 'post'
					})
					.then(function(res) {
						if (res.data.code === '200' || res.data.code === '401') {
							localStorage.removeItem('Authorization');
							that.changeLogin({
								Authorization: '',
								personName: '',
								userPrivilege: ''
							});
							that.$router.push('/login');
							that.$message({
								message: '登出成功',
								type: 'success'
							});
						} else {
							that.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			handleCommand(command) {
				if (command === 'logout') {
					this.logout();
				} else if (command === 'changePwd') {
					this.formPwdChangeVisible = true;
				}
			},
			//重置修改密码表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
				this.formPwdChangeVisible = false;
			},
			//提交修改密码表单
			submitPwdChangeForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formChangePwdData;
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						this.$axios({
								url: '/api/user/changepwd/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								// console.log(res.data);
								if (res.data.code == '200') {
									that.$message({
										message: res.data.message,
										type: 'success'
									});
									that.resetForm(formName);
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
									that.resetForm(formName);
									that.logout();
								}
							})
							.catch(function(error) {
								that.resetForm(formName);
								console.log(error);
							});
					} else {
						return false;
					}
				});
			}
		}
	};
</script>

<style>
	.el-header {
		/* border-bottom: 4px solid #00a6dd; */
		color: #333;
		text-align: center;
		background-color: #fafafa;
		line-height: 60px;
	}

	.el-footer {
		color: #212121;
		text-align: center;
		background-color: #fff;
		line-height: 50px;
		font-size: 14px;
		border-top: 4px solid #00a6dd;
	}

	.el-main {
		background-color: #fff;
		color: #000;
		text-align: left;
	}


	body {
		margin: 0;
		padding: 0;
	}

	a,
	a:visited {
		text-decoration: none;
		color: #fff;
	}

	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #3e444d;
		font-weight: 600;
	}

	.demo-table-expand .el-form-item {
		margin: 0;
		padding: 0;
		width: 48%;
	}

	.paginator {
		margin-top: 20px;
		text-align: center;
	}

	.active {
		color: #E6A23C;
		font-weight: 800;
		padding: 12px 0;
	}
</style>