import Vue from 'vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/polar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title';
import 'echarts/lib/component/legendScroll';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/markPoint';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/markArea';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/calendar';
import 'echarts/theme/shine';
import 'echarts/theme/vintage';
import 'echarts/theme/helianthus';
import 'echarts/theme/tech-blue';
import 'echarts/theme/london';
import 'echarts/theme/roma';
import 'echarts/theme/sakura';
import 'echarts/theme/fresh-cut';

Vue.component('v-chart', ECharts)