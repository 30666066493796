<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<!-- <el-card> -->
			<el-row :gutter="20">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success" @click="addData()">新增市场人员</el-button>
					<!-- <el-button style="margin: 5px;" size="mini" @click="exportExcel()">导出</el-button> -->
				</el-col>
			</el-row>
			<el-table :data="tableData" style="width: 100%; " stripe :default-sort="{ prop: 'date', order: 'descending' }">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" class="demo-table-expand">
							<el-row>
								<el-col :xs="24" :sm="12">
									<el-form-item label="姓名">
										<span>{{ props.row.marketer_name }}</span>
									</el-form-item>
									<el-form-item label="部门">
										<span>{{ props.row.marketer_dept }}</span>
									</el-form-item>
									<el-form-item label="职务">
										<span>{{ props.row.marketer_duty }}</span>
									</el-form-item>
									<el-form-item label="负责地区">
										<span>{{ props.row.marketer_incharge_area }}</span>
									</el-form-item>
								</el-col>
								<el-col :xs="24" :sm="12">
									<el-form-item label="工作内容">
										<span>{{ props.row.marketer_incharge_affair }}</span>
									</el-form-item>
									<el-form-item label="工作经历">
										<span>{{ props.row.marketer_work_exp }}</span>
									</el-form-item>
									<el-form-item label="备注">
										<span>{{ props.row.marketer_remark }}</span>
									</el-form-item>
									<el-form-item label="上报人">
										<span>{{ props.row.marketer_user__person_name }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</template>
				</el-table-column>

				<el-table-column label="姓名" prop="marketer_name" show-overflow-tooltip />
				<el-table-column label="部门" prop="marketer_dept" />
				<el-table-column label="职务" prop="marketer_duty" />
				<el-table-column label="负责地区" prop="marketer_incharge_area" show-overflow-tooltip />
				<el-table-column label="上报人" prop="marketer_user__person_name" show-overflow-tooltip />
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" @click="updateData(scope.$index, scope.row)">编 辑</el-button>
							<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增市场人员"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="姓名" prop="marketer_name"><el-input v-model="formAddData.marketer_name" clearable /></el-form-item>
				<el-form-item label="部门" prop="marketer_dept"><el-input v-model="formAddData.marketer_dept" clearable /></el-form-item>
				<el-form-item label="职务" prop="marketer_duty"><el-input v-model="formAddData.marketer_duty" clearable /></el-form-item>
				<el-form-item label="负责地区" prop="marketer_incharge_area"><el-input v-model="formAddData.marketer_incharge_area" clearable /></el-form-item>
				<el-form-item label="负责工作" prop="marketer_incharge_affair">
					<el-input v-model="formAddData.marketer_incharge_affair" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="工作经历" prop="marketer_work_exp">
					<el-input v-model="formAddData.marketer_work_exp" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="备注" prop="marketer_remark">
					<el-input v-model="formAddData.marketer_remark" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改市场人员资料"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="姓名" prop="marketer_name"><el-input v-model="formUpdateData.marketer_name" clearable /></el-form-item>
				<el-form-item label="部门" prop="marketer_dept"><el-input v-model="formUpdateData.marketer_dept" clearable /></el-form-item>
				<el-form-item label="职务" prop="marketer_duty"><el-input v-model="formUpdateData.marketer_duty" clearable /></el-form-item>
				<el-form-item label="负责地区" prop="marketer_incharge_area"><el-input v-model="formUpdateData.marketer_incharge_area" clearable /></el-form-item>
				<el-form-item label="负责工作" prop="marketer_incharge_affair">
					<el-input v-model="formUpdateData.marketer_incharge_affair" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="工作经历" prop="marketer_work_exp">
					<el-input v-model="formUpdateData.marketer_work_exp" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="备注" prop="marketer_remark">
					<el-input v-model="formUpdateData.marketer_remark" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateData')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { exportToExcel } from '@/api/utils.js';
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			fileList: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				marketer_user__person_name: '',
				marketer_name: '',
				marketer_dept: '',
				marketer_duty: '',
				marketer_incharge_area: '',
				marketer_incharge_affair: '',
				marketer_work_exp: '',
				marketer_remark: ''
			},
			formUpdateData: {
				pk: '',
				marketer_user__person_name: '',
				marketer_name: '',
				marketer_dept: '',
				marketer_duty: '',
				marketer_incharge_area: '',
				marketer_incharge_affair: '',
				marketer_work_exp: '',
				marketer_remark: ''
			},
			//新增和更新表单的验证规则
			rules: {
				marketer_name: [{ required: true, message: '请填写人员姓名', trigger: 'blur' }],
				marketer_dept: [{ required: true, message: '请填写部门信息', trigger: 'blur' }],
				marketer_duty: [{ required: true, message: '请填写职务信息', trigger: 'blur' }]
			},
			formLabelWidth: '150px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/marketer/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						ga_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
			this.fileList = [];
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
						// console.log(formData[key])
					}
					fd.append('ga_pk', that.$route.params.id);
					this.$axios({
						url: '/apim/add/marketer/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							// console.log(res.data);
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.resetForm('formAddData');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增或更新数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.fileList = [];
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/marketer/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/marketer/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.getData(that.keywords, that.currentPage, that.pageSize);
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//过滤器-产品分类：医用和民用
		filterTag(value, row) {
			return row.type === value;
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//导出excel
		exportExcel() {
			exportToExcel('distributor', '经销商', this);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
