 <template>
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card>
					<el-col :xs="24" :sm="8">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" clearable style="margin:5px" maxlength="30" @clear="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="8" style="margin-bottom: 20px;">
						<el-button style="margin: 5px;" size="mini" type="success" @click.prevent="addData()">新增同业公司</el-button>
					</el-col>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe>
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-form label-position="left" class="demo-table-expand">
								<el-row>
									<el-col :xs="24" :sm="12">
										<el-form-item label="公司名称">
											<span>{{ props.row.ga_name }}</span>
										</el-form-item>
										<el-form-item label="注册地">
											<span>{{ props.row.ga_area }}</span>
										</el-form-item>
										<el-form-item label="公司类型">
											<span>{{ props.row.ga_type }}</span>
										</el-form-item>
										<el-form-item label="旗下品牌">
											<span>
												{{
													String(props.row.ga_brand_name)
														.split(',')
														.join(', ')
												}}
											</span>
										</el-form-item>
									</el-col>
									<el-col :xs="24" :sm="12">
										<el-form-item label="组织架构">
											<span>{{ props.row.ga_organization }}</span>
										</el-form-item>
										<el-form-item label="公司介绍">
											<span>{{ props.row.ga_remark }}</span>
										</el-form-item>
									</el-col>
								</el-row>
							</el-form>
						</template>
					</el-table-column>
					<el-table-column label="公司" prop="ga_name" />
					<el-table-column label="注册地" prop="ga_area" />
					<el-table-column label="公司类型" prop="ga_type"></el-table-column>
					<el-table-column label="人员及市场信息">
						<template slot-scope="scope">
							<el-button type="text" @click="jumpTo('/gadashboard/', scope.row.pk)">查看</el-button>
						</template>
					</el-table-column>
					<el-table-column width="150" label="操作" align="center" class="hidden-sm-and-down" v-if="$store.state.userPrivilege > 2">
						<template slot-scope="scope">
							<el-button-group>
								<el-button size="mini" @click="updateData(scope.$index, scope.row)">修 改</el-button>
								<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="total, sizes, prev, pager, next"
				:total="totalItems"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="prev, pager, next"
				:total="totalItems"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				prev-text="上一页"
				next-text="下一页"
				:pager-count="5"
			/>
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增公司"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="公司名称" prop="ga_name"><el-input v-model="formAddData.ga_name" /></el-form-item>
				<el-form-item label="注册地" prop="ga_area">
					<el-select v-model="formAddData.ga_area" filterable placeholder="请选择" allow-create default-first-option>
						<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="公司类型" prop="ga_type"><el-input v-model="formAddData.ga_type" /></el-form-item>
				<el-form-item label="旗下品牌" prop="ga_brand">
					<el-select v-model="formAddData.ga_brand" multiple placeholder="请选择" collapse-tags>
						<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="组织架构" prop="ga_organization"><el-input type="textarea" autosize v-model="formAddData.ga_organization" /></el-form-item>
				<el-form-item label="公司介绍" prop="ga_remark"><el-input type="textarea" autosize v-model="formAddData.ga_remark" /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改公司信息"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="公司名称" prop="ga_name"><el-input v-model="formUpdateData.ga_name" /></el-form-item>
				<el-form-item label="省份/地区" prop="ga_area"><el-input v-model="formUpdateData.ga_area" /></el-form-item>
				<el-form-item label="公司类型" prop="ga_type"><el-input v-model="formUpdateData.ga_type" /></el-form-item>
				<el-form-item label="旗下品牌" prop="ga_brand">
					<el-select v-model="formUpdateData.ga_brand" multiple placeholder="请选择">
						<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="组织架构" prop="ga_organization"><el-input type="textarea" autosize v-model="formUpdateData.ga_organization" /></el-form-item>
				<el-form-item label="公司介绍" prop="ga_remark"><el-input type="textarea" autosize v-model="formUpdateData.ga_remark" /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formUpdateDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				ga_name: '',
				ga_area: '',
				ga_type: '',
				ga_brand: [],
				ga_organization: '',
				ga_remark: ''
			},
			formUpdateData: {
				pk: '',
				ga_name: '',
				ga_area: '',
				ga_type: '',
				ga_brand: [],
				ga_organization: '',
				ga_remark: ''
			},
			formNewBrand: {
				brand_name: ''
			},
			//新增和更新表单的验证规则
			rules: {
				ga_name: [{ required: true, message: '请输入公司名称', trigger: 'blur' }, { max: 40, message: '长度不超过40个字符', trigger: 'blur' }],
				ga_area: [{ required: true, message: '请输入省份或地区', trigger: 'blur' }]
			},
			formLabelWidth: '100px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false,
			brandOptions: [],
			visible: false,
			provinceOptions: [
				{
					value: '北京',
					label: '北京'
				},
				{
					value: '天津',
					label: '天津'
				},
				{
					value: '上海',
					label: '上海'
				},
				{
					value: '重庆',
					label: '重庆'
				},
				{
					value: '河北',
					label: '河北'
				},
				{
					value: '山西',
					label: '山西'
				},
				{
					value: '辽宁',
					label: '辽宁'
				},
				{
					value: '吉林',
					label: '吉林'
				},
				{
					value: '黑龙江',
					label: '黑龙江'
				},
				{
					value: '江苏',
					label: '江苏'
				},
				{
					value: '浙江',
					label: '浙江'
				},
				{
					value: '安徽',
					label: '安徽'
				},
				{
					value: '福建',
					label: '福建'
				},
				{
					value: '江西',
					label: '江西'
				},
				{
					value: '山东',
					label: '山东'
				},
				{
					value: '河南',
					label: '河南'
				},
				{
					value: '湖北',
					label: '湖北'
				},
				{
					value: '湖南',
					label: '湖南'
				},
				{
					value: '广东',
					label: '广东'
				},
				{
					value: '甘肃',
					label: '甘肃'
				},
				{
					value: '四川',
					label: '四川'
				},
				{
					value: '贵州',
					label: '贵州'
				},
				{
					value: '海南',
					label: '海南'
				},
				{
					value: '云南',
					label: '云南'
				},
				{
					value: '青海',
					label: '青海'
				},
				{
					value: '陕西',
					label: '陕西'
				},
				{
					value: '广西',
					label: '广西'
				},
				{
					value: '西藏',
					label: '西藏'
				},
				{
					value: '宁夏',
					label: '宁夏'
				},
				{
					value: '新疆',
					label: '新疆'
				},
				{
					value: '内蒙',
					label: '内蒙'
				},
				{
					value: '澳门',
					label: '澳门'
				},
				{
					value: '香港',
					label: '香港'
				},
				{
					value: '台湾',
					label: '台湾'
				}
			]
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/generalagent/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						// console.log(response.data.data);
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					console.log(this.formAddData);
					this.$axios({
						url: '/apim/add/generalagent/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.reload();
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
			// console.log(this.formUpdateData)
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/generalagent/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/generalagent/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.reload();
					} else {
						that.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		// 获取品牌列表
		getBrandOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/brandoptions/')
				.then(function(response) {
					that.brandOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//路由跳转
		jumpTo(name, id) {
			var fullpath = name + id + '/';
			this.$router.push({ path: fullpath });
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		this.getBrandOptions();
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
