import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
		personName: localStorage.getItem('personName') ? localStorage.getItem('personName') : '',
		userPrivilege:localStorage.getItem('userPrivilege') ? localStorage.getItem('userPrivilege') : '',
		userID:localStorage.getItem('userID') ? localStorage.getItem('userID') : '',
	},
	mutations: {
		changeLogin(state, user) {
			state.Authorization = user.Authorization;
			state.personName = user.personName;
			state.userPrivilege = user.userPrivilege
			localStorage.setItem('Authorization', user.Authorization);
			localStorage.setItem('personName', user.personName);
			localStorage.setItem('userPrivilege', user.userPrivilege);
			localStorage.setItem('userID', user.userID);	
		}
	},
	actions: {},
	modules: {}
})
