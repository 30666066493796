<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" class="demo-table-expand">
							<el-row>
								<el-col :xs="24" :sm="12">
									<el-form-item label="医院">
										<span>{{ props.row.equip_hospital__hospital_name }}</span>
									</el-form-item>
									<el-form-item label="使用科室">
										<span>{{ props.row.equip_dept }}</span>
									</el-form-item>
									<el-form-item label="设备名称">
										<span>{{ props.row.equip_name }}</span>
									</el-form-item>
									
									<el-form-item label="设备数量">
										<span>{{ props.row.equip_quantity }}</span>
									</el-form-item>
									<el-form-item label="设备品牌">
										<span>{{ props.row.equip_brand }}</span>
									</el-form-item>
									<el-form-item label="设备类型">
										<span>{{ props.row.equip_type }}</span>
									</el-form-item>
									<el-form-item label="设备型号">
										<span>{{ props.row.equip_model }}</span>
									</el-form-item>
								</el-col>
								<el-col :xs="24" :sm="12">
									<el-form-item label="出厂日期">
										<span>{{ props.row.equip_DOM }}</span>
									</el-form-item>
									<el-form-item label="设备备注">
										<span>{{ props.row.equip_remark }}</span>
									</el-form-item>
									<el-form-item label="产品兼容性">
										<span>{{ props.row.equip_compatibility }}</span>
									</el-form-item>
									<el-form-item label="处理记录">
										<span>{{ props.row.equip_CS_record }}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</template>
				</el-table-column>
				<el-table-column label="医院" prop="equip_hospital__hospital_name" show-overflow-tooltip sortable />
				<el-table-column label="设备名称" prop="equip_name" show-overflow-tooltip sortable />
				<el-table-column label="使用科室" prop="equip_dept" />
				<el-table-column label="品牌" prop="equip_brand" show-overflow-tooltip sortable />
				<el-table-column label="型号" prop="equip_model" show-overflow-tooltip sortable />
				<el-table-column label="数量" prop="equip_quantity"  />
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>


	</div>
</template>

<script>

export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			ifFullScreen: false,
			formLabelWidth: '100px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/queryequipment/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
	},	
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
