<template>
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card>
					<el-col :xs="24" :sm="6">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" clearable style="margin:5px"
							maxlength="30" @clear="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="16" style="margin-bottom: 20px;">
						<el-button style="margin: 5px;" size="mini" type="success"
							@click.prevent="addData()">新增医院客户</el-button>
					</el-col>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe>					
					<el-table-column label="省份" prop="hospital_area" sortable />
					<el-table-column label="市/地区" prop="hospital_city" sortable />
					<el-table-column label="医院名称" prop="hospital_name" sortable width="200" />
					<el-table-column label="医院类型" prop="hospital_type" sortable></el-table-column>
					<el-table-column label="医院级别" prop="hospital_level" sortable></el-table-column>
					<el-table-column label="床位数" prop="hospital_bed_num" sortable></el-table-column>
					<el-table-column label="备注" prop="hospital_remark" sortable></el-table-column>
					<el-table-column label="填报人" prop="hospital_user__person_name" sortable></el-table-column>
					<el-table-column label="最后更新" prop="update_date" sortable>
						<template slot-scope="props">
							<span>
								{{
									props.row.update_date
										.split('T')
										.slice(0, 1)
										.join('')
								}}
							</span>
						</template>
					</el-table-column>

					<el-table-column width="150" label="操作" align="center">
						<template slot-scope="scope">
							<el-button-group>
								<el-button size="mini" icon="el-icon-edit"
									@click="updateData(scope.$index, scope.row)">改</el-button>
								<el-button type="danger" size="mini" icon="el-icon-delete"
									@click="deleteData(scope.$index, scope.row)">删</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
				:total="totalItems" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				@current-change="handleCurrentChange" @size-change="handleSizeChange" />
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next" :total="totalItems"
				:page-size="pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange"
				prev-text="上一页" next-text="下一页" :pager-count="5" />
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog title="新增医院" :visible.sync="formAddDialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :width="'60%'" :fullscreen="ifFullScreen">
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="医院名称" prop="hospital_name"><el-input
						v-model="formAddData.hospital_name" /></el-form-item>
				<el-form-item label="省份" prop="hospital_area">
					<el-select v-model="formAddData.hospital_area" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市/地区" prop="hospital_city">
					<el-input v-model="formAddData.hospital_city" />
				</el-form-item>
				<el-form-item label="医院类型" prop="hospital_type">
					<el-select v-model="formAddData.hospital_type" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="医院等级" prop="hospital_level">
					<el-select v-model="formAddData.hospital_level" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in levelOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="床位数" prop="hospital_bed_num">
					<el-input-number v-model="formAddData.hospital_bed_num" :min="1" :max="9999" 
						size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="备注" prop="hospital_remark"><el-input type="textarea" autosize
						v-model="formAddData.hospital_remark" /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog title="修改客户信息" :visible.sync="formUpdateDialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :fullscreen="ifFullScreen">
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth"
				size="mini">
				<el-form-item label="医院名称" prop="hospital_name"><el-input
						v-model="formUpdateData.hospital_name" /></el-form-item>
				<el-form-item label="省份/地区" prop="hospital_area">
					<el-select v-model="formUpdateData.hospital_area" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市/地区" prop="hospital_city">
					<el-input v-model="formUpdateData.hospital_city" />
				</el-form-item>				
				<el-form-item label="医院等级" prop="hospital_level">
					<el-select v-model="formUpdateData.hospital_level" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in levelOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="医院类型" prop="hospital_type">
					<el-select v-model="formUpdateData.hospital_type" filterable placeholder="请选择" allow-create
						default-first-option>
						<el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="床位数" prop="hospital_bed_num">
					<el-input-number v-model="formUpdateData.hospital_bed_num" :min="1" :max="9999" 
						size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="备注" prop="hospital_remark"><el-input type="textarea" autosize
						v-model="formUpdateData.hospital_remark" /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formUpdateDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				keywords: '',
				// ifMyOwn: true,
				formAddDialogVisible: false,
				formUpdateDialogVisible: false,
				ifFullScreen: false,
				formAddData: {
					pk: '',
					hospital_name: '',
					hospital_area: '',
					hospital_level: '',
					hospital_type: '',
					hospital_remark: '',
					hosptial_city:'',
					hospital_bed_num:''
				},
				formUpdateData: {
					pk: '',
					hospital_name: '',
					hospital_area: '',
					hospital_level: '',
					hospital_type: '',
					hospital_remark: '',
					hosptial_city:'',
					hospital_bed_num:''
				},
				formNewBrand: {
					brand_name: ''
				},
				//新增和更新表单的验证规则
				rules: {
					hospital_name: [{
						required: true,
						message: '请填写医院名称',
						trigger: 'blur'
					}, {
						max: 40,
						message: '长度不超过40个字符',
						trigger: 'blur'
					}],
					hospital_area: [{
						required: true,
						message: '请选择或填写省份',
						trigger: 'change'
					}],
					hospital_city: [{
						required: true,
						message: '请填写地区',
						trigger: 'change'
					}],
					hospital_level: [{
						required: true,
						message: '请选择医院级别',
						trigger: 'change'
					}],
					hospital_type: [{
						required: true,
						message: '请选择医院类型',
						trigger: 'change'
					}]
				},
				formLabelWidth: '100px',
				totalItems: 100,
				currentPage: 1,
				pageSize: 20,
				deleteItemPk: '',
				loading: false,
				brandOptions: [],
				visible: false,
				provinceOptions: [{
						value: '北京',
						label: '北京'
					},
					{
						value: '天津',
						label: '天津'
					},
					{
						value: '上海',
						label: '上海'
					},
					{
						value: '重庆',
						label: '重庆'
					},
					{
						value: '河北',
						label: '河北'
					},
					{
						value: '山西',
						label: '山西'
					},
					{
						value: '辽宁',
						label: '辽宁'
					},
					{
						value: '吉林',
						label: '吉林'
					},
					{
						value: '黑龙江',
						label: '黑龙江'
					},
					{
						value: '江苏',
						label: '江苏'
					},
					{
						value: '浙江',
						label: '浙江'
					},
					{
						value: '安徽',
						label: '安徽'
					},
					{
						value: '福建',
						label: '福建'
					},
					{
						value: '江西',
						label: '江西'
					},
					{
						value: '山东',
						label: '山东'
					},
					{
						value: '河南',
						label: '河南'
					},
					{
						value: '湖北',
						label: '湖北'
					},
					{
						value: '湖南',
						label: '湖南'
					},
					{
						value: '广东',
						label: '广东'
					},
					{
						value: '甘肃',
						label: '甘肃'
					},
					{
						value: '四川',
						label: '四川'
					},
					{
						value: '贵州',
						label: '贵州'
					},
					{
						value: '海南',
						label: '海南'
					},
					{
						value: '云南',
						label: '云南'
					},
					{
						value: '青海',
						label: '青海'
					},
					{
						value: '陕西',
						label: '陕西'
					},
					{
						value: '广西',
						label: '广西'
					},
					{
						value: '西藏',
						label: '西藏'
					},
					{
						value: '宁夏',
						label: '宁夏'
					},
					{
						value: '新疆',
						label: '新疆'
					},
					{
						value: '内蒙',
						label: '内蒙'
					},
					{
						value: '澳门',
						label: '澳门'
					},
					{
						value: '香港',
						label: '香港'
					},
					{
						value: '台湾',
						label: '台湾'
					}
				],
				levelOptions: [{
						value: '三级特等',
						label: '三级特等'
					},
					{
						value: '三级甲等',
						label: '三级甲等'
					},
					{
						value: '三级乙等',
						label: '三级乙等'
					},
					{
						value: '三级丙等',
						label: '三级丙等'
					},
					{
						value: '二级甲等',
						label: '二级甲等'
					},
					{
						value: '二级乙等',
						label: '二级乙等'
					},
					{
						value: '二级丙等',
						label: '二级丙等'
					},
					{
						value: '一级甲等',
						label: '一级甲等'
					},
					{
						value: '一级乙等',
						label: '一级乙等'
					},
					{
						value: '一级丙等',
						label: '一级丙等'
					}
				],
				typeOptions: [{
						value: '三级综合',
						label: '三级综合'
					},
					{
						value: '二级综合',
						label: '二级综合'
					},
					{
						value: '一级综合',
						label: '一级综合'
					},
					{
						value: '专科',
						label: '专科'
					},
					{
						value: '教学',
						label: '教学'
					},
					{
						value: '诊所',
						label: '诊所'
					}
				]
			};
		},
		methods: {
			//通用函数-获取数据
			getData(keywords, currentPage, pageSize) {
				let that = this;
				that.loading = true;
				this.$axios
					.get('/apim/get/customer/', {
						params: {
							keywords: keywords,
							currentpage: currentPage,
							pagesize: pageSize
							// ifmyown:ifMyOwn
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.tableData = response.data.data;
							// console.log(response.data.data);
							that.totalItems = response.data.totalItemNum;
							that.loading = false;
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},
			//通用功能 - 关键字搜索
			Search() {
				let that = this;
				this.currentPage = 1;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//新增数据-对话框
			addData() {
				this.formAddDialogVisible = true;
			},
			//新增数据-提交
			submitAddForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formAddData;
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						console.log(this.formAddData);
						this.$axios({
								url: '/apim/add/customer/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.reload();
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//新增数据 - 重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.formAddDialogVisible = false;
			},
			//更新数据 - 对话框
			updateData(index, row) {
				//解除双向绑定
				this.formUpdateData = JSON.parse(JSON.stringify(row));
				this.formUpdateDialogVisible = true;
				// console.log(this.formUpdateData)
			},
			//更新数据-提交
			submitUpdateForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formUpdateData;
						//遍历添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
						}
						this.$axios({
								url: '/apim/update/customer/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.getData(that.keywords, that.currentPage, that.pageSize);
									that.formUpdateDialogVisible = false;
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//删除数据 - 对话框
			deleteData(index, row) {
				let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.submitDeleteData(deleteItemPk);
					})
					.catch(() => {});
			},
			//删除数据-提交
			submitDeleteData(dataPk) {
				var that = this;
				var fd = new FormData();
				fd.append('pk', dataPk);
				this.$axios({
						url: '/apim/delete/customer/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res.data);
						if (res.data.code == '200') {
							that.reload();
						} else {
							that.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//分页跳转
			handleCurrentChange(val) {
				let that = this;
				this.currentPage = val;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//每页显示条数更改
			handleSizeChange(val) {
				let that = this;
				this.pageSize = val;
				this.currentPage = 1;
				this.getData(that.keywords, that.currentPage, that.pageSize);
			},
			//路由跳转
			jumpTo(name, id) {
				var fullpath = name + id + '/';
				this.$router.push({
					path: fullpath
				});
			}
		},
		mounted() {
			var that = this;
			this.getData('', that.currentPage, that.pageSize);
			that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
		}
	};
</script>

<style></style>