<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="10">
				<el-col :xs="24" :sm="12" :md="12">
					<el-date-picker
						v-model="date_range"
						type="monthrange"
						align="right"
						unlink-panels
						range-separator="至"
						start-placeholder="开始月份"
						end-placeholder="结束月份"
						value-format="yyyy-MM-dd"
						size="mini"
					/>
					<el-button size="mini" type="primary" @click="Search()" style="margin-left: 15px;">查询</el-button>
					<el-button size="mini" @click="showAreaUsageDialog()">区域统计</el-button>
				</el-col>
				<el-col :xs="24" :sm="10" :md="10" style="margin-left: 15px;">
					<el-tag type="danger" size="medium">最多：{{ static_data.usage_quantity__max }}</el-tag>
					<el-tag type="success" size="medium">最少：{{ static_data.usage_quantity__min }}</el-tag>
					<el-tag size="medium">总量：{{ static_data.usage_quantity__sum }}</el-tag>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe max-height="600">
				<el-table-column label="日期" prop="usage_date" show-overflow-tooltip sortable>
					<template slot-scope="scope">
						{{
							scope.row.usage_date
								.split('-')
								.slice(0, 2)
								.join('-')
						}}
					</template>
				</el-table-column>
				<el-table-column label="地区" prop="usage_marketshare__marketshare_hospital__hospital_area" sortable />
				<el-table-column label="医院" prop="usage_marketshare__marketshare_hospital__hospital_name" sortable show-overflow-tooltip />
				<el-table-column label="部门" prop="usage_marketshare__marketshare_dept" sortable />
				<el-table-column label="月用量(件)" prop="usage_quantity" />
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>
		<!-- 月用量展示主对话框 -->
		<el-dialog title="各地区用量统计" :visible.sync="areaUsageDialogVisible" :fullscreen="ifFullScreen">
			<el-row>
				<el-col>
					<el-table :data="areaUsageTableData" style="width: 100%; " stripe max-height="400" show-summary>
						<el-table-column label="地区" prop="name" />
						<el-table-column label="总量" prop="value" sortable>
							<template slot-scope="props">
								{{ props.row.value }}
							</template>
						</el-table-column>
					</el-table>
				</el-col>
				<el-col>
					<div style="height: 400px;"><v-chart :options="areaUsagePieData" autoresize theme="light" /></div>
				</el-col>
			</el-row>
		</el-dialog>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formLabelWidth: '150px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false,
			static_data: {},
			date_range: [],
			areaUsageDialogVisible:false,
			areaUsageTableData:[],
			ifFullScreen:false,
			areaUsagePieData: {
				title: {
					text: '2021年各地区用量统计',
					left: 'center',
					top: 20
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						dataView: { show: true, readOnly: false },
						magicType: {
							show: true,
							type: ['pie', 'funnel']
						},
						restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				tooltip: {
					trigger: 'item',
					formatter: '{a} <br/>{b} : {c} ({d}%)'
				},
				series: [
					{
						name: '地区用量',
						type: 'pie',
						center: ['50%', '50%'],
						selectedMode: 'single',
						radius: [0, '30%'],
			
						label: {
							position: 'inner'
						},
						labelLine: {
							show: false
						},
						data: [{ value: 335, name: '北京', selected: true }, { value: 679, name: '天津' }, { value: 1548, name: '上海' }]
					}

				],
				animationDuration: 3000
			},
		};
	},
	methods: {
		//通用函数-获取数据
		getData(date_range, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/productusage/', {
					params: {
						date_range: that.date_range !== null ? that.date_range.toString() : '',
						currentpage: currentPage,
						pagesize: pageSize,
						prod_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.static_data = response.data.static_data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.date_range, that.currentPage, that.pageSize);
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.date_range, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.date_range, that.currentPage, that.pageSize);
		},
		//获取区域用量数据
		showAreaUsageDialog() {
			this.areaUsageDialogVisible = true;
			var that = this;
			this.$axios
				.get('/apim/get/areausage/', {
					params: {
						prod_pk: that.$route.params.id,
						date_range: that.date_range !== null ? that.date_range.toString() : ''
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.areaUsageTableData = response.data.data;
						that.areaUsagePieData.series[0].data = response.data.data;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		toDecimal(x) {
			if (typeof x == 'undefined') {
				return x;
			} else {
				return Number(x.toFixed(2));
			}
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
