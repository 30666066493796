<template>
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row class="margin-bottom-sm">
			<el-card>
				<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
					<el-form-item prop="date_range">
						<el-date-picker v-model="searchData.date_range" type="year" align="right" value-format="yyyy"
							size="mini" placeholder="请选择年份" />
					</el-form-item>
					<el-form-item prop="area">
						<el-select v-model="searchData.area" placeholder="请选择地区" multiple collapse-tags>
							<el-option v-for="item in areaOptions" :key="item.index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitFormSearchData()">查看报表</el-button>
						<el-button @click="resetSearchForm('formSearch')">重置</el-button>
						<el-button type="success" @click="exportTableData(searchData,'年度地区品牌月销量')">导出</el-button>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item>
						<el-checkbox v-model="quantity_checked">销量</el-checkbox>
						<el-checkbox v-model="amount_checked">订货额</el-checkbox>
					</el-form-item>
					<el-divider direction="vertical"></el-divider>
					<el-form-item label="图表风格">
						<el-select v-model="echartTheme">
							<el-option v-for="theme in themes" :key="theme.value" :label="theme.label"
								:value="theme.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</el-card>
		</el-row>

		<el-row style="margin-top:10px;">
			<el-card>
				<el-tabs v-model="tableActiveName">
					<el-tab-pane label="进口+国产" name="table_total">
						<el-table :data="tableDataTotal" style="width: 100%; " show-summary max-height="600">
							<el-table-column fixed label="地区" prop="province" sortable width="100">
							</el-table-column>
							<el-table-column :label="tableTitle" width="100%">
								<el-table-column :label="item.label" v-for="(item, index) in tableHeader" :key="index">
									<el-table-column label="销量(桶)" :prop="item.prop_quantity" sortable width="120"
										v-if="quantity_checked" />
									<el-table-column label="订货额(元)" :prop="item.prop_amount" sortable width="120"
										v-if="amount_checked" />
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="进口鲁沃夫" name="table_ruhof">
						<el-table :data="tableDataRuhof" style="width: 100%; " show-summary max-height="600">
							<el-table-column fixed label="地区" prop="province" sortable width="100">
							</el-table-column>
							<el-table-column :label="tableTitle" width="100%">
								<el-table-column :label="item.label" v-for="(item,index) in tableHeader" :key="index">
									<el-table-column label="销量(桶)" :prop="item.prop_quantity" sortable width="120"
										v-if="quantity_checked" />
									<el-table-column label="订货额(元)" :prop="item.prop_amount" sortable width="120"
										v-if="amount_checked" />
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>
					<el-tab-pane label="国产鲁沃夫" name="table_anji">
						<el-table :data="tableDataAnji" style="width: 100%; " show-summary max-height="600">
							<el-table-column fixed label="地区" prop="province" sortable width="100">
							</el-table-column>
							<el-table-column :label="tableTitle" width="100%">
								<el-table-column :label="item.label" v-for="(item,index) in tableHeader" :key="index">
									<el-table-column label="销量(桶)" :prop="item.prop_quantity" sortable width="120"
										v-if="quantity_checked" />
									<el-table-column label="订货额(元)" :prop="item.prop_amount" sortable width="120"
										v-if="amount_checked" />
								</el-table-column>
							</el-table-column>
						</el-table>
					</el-tab-pane>

				</el-tabs>

			</el-card>
		</el-row>

		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="barChartActiveName">
						<el-tab-pane label="按品牌" name="bar_brand">
							<div class="plate-card"><v-chart :options="bar_quantity_brand" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
							<div class="plate-card"><v-chart :options="bar_amount_brand" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>

						<el-tab-pane label="按区域" name="bar_area">
							<div class="plate-card"><v-chart :options="bar_quantity_area" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
							<div class="plate-card"><v-chart :options="bar_amount_area" autoresize
									:theme="echartTheme">/></v-chart>
							</div>
						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
		<el-row style="margin-top: 10px;" :gutter="15">
			<el-col :sm="24" :xs="24">
				<el-card>
					<el-tabs v-model="pieChartActiveName">
						<el-tab-pane label="进口+国产" name="pie_total">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_total" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_total" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>

						</el-tab-pane>

						<el-tab-pane label="进口鲁沃夫" name="pie_ruhof">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_ruhof" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

						<el-tab-pane label="国产鲁沃夫" name="pie_anji">
							<el-row :gutter="10">
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_quantity_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
								<el-col :sm="12" :xs="24">
									<div class="plate-card"><v-chart :options="pie_amount_anji" autoresize
											:theme="echartTheme">/></v-chart></div>
								</el-col>
							</el-row>
						</el-tab-pane>

					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>


<script>
	import ECharts from 'vue-echarts';
	export default {
		inject: ['logout'],
		data() {
			return {
				searchData: {
					area: '',
					date_range: ''
				},
				tableDataTotal: [],
				tableDataRuhof: [],
				tableDataAnji: [],
				tableHeader: [{
					label: '月份',
					prop_quantity: '',
					prop_amount: ''
				}],
				quantity_checked: true,
				amount_checked: true,
				tableTitle: '年度月销售数据',
				tableActiveName: 'table_total',
				loading: false,
				echartTheme: 'light',
				themes: [{
						value: 'light',
						label: '默认'
					},
					{
						value: 'dark',
						label: '黑暗'
					},
					{
						value: 'shine',
						label: '闪亮'
					},
					{
						value: 'vintage',
						label: '复古'
					},
					{
						value: 'helianthus',
						label: '太阳花'
					},
					{
						value: 'tech-blue',
						label: '科技蓝'
					},
					{
						value: 'london',
						label: '伦敦'
					},
					{
						value: 'roma',
						label: '罗马'
					},
					{
						value: 'sakura',
						label: '樱花粉'
					},
					{
						value: 'fresh-cut',
						label: '鲜果切'
					}
					// 你可以继续添加其他主题
				],
				barChartActiveName: 'bar_brand',
				pieChartActiveName: 'pie_total',
				areaOptions: [],
				bar_quantity_brand: {},
				bar_amount_brand: {},
				bar_quantity_area: {},
				bar_amount_area: {},
				pie_quantity_ruhof: {},
				pie_amount_ruhof: {},
				pie_quantity_anji: {},
				pie_amount_anji: {},
				pie_quantity_total: {},
				pie_amount_total: {},

				pie_sale_quantity: {
					title: {
						text: '地区销量',
						left: 'center',
						top: 5
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 桶 ({d}%)'
					},
					series: [{
						name: '销量',
						type: 'pie',
						center: ['50%', '60%'],
						selectedMode: 'single',
						radius: [0, '60%'],
						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							rich: {
								b: {
									fontSize: 14,
									lineHeight: 33,
									padding: [2, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						data: []
					}],
					animationDuration: 2000
				},
				pie_sale_amount: {
					title: {
						text: '地区订货额',
						left: 'center',
						top: 5
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							magicType: {
								show: true,
								type: ['pie', 'funnel']
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b} : {c} 元 ({d}%)'
					},
					series: [{
						name: '订货额',
						type: 'pie',
						radius: ['30%', '60%'],
						center: ['50%', '60%'],
						data: [],
						label: {
							formatter: '{b|{b}}{per|{d}%}  ',
							rich: {
								b: {
									fontSize: 14,
									lineHeight: 33,
									padding: [2, 4]
								},
								per: {
									color: '#ffffff',
									backgroundColor: '#403a3a',
									padding: [4, 4],
									borderRadius: 2
								}
							}
						},
						itemStyle: {
							shadowBlur: 10,
							shadowColor: 'rgba(0, 0, 0, 0.1)'
						},
						animationType: 'scale',
						animationEasing: 'elasticOut',
						animationDelay: function(idx) {
							return Math.random() * 200;
						}
					}],
					animationDuration: 2000
				},
				bar_chart_quantity: {
					title: {
						text: '地区销量(桶)',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}`;
							params.sort((a, b) => b.value - a.value);
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							tooltipText += ' ( 总计：' + totalValue.toLocaleString() + ` 桶 ) <br/>`
							params.forEach((item, index) => {
								const formattedValue = item.value.toLocaleString();
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';
								tooltipText +=
									` ${item.marker}${item.seriesName}：${formattedValue}（桶）（${percentage}%）`;
								if ((index + 1) % 3 === 0 && index !== params.length - 1) {
									tooltipText += `<br/>`;
								}
							});
							return tooltipText;

						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '15%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '进口鲁沃夫',
							type: 'bar',
							stack: 'Total',
							// areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '国产鲁沃夫',
							type: 'bar',
							stack: 'Total',
							// areaStyle: {},
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
				bar_chart_amount: {
					title: {
						text: '地区订货额（元）',
						left: 'center',
						top: 20
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let tooltipText = `${params[0].axisValue}`;
							params.sort((a, b) => b.value - a.value);
							let totalValue = params.reduce((acc, item) => acc + item.value, 0);
							tooltipText += ' ( 总计：' + totalValue.toLocaleString() + ` 元 ) <br/>`
							params.forEach((item, index) => {
								const formattedValue = item.value.toLocaleString();
								const percentage = totalValue !== 0 ? ((item.value / totalValue) * 100)
									.toFixed(2) : '0.00';
								tooltipText +=
									` ${item.marker}${item.seriesName}：${formattedValue}（元）（${percentage}%）`;
								if ((index + 1) % 3 === 0 && index !== params.length - 1) {
									tooltipText += `<br/>`;
								}
							});
							return tooltipText;

						}
					},
					toolbox: {
						show: true,
						orient: 'vertical',
						left: 'right',
						top: 'center',
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: true
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					legend: {
						bottom: 10,
						left: 'center',
						orient: 'horizontal',
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '15%',
						containLabel: true
					},
					xAxis: [{
						type: 'category',
						data: []
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '进口鲁沃夫',
							type: 'bar',
							stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: []
						},
						{
							name: '国产鲁沃夫',
							type: 'bar',
							stack: 'Ad',
							emphasis: {
								focus: 'series'
							},
							data: []
						},

					]
				},
			};
		},
		mounted() {
			// 获取数据
			this.getAreaOptions();
		},
		created() {
			// 深拷贝数据以取消双向绑定
			this.pie_quantity_ruhof = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_quantity_anji = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_quantity_total = JSON.parse(JSON.stringify(this.pie_sale_quantity));
			this.pie_amount_ruhof = JSON.parse(JSON.stringify(this.pie_sale_amount));
			this.pie_amount_anji = JSON.parse(JSON.stringify(this.pie_sale_amount));
			this.pie_amount_total = JSON.parse(JSON.stringify(this.pie_sale_amount));

			// 深拷贝数据以取消双向绑定

			this.bar_quantity_brand = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_amount_brand = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};

			this.bar_quantity_area = {
				...JSON.parse(JSON.stringify(this.bar_chart_quantity)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_quantity.tooltip,
					formatter: this.bar_chart_quantity.tooltip.formatter
				}
			};

			this.bar_amount_area = {
				...JSON.parse(JSON.stringify(this.bar_chart_amount)), // 使用浅拷贝保留大部分属性
				tooltip: { // 手动添加 tooltip 以保留 formatter 函数
					...this.bar_chart_amount.tooltip,
					formatter: this.bar_chart_amount.tooltip.formatter
				}
			};
		},
		methods: {
			//重置搜索表单
			resetSearchForm(formName) {
				let that = this;
				this.$refs[formName].resetFields();
				this.$refs[formName].clearValidate();
			},
			//提交搜索
			submitFormSearchData() {
				console.log(this.searchData)
				this.getTableAndChartData(this.searchData);
			},
			// 获取地区列表
			getAreaOptions() {
				let that = this;
				this.$axios
					.get('/apis/get_area_list/')
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.areaOptions = response.data;
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},

			//获取表格及图表数据
			getTableAndChartData(queryData) {
				let that = this;
				that.loading = true;
				//添加表单数据
				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}
				this.$axios({
						url: '/apis/get_yearly_area_sales/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						console.log(res)
						that.loading = false;
						if (res.data.code == '401') {
							that.logout();
						} else {
							let date_range = res.data.date_range;
							let area = res.data.area;

							that.tableDataTotal = res.data.tableDataTotal;
							that.tableDataRuhof = res.data.tableDataRuhof;
							that.tableDataAnji = res.data.tableDataAnji;
							that.tableHeader = res.data.tableHeader;
							that.tableTitle = date_range + '年度' + area + '地区月销售数据'

							that.bar_quantity_brand.title.text = date_range + '年' + area + '地区月销量（桶）';
							that.bar_quantity_brand.xAxis[0].data = res.data.xAxis_data;
							that.bar_quantity_brand.series[0].data = res.data.bar_chart_quantity_series_0_data;
							that.bar_quantity_brand.series[1].data = res.data.bar_chart_quantity_series_1_data;

							that.bar_amount_brand.title.text = date_range + '年' + area + '地区月订货额（元）';
							that.bar_amount_brand.xAxis[0].data = res.data.xAxis_data;
							that.bar_amount_brand.series[0].data = res.data.bar_chart_amount_series_0_data;
							that.bar_amount_brand.series[1].data = res.data.bar_chart_amount_series_1_data;

							that.bar_quantity_area.title.text = date_range + '年' + area + '地区月销量（桶）';
							that.bar_quantity_area.xAxis[0].data = res.data.xAxis_data;
							that.bar_quantity_area.series = res.data.bar_quantity_area_series;

							that.bar_amount_area.title.text = date_range + '年' + area + '地区月订货额（元）';
							that.bar_amount_area.xAxis[0].data = res.data.xAxis_data;
							that.bar_amount_area.series = res.data.bar_amount_area_series;


							that.pie_quantity_ruhof.title.text = date_range + '年' + area + '地区月销量（桶）'
							that.pie_quantity_ruhof.series[0].data = res.data.pie_chart_quantity_ruhof_series;

							that.pie_amount_ruhof.title.text = date_range + '年' + area + '地区月订货额（元）'
							that.pie_amount_ruhof.series[0].data = res.data.pie_chart_amount_ruhof_series;

							that.pie_quantity_anji.title.text = date_range + '年' + area + '地区月销量（桶）'
							that.pie_quantity_anji.series[0].data = res.data.pie_chart_quantity_anji_series;

							that.pie_amount_anji.title.text = date_range + '年' + area + '地区月订货额（元）'
							that.pie_amount_anji.series[0].data = res.data.pie_chart_amount_anji_series;

							that.pie_quantity_total.title.text = date_range + '年' + area + '地区月销量（桶）'
							that.pie_quantity_total.series[0].data = res.data.pie_chart_quantity_total_series;

							that.pie_amount_total.title.text = date_range + '年' + area + '地区月订货额（元）'
							that.pie_amount_total.series[0].data = res.data.pie_chart_amount_total_series;

							that.barChartActiveName = "bar_brand";
						}
					})
					.catch(function(error) {
						console.log(error);
						that.loading = false;
					});
			},

			//下载数据
			exportTableData(queryData, filename) {
				let that = this;
				that.loading = true;
				//添加表单数据

				let fd = new FormData();
				let formData = queryData;
				for (let key in formData) {
					fd.append(key, formData[key]);
				}
				if (queryData.date_range == null) {
					fd.set('date_range', '');
				}

				let fileName = filename + Date.now();

				this.$axios({
						url: '/apis/export_yearly_area_sales/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						responseType: 'blob'
					})
					.then(function(res) {
						const _res = res.data;
						let blob = new Blob([_res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
						let objectUrl = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.href = objectUrl;
						a.download = fileName.replace('.', '');
						a.dispatchEvent(new MouseEvent('click', {
							bubbles: true,
							cancelable: true,
							view: window
						}));
						that.loading = false;
						window.URL.revokeObjectURL(blob);
					})
					.catch(function(err) {
						that.loading = false;
						console.log(err);
					});
			}
		}
	};
</script>

<style scoped>
	.title-text {
		color: #00a6dd;
		font-size: 18px;
		font-weight: 800;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: auto;
		margin: 0;
	}

	.echarts {
		width: 100%;
		height: 100%;
	}

	.plate-card {
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		height: 500px;
	}

	.margin-bottom-sm {
		margin-bottom: 10px;
	}
</style>