<template>
	<div v-loading="loading" element-loading-text="获取数据中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row :gutter="20">
			<el-col>
				<el-card>
					<el-col :xs="24" :sm="6">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" clearable style="margin:5px" maxlength="30" @clear="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="6" style="margin-bottom: 20px;">
						<el-button style="margin: 5px;" size="mini" type="success" @click.prevent="addData()">新增大区</el-button>
					</el-col>
				</el-card>
			</el-col>
		</el-row>

		<el-row style="margin-top:15px;">
			<el-card>
				<el-table :data="tableData" style="width: 100%; " stripe>
					<el-table-column label="大区名称" prop="name" />
					<el-table-column label="辖区" prop="area" />
					<el-table-column label="经理">
						<template slot-scope="scope">
							{{
								String(scope.row.manager)
									.split(',')
									.join(', ')
							}}
						</template>
					</el-table-column>
					<el-table-column label="专员">
						<template slot-scope="scope">
							{{
								String(scope.row.staffs)
									.split(',')
									.join(', ')
							}}
						</template>
					</el-table-column>
					<el-table-column width="150" label="操作" align="center">
						<template slot-scope="scope">
							<el-button-group>
								<el-button size="mini" @click="updateData(scope.$index, scope.row)">修 改</el-button>
								<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</el-row>

		<!-- 分页 -->
		<el-row class="paginator hidden-sm-and-down">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="total, sizes, prev, pager, next"
				:total="totalItems"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
			/>
		</el-row>

		<el-row class="paginator hidden-sm-and-up">
			<el-pagination
				background
				:current-page.sync="currentPage"
				layout="prev, pager, next"
				:total="totalItems"
				:page-size="pageSize"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				prev-text="上一页"
				next-text="下一页"
				:pager-count="5"
			/>
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增大区"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="大区名称" prop="name"><el-input v-model="formAddData.name" clearable /></el-form-item>
				<el-form-item label="辖区" prop="area"><el-input v-model="formAddData.area" clearable /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改大区信息"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="mini">
				<el-form-item label="大区名称" prop="name"><el-input v-model="formUpdateData.name" clearable /></el-form-item>
				<el-form-item label="辖区" prop="area"><el-input v-model="formUpdateData.area" clearable /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formUpdateDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				name: '',
				area: ''
			},
			formUpdateData: {
				pk: '',
				name: '',
				area: ''
			},
			//新增和更新表单的验证规则
			rules: {
				name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }, { max: 40, message: '长度不超过20个字符', trigger: 'blur' }],
				area: [{ required: true, message: '请输入管理区域', trigger: 'blur' }, { max: 40, message: '长度不超过20个字符', trigger: 'blur' }]
			},
			formLabelWidth: '80px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/api/get/marketarea/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						console.log(response.data.data);
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/api/add/marketarea/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.reload();
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
			// console.log(this.formUpdateData)
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/api/update/marketarea/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/api/delete/marketarea/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.reload();
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		// 获取用户列表
		getMarketAreaOptions() {
			var that = this;
			this.$axios
				.get('/api/get/userlistoption/')
				.then(function(response) {
					that.marketAreaOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		this.getMarketAreaOptions();
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
