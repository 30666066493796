<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<!-- <el-card> -->
			<el-row :gutter="10">
				<el-col>
					<el-card>
						<el-form ref="formSearch" :model="searchData" label-width="80px" size="mini" :inline="true">
							<el-form-item prop="area">
								<el-select v-model="searchData.area" filterable multiple collapse-tags placeholder="客户地区">
									<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item prop="brand_type">
								<el-select v-model="searchData.brand_type" filterable multiple collapse-tags placeholder="产品品牌">
									<el-option v-for="item in brandOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item prop="product_type">
								<el-select v-model="searchData.product_type" filterable multiple collapse-tags placeholder="产品分类">
									<el-option v-for="item in prodTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>

							<el-form-item prop="keywords"><el-input v-model="searchData.keywords" placeholder="医院或产品名称" clearable maxlength="20" /></el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitFormSearchData()">搜索</el-button>
								<el-button @click="resetSearchForm('formSearch')">重置</el-button>
								<el-button type="success" @click="exportData(searchData, '客户使用产品数据')">导出</el-button>
							</el-form-item>
						</el-form>
					</el-card>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column label="医院" prop="marketshare_hospital__hospital_name" sortable show-overflow-tooltip />
				<el-table-column label="地区" prop="marketshare_hospital__hospital_area" sortable  width="80"/>
				<el-table-column label="级别" prop="marketshare_hospital__hospital_level" sortable  width="80"/>
				<el-table-column label="部门" prop="marketshare_dept" sortable  />
				<el-table-column label="产品" prop="marketshare_product__prod_name" sortable  />
				<el-table-column label="品牌" prop="marketshare_product__prod_brand__brand_name" sortable width="160"/>
				<el-table-column label="类型" prop="marketshare_product__prop_type__type_name" sortable  width="160"/>
				<el-table-column label="价格(元)" prop="marketshare_price" width="90" />
				<el-table-column label="供应商" prop="marketshare_supplier" width="200"/>
				<el-table-column label="上报人" prop="marketshare_user__person_name" width="100"/>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>
	</div>
</template>

<script>
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			ifFullScreen: false,
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			loading: false,
			formLabelWidth: '100px',
			searchData: {
				area: [],
				brand_type: [],
				product_type: [],
				keywords: ''
			},
			brandOptions: [],
			prodTypeOptions: [],
			provinceOptions: [
				{
					value: '北京',
					label: '北京'
				},
				{
					value: '天津',
					label: '天津'
				},
				{
					value: '上海',
					label: '上海'
				},
				{
					value: '重庆',
					label: '重庆'
				},
				{
					value: '河北',
					label: '河北'
				},
				{
					value: '山西',
					label: '山西'
				},
				{
					value: '辽宁',
					label: '辽宁'
				},
				{
					value: '吉林',
					label: '吉林'
				},
				{
					value: '黑龙江',
					label: '黑龙江'
				},
				{
					value: '江苏',
					label: '江苏'
				},
				{
					value: '浙江',
					label: '浙江'
				},
				{
					value: '安徽',
					label: '安徽'
				},
				{
					value: '福建',
					label: '福建'
				},
				{
					value: '江西',
					label: '江西'
				},
				{
					value: '山东',
					label: '山东'
				},
				{
					value: '河南',
					label: '河南'
				},
				{
					value: '湖北',
					label: '湖北'
				},
				{
					value: '湖南',
					label: '湖南'
				},
				{
					value: '广东',
					label: '广东'
				},
				{
					value: '甘肃',
					label: '甘肃'
				},
				{
					value: '四川',
					label: '四川'
				},
				{
					value: '贵州',
					label: '贵州'
				},
				{
					value: '海南',
					label: '海南'
				},
				{
					value: '云南',
					label: '云南'
				},
				{
					value: '青海',
					label: '青海'
				},
				{
					value: '陕西',
					label: '陕西'
				},
				{
					value: '广西',
					label: '广西'
				},
				{
					value: '西藏',
					label: '西藏'
				},
				{
					value: '宁夏',
					label: '宁夏'
				},
				{
					value: '新疆',
					label: '新疆'
				},
				{
					value: '内蒙',
					label: '内蒙'
				},
				{
					value: '澳门',
					label: '澳门'
				},
				{
					value: '香港',
					label: '香港'
				},
				{
					value: '台湾',
					label: '台湾'
				}
			]
		};
	},
	methods: {
		//通用函数-获取数据
		getData(queryData, currentPage, pageSize) {
			let that = this;
			that.loading = true;
			//添加表单数据
			let fd = new FormData();
			let formData = queryData;
			for (let key in formData) {
				fd.append(key, formData[key]);
			}
			fd.append('currentpage', currentPage);
			fd.append('pagesize', pageSize);

			this.$axios({
				url: '/apim/get/customeruseproducts/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//重置搜索表单
		resetSearchForm(formName) {
			var that = this;
			this.$refs[formName].resetFields();
			this.$refs[formName].clearValidate();
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		// 获取品牌列表
		getBrandOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/brandoptions/')
				.then(function(response) {
					that.brandOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		// 获取品牌列表
		getProdTypeOptions() {
			var that = this;
			this.$axios
				.get('/apim/get/prodtypeoptions/')
				.then(function(response) {
					that.prodTypeOptions = response.data;
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//提交搜索
		submitFormSearchData() {
			var that = this;
			this.getData(that.searchData, that.currentPage, that.pageSize);
		},
		//导出客户产品使用数据
		exportData(queryData, filename) {
			let that = this;
			that.loading = true;
			//添加表单数据
			let fd = new FormData();
			let formData = queryData;
			for (let key in formData) {
				fd.append(key, formData[key]);
			}
			let fileName = filename + Date.now();
			this.$axios({
				url: '/apim/export/customeruseproducts/',
				responseType: 'blob',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					const _res = res.data;
					let blob = new Blob([_res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
					let objectUrl = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = objectUrl;
					a.download = fileName.replace('.', '');
					a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
					that.loading = false;
					window.URL.revokeObjectURL(blob);
				})
				.catch(function(err) {
					that.loading = false;
					console.log(err);
				});
		}
	},
	mounted() {
		let that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
		that.getBrandOptions();
		that.getProdTypeOptions();
	}
};
</script>

<style></style>
