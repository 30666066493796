<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<!-- <el-card>
			 -->
				<el-row :gutter="15">
					<el-col :xs="24" :sm="8" :md="8">
						<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
							<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
						</el-input>
					</el-col>
					<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
						<el-button style="margin: 5px;" size="mini" type="success" @click="addData()">新增经销商</el-button>
						<!-- <el-button style="margin: 5px;" size="mini" @click="exportExcel()">导出</el-button> -->
					</el-col>
				</el-row>

				
				<el-table :data="tableData" style="width: 100%; " stripe :default-sort="{ prop: 'date', order: 'descending' }">
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-form label-position="left" class="demo-table-expand">
								<el-row>
									<el-col :xs="24" :sm="12">
										<el-form-item label="公司名称">
											<span>{{ props.row.dist_name }}</span>
										</el-form-item>
										<el-form-item label="省份/地区">
											<span>{{ props.row.dist_area }}</span>
										</el-form-item>
										<el-form-item label="经销商类型">
											<span>{{ props.row.dist_level }}</span>
										</el-form-item>
										<el-form-item label="授权情况">
											<span>{{ props.row.dist_authorize }}</span>
										</el-form-item>
									</el-col>
									<el-col :xs="24" :sm="12">
										<el-form-item label="公司地址">
											<span>{{ props.row.dist_address }}</span>
										</el-form-item>
										<el-form-item label="联系方式">
											<span>{{ props.row.dist_contact }}</span>
										</el-form-item>
										<el-form-item label="组织架构">
											<span>{{ props.row.dist_organization }}</span>
										</el-form-item>
										<el-form-item label="备注">
											<span>{{ props.row.remark }}</span>
										</el-form-item>
									</el-col>
								</el-row>
							</el-form>
						</template>
					</el-table-column>
					<el-table-column label="经销商" prop="dist_name" show-overflow-tooltip />
					<el-table-column label="级别" prop="dist_level" />
					<el-table-column label="省份/地区" prop="dist_area" />
					<el-table-column label="授权品牌" prop="dist_authorize" show-overflow-tooltip />
					<el-table-column width="150" label="操作" align="center">
						<template slot-scope="scope">
							<el-button-group>
								<el-button size="mini" @click="updateData(scope.$index, scope.row)">编 辑</el-button>
								<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
							</el-button-group>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<el-row class="paginator hidden-sm-and-down">
					<el-pagination
						background
						:current-page.sync="currentPage"
						layout="total, sizes, prev, pager, next"
						:total="totalItems"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="pageSize"
						@current-change="handleCurrentChange"
						@size-change="handleSizeChange"
					/>
				</el-row>

				<el-row class="paginator hidden-sm-and-up">
					<el-pagination
						background
						:current-page.sync="currentPage"
						layout="prev, pager, next"
						:total="totalItems"
						:page-size="pageSize"
						@current-change="handleCurrentChange"
						@size-change="handleSizeChange"
						prev-text="上一页"
						next-text="下一页"
						:pager-count="5"
					/>
				</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增经销商"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="公司名称" prop="dist_name"><el-input v-model="formAddData.dist_name" clearable /></el-form-item>
				<el-form-item label="省份/地区" prop="dist_area">
					<el-select v-model="formAddData.dist_area" filterable placeholder="省份/地区" multiple allow-create default-first-option>
						<el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="经销商级别" prop="dist_level"><el-input v-model="formAddData.dist_level" clearable /></el-form-item>
				<el-form-item label="授权品牌" prop="dist_authorize">
					<el-input v-model="formAddData.dist_authorize" clearable type="textarea" maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="公司地址" prop="dist_address"><el-input v-model="formAddData.dist_address" clearable /></el-form-item>
				<el-form-item label="联系方式" prop="dist_contact"><el-input v-model="formAddData.dist_contact" clearable /></el-form-item>
				<el-form-item label="组织架构" prop="dist_organization">
					<el-input v-model="formAddData.dist_organization" type="textarea" clearable maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="备注" prop="dist_remark">
					<el-input v-model="formAddData.dist_remark" type="textarea" clearable maxlength="250" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改经销商"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="公司名称" prop="dist_name"><el-input v-model="formUpdateData.dist_name" clearable /></el-form-item>
				<el-form-item label="省份/城市" prop="province"><el-input v-model="formUpdateData.dist_area" clearable /></el-form-item>
				<el-form-item label="经销商级别" prop="dist_level"><el-input v-model="formUpdateData.dist_level" clearable /></el-form-item>
				<el-form-item label="授权品牌" prop="dist_authorize"><el-input v-model="formUpdateData.dist_authorize" clearable /></el-form-item>
				<el-form-item label="公司地址" prop="dist_address"><el-input v-model="formUpdateData.dist_address" clearable /></el-form-item>
				<el-form-item label="联系方式" prop="dist_contact"><el-input v-model="formUpdateData.dist_contact" clearable /></el-form-item>
				<el-form-item label="组织架构" prop="dist_organization">
					<el-input v-model="formUpdateData.dist_organization" type="textarea" clearable maxlength="250" show-word-limit />
				</el-form-item>
				<el-form-item label="备注" prop="dist_remark">
					<el-input v-model="formUpdateData.dist_remark" type="textarea" clearable maxlength="250" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateData')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { exportToExcel } from '@/api/utils.js';
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			fileList: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				dist_name: '',
				dist_area: '',
				dist_level: '',
				dist_authorize: '',
				dist_address: '',
				dist_contact: '',
				dist_organization: '',
				dist_remark: ''
			},
			formUpdateData: {
				pk: '',
				dist_name: '',
				dist_area: '',
				dist_level: '',
				dist_authorize: '',
				dist_address: '',
				dist_contact: '',
				dist_organization: '',
				dist_remark: ''
			},
			//新增和更新表单的验证规则
			rules: {
				dist_name: [{ required: true, message: '请填写经销商公司名称', trigger: 'change' }],
				dist_area: [{ required: true, message: '请选省份或地区', trigger: 'blur' }],
				dist_level: [{ required: true, message: '请选择经销级别', trigger: 'change' }]
			},
			formLabelWidth: '100px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false,
			provinceOptions: [
				{
					value: '北京',
					label: '北京'
				},
				{
					value: '天津',
					label: '天津'
				},
				{
					value: '上海',
					label: '上海'
				},
				{
					value: '重庆',
					label: '重庆'
				},
				{
					value: '河北',
					label: '河北'
				},
				{
					value: '山西',
					label: '山西'
				},
				{
					value: '辽宁',
					label: '辽宁'
				},
				{
					value: '吉林',
					label: '吉林'
				},
				{
					value: '黑龙江',
					label: '黑龙江'
				},
				{
					value: '江苏',
					label: '江苏'
				},
				{
					value: '浙江',
					label: '浙江'
				},
				{
					value: '安徽',
					label: '安徽'
				},
				{
					value: '福建',
					label: '福建'
				},
				{
					value: '江西',
					label: '江西'
				},
				{
					value: '山东',
					label: '山东'
				},
				{
					value: '河南',
					label: '河南'
				},
				{
					value: '湖北',
					label: '湖北'
				},
				{
					value: '湖南',
					label: '湖南'
				},
				{
					value: '广东',
					label: '广东'
				},
				{
					value: '甘肃',
					label: '甘肃'
				},
				{
					value: '四川',
					label: '四川'
				},
				{
					value: '贵州',
					label: '贵州'
				},
				{
					value: '海南',
					label: '海南'
				},
				{
					value: '云南',
					label: '云南'
				},
				{
					value: '青海',
					label: '青海'
				},
				{
					value: '陕西',
					label: '陕西'
				},
				{
					value: '广西',
					label: '广西'
				},
				{
					value: '西藏',
					label: '西藏'
				},
				{
					value: '宁夏',
					label: '宁夏'
				},
				{
					value: '新疆',
					label: '新疆'
				},
				{
					value: '内蒙',
					label: '内蒙'
				},
				{
					value: '澳门',
					label: '澳门'
				},
				{
					value: '香港',
					label: '香港'
				},
				{
					value: '台湾',
					label: '台湾'
				}
			]
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/distributor/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						ga_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
			this.fileList = [];
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					fd.append('ga_pk', that.$route.params.id);
					this.$axios({
						url: '/apim/add/distributor/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							// console.log(res.data);
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.resetForm('formAddData');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增或更新数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.fileList = [];
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					this.$axios({
						url: '/apim/update/distributor/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/distributor/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.getData(that.keywords, that.currentPage, that.pageSize);
					} else {
						this.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//过滤器-产品分类：医用和民用
		filterTag(value, row) {
			return row.type === value;
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//导出excel
		exportExcel() {
			exportToExcel('distributor', '经销商', this);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
