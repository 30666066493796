<template>
	<div>
		<el-row :gutter="10">
			<el-col :sm="16" :xs="24">
				<el-card>
					<el-calendar v-model="selectedDate">
						<template slot="dateCell" slot-scope="{ date, data }">
							<div style="font-size: 13px; ">
								{{
									data.day
										.split('-')
										.slice(2)
										.join('-')
								}}
								<br />
								<el-tooltip v-if="dealMyDate(data.day) !== ''" class="item" effect="dark"
									placement="right">
									<div slot="content" style="">{{ dealMyDate(data.day) }}</div>
									<div>{{ dealMyDate(data.day).slice(0, 8) }}...</div>
								</el-tooltip>
							</div>
						</template>
					</el-calendar>
				</el-card>
			</el-col>
			<el-col :sm="8" :xs="24">
				<el-row style="margin-bottom: 15px;">
					<el-col v-if="Object.keys(taskDetails).length == 0"><el-button type="primary"
							@click="mainTaskAddDialog()">写日志</el-button></el-col>
					<el-col v-else>
						<el-card class="box-card">
							<div slot="header" class="clearfix">
								<el-col :span="12"><span>任务详情</span></el-col>
								<el-col :span="12" style="text-align: right;">
									<el-button-group>
										<el-button size="small" type="primary" icon="el-icon-edit"
											@click="updateMainTaskDialog(taskDetails)">编辑</el-button>
										<el-button size="small" type="danger" icon="el-icon-delete"
											@click="deleteData(taskDetails)">删除</el-button>
									</el-button-group>
								</el-col>
							</div>
							<div class="text item">
								日期：{{ taskDetails.task_time }}
								<br />
								行程：{{ taskDetails.itinerary }}
								<br />
								拜访客户数：{{ taskDetails.customer_visit_num }}
								<br />
								主要任务：
								<br />
								<ol>
									<li v-for="item in taskDetails.subtasks" :key="item.pk">
										{{ item.maintask_type }} - {{ item.subtask_type }}
										<br />
										{{ item.description }}
									</li>
								</ol>
								相关文件：
								<el-link v-for="(item, idx) in taskDetails.files" :key="item.id" icon="el-icon-download"
									:underline="false" type="primary" :href="$domainPrefix + item.file" target="_blank"
									style="display: block;">
									文件 {{ idx+1 }}
								</el-link>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</el-col>
		</el-row>

		<!-- 新增日志表单 -->
		<el-dialog title="新增日志" :visible.sync="formMainTaskAddVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :fullscreen="ifFullScreen">
			<el-form ref="formMainTaskAdd" :model="formMainTaskAddData" label-width="80px" :rules="rules" size="medium">
				<el-form-item label="日期">
					<el-date-picker v-model="formMainTaskAddData.task_time" type="date" placeholder="选择日期"
						style="width: 100%;" readonly value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="行程" prop="itinerary"><el-input v-model="formMainTaskAddData.itinerary"
						clearable /></el-form-item>
				<el-form-item label="拜访客户数" prop="customer_visit_num">
					<el-input-number v-model="formMainTaskAddData.customer_visit_num" :min="0" :max="99"
						label="描述文字"></el-input-number>
				</el-form-item>

				<el-form-item label="相关文件">
					<el-upload action="none" list-type="picture" :on-change="handleChange" :on-remove="handleRemove"
						:auto-upload="false" accept="image/*,.pdf,.doc,.docx" :file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">可上传图片\pdf文件\word文档</div>
					</el-upload>
				</el-form-item>

				<el-form-item label="子任务"><el-button type="success" size="small"
						@click="addTab('formMainTaskAddData')">新增子任务</el-button></el-form-item>

				<div v-for="(subtask, index) in formMainTaskAddData.subtasks" :key="index">
					<el-divider />
					<el-form-item label="任务类型" :prop="'subtasks.' + index + '.task_type'"
						:rules="{ required: true, message: '任务类型不能为空', trigger: 'blur' }">
						<el-cascader v-model="subtask.task_type" :options="taskTypeOptions" />
					</el-form-item>
					<el-form-item label="任务描述" :prop="'subtasks.' + index + '.description'"
						:rules="{ required: true, message: '任务描述不能为空', trigger: 'blur' }">
						<el-input v-model="subtask.description" type="textarea" clearable maxlength="500"
							show-word-limit autosize />
						<el-button type="danger" size="small"
							@click="removeTab(subtask, 'formMainTaskAddData')">删除</el-button>
					</el-form-item>
				</div>
				<el-divider />
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formMainTaskAdd')">取 消</el-button>
				<el-button type="primary" @click="submitMainTaskAddForm('formMainTaskAdd')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 修改日志表单 -->
		<el-dialog title="修改日志" :visible.sync="formMainTaskUpdateVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :fullscreen="ifFullScreen">
			<el-form ref="formMainTaskUpdate" :model="formMainTaskUpdateData" label-width="80px" :rules="rules">
				<el-form-item label="日期">
					<el-date-picker v-model="formMainTaskUpdateData.task_time" type="date" placeholder="选择日期"
						style="width: 100%;" readonly value-format="yyyy-MM-dd" />
				</el-form-item>
				<el-form-item label="行程" prop="itinerary"><el-input v-model="formMainTaskUpdateData.itinerary"
						clearable /></el-form-item>
				<el-form-item label="拜访客户数" prop="customer_visit_num">
					<el-input-number v-model="formMainTaskUpdateData.customer_visit_num" :min="0" :max="99"
						label="描述文字"></el-input-number>
				</el-form-item>
				<el-form-item label="相关文件">
					<el-upload action="none" list-type="picture" :on-change="handleChangeUpdate"
						:on-remove="handleRemoveUpdate" :auto-upload="false" accept="image/*,.pdf,.doc,.docx"
						:file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">可上传图片\pdf文件\word文档</div>
					</el-upload>
				</el-form-item>

				<el-form-item label="子任务"><el-button type="success"
						@click="addTab('formMainTaskUpdateData')">新增子任务</el-button></el-form-item>

				<div v-for="(subtask, index) in formMainTaskUpdateData.subtasks" :key="index">
					<el-divider />
					<el-form-item label="任务类型" :prop="'subtasks.' + index + '.task_type'"
						:rules="{ required: true, message: '任务类型不能为空', trigger: 'blur' }">
						<el-cascader v-model="subtask.task_type" :options="taskTypeOptions" />
					</el-form-item>
					<el-form-item label="任务描述" :prop="'subtasks.' + index + '.description'"
						:rules="{ required: true, message: '任务描述不能为空', trigger: 'blur' }">
						<el-input v-model="subtask.description" type="textarea" clearable maxlength="250"
							show-word-limit />
						<el-button type="danger" @click="removeTab(subtask, 'formMainTaskUpdateData')">删除</el-button>
					</el-form-item>
				</div>
				<el-divider />
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formMainTaskUpdate')">取 消</el-button>
				<el-button type="primary" @click="submitMainTaskUpdateForm('formMainTaskUpdate')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				formMainTaskAddData: {
					task_time: '',
					itinerary: '',
					customer_visit_num: 0,
					subtasks: [{
						pk: '',
						task_type: '',
						maintask_type: '',
						subtask_type: '',
						description: ''
					}],
					files: []
				},
				formMainTaskUpdateData: {
					pk: '',
					task_time: '',
					itinerary: '',
					customer_visit_num: '',
					subtasks: [],
					files: []
				},
				fileList: [],
				selectedDate: new Date(),
				taskList: [],
				taskDetails: {},
				formMainTaskAddVisible: false,
				formMainTaskUpdateVisible: false,
				ifFullScreen: false,
				taskTypeOptions: [],
				rules: {
					itinerary: [{
						required: true,
						message: '请填写日程',
						trigger: 'change'
					}]
				}
			};
		},
		computed: {
			formatDate() {
				let date = this.selectedDate;
				let date_value = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
				return date_value;
			}
		},
		watch: {
			selectedDate(val, oldVal) {
				let presentDate = val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate();
				this.$nextTick(() => {
					this.getPresentDaySubTaskList(presentDate);
				});
			}
		},
		created: function() {
			this.getPresentMonthTaskList(this.selectedDate);
			this.getPresentDaySubTaskList(this.selectedDate);
			this.$nextTick(() => {
				// 点击前一个月
				let prevBtn = document.querySelector(
					'.el-calendar__button-group .el-button-group>button:nth-child(1)');
				prevBtn.addEventListener('click', () => {
					this.getPresentMonthTaskList(this.selectedDate);
				});

				//点击下一个月
				let nextBtn = document.querySelector(
					'.el-calendar__button-group .el-button-group>button:nth-child(3)');
				nextBtn.addEventListener('click', () => {
					this.getPresentMonthTaskList(this.selectedDate);
				});

				//点击今天
				let todayBtn = document.querySelector(
					'.el-calendar__button-group .el-button-group>button:nth-child(2)');
				todayBtn.addEventListener('click', () => {
					this.getPresentMonthTaskList(this.selectedDate);
				});
			});
		},
		mounted() {
			let that = this;
			that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
			that.getTaskTypeOptions();
		},
		methods: {
			//新增子任务输入框
			addTab(formdata) {
				this.$data[formdata].subtasks.push({
					pk: '',
					task_type: '',
					maintask_type: '',
					subtask_type: '',
					description: ''
				});
			},
			//删除子任务输入框
			removeTab(item, formdata) {
				let index = this.$data[formdata].subtasks.indexOf(item);
				if (index !== 0) {
					this.$data[formdata].subtasks.splice(index, 1);
				}
			},
			//显示新增日志表单
			mainTaskAddDialog() {
				this.$nextTick(() => {
					this.formMainTaskAddVisible = true;
					this.formMainTaskAddData.task_time = this.formatDate;
					this.fileList = [];
				});
			},
			//处理日历视图显示数据
			dealMyDate(v) {
				let len = this.taskList.length;
				let res = '';
				for (let i = 0; i < len; i++) {
					if (this.taskList[i].task_time == v) {
						res = this.taskList[i].itinerary;
						break;
					}
				}
				return res;
			},
			// 获取工作任务分类列表
			getTaskTypeOptions() {
				let that = this;
				this.$axios
					.get('/api/get/tasktypeoption/')
					.then(function(response) {
						that.taskTypeOptions = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//按日期获取当月的工作任务列表
			getPresentMonthTaskList(presentDate) {
				let that = this;
				this.$axios
					.get('/api/get/presentmonthtasklist/', {
						params: {
							current_date: presentDate
						}
					})
					.then(function(response) {

						that.taskList = response.data;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//按日期获取当日的任务详情及子任务列表
			getPresentDaySubTaskList(presentDate) {
				let that = this;
				this.$axios
					.get('/api/get/presentdaysubtasklist/', {
						params: {
							present_date: presentDate
						}
					})
					.then(function(response) {
						if (response.data.code == '401') {
							that.logout();
						} else {
							that.taskDetails = response.data;
						}

					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//新增 - 新文件上传
			handleChange(file, fileList) {
				this.formMainTaskAddData.files = fileList;
				// console.log(file);
				// console.log(fileList);
			},
			//新增 - 文件列表变动
			handleRemove(file, fileList) {
				this.formMainTaskAddData.files = fileList;
				// console.log(file);
				// console.log(fileList);
			},
			//修改 - 新文件上传
			handleChangeUpdate(file, fileList) {
				this.formMainTaskUpdateData.files = fileList;
				// console.log(file);
				// console.log(fileList);
			},
			//修改 - 文件列表变动
			handleRemoveUpdate(file, fileList) {
				this.formMainTaskUpdateData.files = fileList;
			},
			//重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.formMainTaskUpdateVisible = false;
				this.formMainTaskAddVisible = false;
				this.fileList = [];
				this.formMainTaskAddData.subtasks = [{
					pk: '',
					task_type: '',
					maintask_type: '',
					subtask_type: '',
					description: ''
				}];
				this.formMainTaskUpdateData.subtasks = [];
			},
			//新增数据-提交
			submitMainTaskAddForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						var that = this;
						let fd = new FormData();
						let formData = this.formMainTaskAddData;
						//添加表单数据
						fd.append('task_time', formData.task_time);
						fd.append('itinerary', formData.itinerary);
						fd.append('customer_visit_num', formData.customer_visit_num)
						fd.append('subtasks', JSON.stringify(formData.subtasks));
						//单独添加文件数据
						for (let item in formData.files) {
							fd.append('fileList', formData.files[item].raw, formData.files[item].name);
						}
						this.$axios({
								url: '/api/add/maintask/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								// console.log(res.data);
								if (res.data.code == '200') {
									that.reload();
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//更新数据 - 对话框
			updateMainTaskDialog(data) {
				//解除双向绑定
				this.formMainTaskUpdateData = JSON.parse(JSON.stringify(data));
				let tempData = this.formMainTaskUpdateData.files;
				//拼接上传插件预览需要的对象数组
				for (let item in tempData) {
					let newObj = Object.create({
						name: tempData[item].file.replace('files/', ''),
						url: this.$domainPrefix + tempData[item].file,
						id: tempData[item].id
					});
					this.fileList.push(newObj);
				}
				this.formMainTaskUpdateVisible = true;
			},
			//更新数据 - 提交表单
			submitMainTaskUpdateForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						let that = this;
						let fd = new FormData();
						let formData = this.formMainTaskUpdateData;
						//添加表单数据
						fd.append('pk', formData.pk);
						fd.append('task_time', formData.task_time);
						fd.append('itinerary', formData.itinerary);
						fd.append('customer_visit_num', formData.customer_visit_num)
						fd.append('subtasks', JSON.stringify(formData.subtasks));
						//单独添加文件数据， fileList 为新增的文件，existFile为之前保存的文件
						for (let item in formData.files) {
							if (formData.files[item].raw) {
								fd.append('fileList', formData.files[item].raw, formData.files[item].name);
							} else {
								fd.append('existFile', formData.files[item].id);
							}
						}
						this.$axios({
								url: '/api/update/maintask/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								// console.log(res.data);
								if (res.data.code == '200') {
									that.reload();
									that.formMainTaskUpdateVisible = false;
									that.fileList = [];
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					} else {
						return false;
					}
				});
			},
			//删除数据 - 对话框
			deleteData(data) {
				let deleteItemPk = data.pk;
				this.$confirm('此操作将永久删除该记录并无法恢复, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					})
					.then(() => {
						this.submitDeleteData(deleteItemPk);
					})
					.catch(() => {});
			},
			//删除数据-提交
			submitDeleteData(dataPk) {
				let that = this;
				let fd = new FormData();
				fd.append('pk', dataPk);
				this.$axios({
						url: '/api/delete/maintask/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(function(res) {
						// console.log(res.data);
						if (res.data.code == '200') {
							that.reload();
						} else {
							that.$message({
								message: res.data.message,
								type: 'warning'
							});
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			}
		}
	};
</script>

<style>
	/deep/ .el-calendar-table .el-calendar-day {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 4px;
		overflow: hidden;
		height: 20px;
	}

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: '';
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: 100%;
	}

	.is-selected {
		color: #f8a535;
		font-size: 18px;
		margin-top: 5px;
	}

	.el-calendar-table:not(.is-range) td.next,
	.el-calendar-table:not(.is-range) td.prev {
		pointer-events: none;
	}
</style>