<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable style="margin:5px" @change="Search()">
						<el-button slot="append" icon="el-icon-search" @click="Search()"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success" @click="addData()">新增设备设施</el-button>
					<!-- <el-button style="margin: 5px;" size="mini" @click="exportExcel()">导出</el-button> -->
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-form label-position="left" class="demo-table-expand">
							<el-row>
								<el-col :xs="24" :sm="12">
									<el-form-item label="使用科室" >
										<span>{{ props.row.equip_dept }}</span>
									</el-form-item>
									<el-form-item label="设备名称">
										<span>{{ props.row.equip_name }}</span>
									</el-form-item>
									
									<el-form-item label="设备数量">
										<span>{{ props.row.equip_quantity }}</span>
									</el-form-item>
									<el-form-item label="设备品牌">
										<span>{{ props.row.equip_brand }}</span>
									</el-form-item>
									<el-form-item label="设备类型">
										<span>{{ props.row.equip_type }}</span>
									</el-form-item>
									<el-form-item label="设备型号">
										<span>{{ props.row.equip_model }}</span>
									</el-form-item>

								</el-col>
								<el-col :xs="24" :sm="12">
									<el-form-item label="出厂日期">
										<span>{{ props.row.equip_DOM }}</span>
									</el-form-item>
									<el-form-item label="设备备注">
										<span>{{ props.row.equip_remark }}</span>
									</el-form-item>
									<el-form-item label="产品兼容性">
										<span>{{ props.row.equip_compatibility }}</span>
									</el-form-item>
									<el-form-item label="处理记录">
										<span>{{ props.row.equip_CS_record }}</span>
									</el-form-item>
									<el-form-item label="上报人">
										<span>{{ props.row.equip_user__person_name }}</span>
									</el-form-item>
									<el-form-item label="创建时间">
										<span>
											{{
												props.row.create_date
													.split('T')
													.slice(0, 1)
													.join('')
											}}
										</span>
									</el-form-item>
									<el-form-item label="更新时间">
										<span>
											{{
												props.row.update_date
													.split('T')
													.slice(0, 1)
													.join('')
											}}
										</span>
									</el-form-item>
								</el-col>

							</el-row>
						</el-form>
					</template>
				</el-table-column>
				<el-table-column label="设备名称" prop="equip_name" show-overflow-tooltip sortable/>
				<el-table-column label="使用科室" prop="equip_dept" sortable />
				<el-table-column label="品牌" prop="equip_brand" show-overflow-tooltip sortable />
				<el-table-column label="型号" prop="equip_model" show-overflow-tooltip sortable/>
				<el-table-column label="数量" prop="equip_quantity"  />
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" @click="updateData(scope.$index, scope.row)">编 辑</el-button>
							<el-button size="mini" type="danger" @click="deleteData(scope.$index, scope.row)">删 除</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="total, sizes, prev, pager, next"
					:total="totalItems"
					:page-sizes="[10, 20, 50, 100]"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
				/>
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination
					background
					:current-page.sync="currentPage"
					layout="prev, pager, next"
					:total="totalItems"
					:page-size="pageSize"
					@current-change="handleCurrentChange"
					@size-change="handleSizeChange"
					prev-text="上一页"
					next-text="下一页"
					:pager-count="5"
				/>
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 新增数据表单 -->
		<el-dialog
			title="新增设备"
			:visible.sync="formAddDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:width="'60%'"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formAddData" :rules="rules" :model="formAddData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="使用科室" prop="equip_dept"><el-input v-model="formAddData.equip_dept" clearable /></el-form-item>
				<el-form-item label="设备名称" prop="equip_name"><el-input v-model="formAddData.equip_name" clearable /></el-form-item>
				<el-form-item label="设备数量" prop="equip_quantity">
					<el-input-number v-model="formAddData.equip_quantity" :min="1" :max="1000" label="描述文字" size="small" ></el-input-number>
				</el-form-item>
				<el-form-item label="设备品牌" prop="equip_brand"><el-input v-model="formAddData.equip_brand" clearable /></el-form-item>
				<el-form-item label="设备类型" prop="equip_type"><el-input v-model="formAddData.equip_type" clearable /></el-form-item>
				<el-form-item label="设备型号" prop="equip_model"><el-input v-model="formAddData.equip_model" clearable /></el-form-item>
				<el-form-item label="出厂日期" prop="equip_DOM">
					<el-date-picker v-model="formAddData.equip_DOM" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="设备备注" prop="equip_remark">
					<el-input v-model="formAddData.equip_remark" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
				<el-form-item label="与鲁沃夫产品兼容性" prop="equip_compatibility">
					<el-input v-model="formAddData.equip_compatibility" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
				<el-form-item label="兼容性处理记录" prop="equip_CS_record">
					<el-input v-model="formAddData.equip_CS_record" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formAddData')">取 消</el-button>
				<el-button type="danger" @click="submitAddForm('formAddData')">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 更新数据表单 -->
		<el-dialog
			title="修改设备信息"
			:visible.sync="formUpdateDialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			:fullscreen="ifFullScreen"
		>
			<el-form ref="formUpdateData" :rules="rules" :model="formUpdateData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="使用科室" prop="equip_dept"><el-input v-model="formUpdateData.equip_dept" clearable /></el-form-item>
				<el-form-item label="设备名称" prop="equip_name"><el-input v-model="formUpdateData.equip_name" clearable /></el-form-item>
				<el-form-item label="设备数量" prop="equip_quantity">
					<el-input-number v-model="formUpdateData.equip_quantity" :min="1" :max="1000" label="描述文字" size="small" ></el-input-number>
				</el-form-item>
				<el-form-item label="设备品牌" prop="equip_brand"><el-input v-model="formUpdateData.equip_brand" clearable /></el-form-item>
				<el-form-item label="设备类型" prop="equip_type"><el-input v-model="formUpdateData.equip_type" clearable /></el-form-item>
				<el-form-item label="设备型号" prop="equip_model"><el-input v-model="formUpdateData.equip_model" clearable /></el-form-item>
				<el-form-item label="出厂日期" prop="equip_DOM">
					<el-date-picker v-model="formUpdateData.equip_DOM" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
				</el-form-item>
				<el-form-item label="设备备注" prop="equip_remark">
					<el-input v-model="formUpdateData.equip_remark" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
				<el-form-item label="与鲁沃夫产品兼容性" prop="equip_compatibility">
					<el-input v-model="formUpdateData.equip_compatibility" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
				<el-form-item label="兼容性处理记录" prop="equip_CS_record">
					<el-input v-model="formUpdateData.equip_CS_record" type="textarea" clearable maxlength="1000" show-word-limit />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('formUpdateData')">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('formUpdateData')">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { exportToExcel } from '@/api/utils.js';
export default {
	inject: ['reload', 'logout'],
	data() {
		return {
			tableData: [],
			fileList: [],
			keywords: '',
			formAddDialogVisible: false,
			formUpdateDialogVisible: false,
			ifFullScreen: false,
			formAddData: {
				pk: '',
				equip_dept: '',
				equip_name: '',
				equip_quantity: '',
				equip_type: '',
				equip_model: '',
				equip_brand: '',
				equip_DOM: '',
				equip_remark: '',
				equip_compatibility: '',
				equip_CS_record: ''
			},
			formUpdateData: {
				pk: '',
				equip_dept: '',
				equip_name: '',
				equip_quantity: '',
				equip_type: '',
				equip_model: '',
				equip_brand: '',
				equip_DOM: '',
				equip_remark: '',
				equip_compatibility: '',
				equip_CS_record: ''
			},
			//新增和更新表单的验证规则
			rules: {
				equip_dept: [{ required: true, message: '请填写使用部门名称', trigger: 'change' }],
				equip_name: [{ required: true, message: '请填写设备名称', trigger: 'blur' }],
				equip_quantity: [{ required: true, message: '请选择设备数量', trigger: 'change' }]
			},
			formLabelWidth: '100px',
			totalItems: 100,
			currentPage: 1,
			pageSize: 20,
			deleteItemPk: '',
			loading: false
		};
	},
	methods: {
		//通用函数-获取数据
		getData(keywords, currentPage, pageSize) {
			var that = this;
			that.loading = true;
			this.$axios
				.get('/apim/get/equipment/', {
					params: {
						keywords: keywords,
						currentpage: currentPage,
						pagesize: pageSize,
						cm_pk: that.$route.params.id
					}
				})
				.then(function(response) {
					if (response.data.code == '401') {
						that.logout();
					} else {
						that.tableData = response.data.data;
						that.totalItems = response.data.totalItemNum;
						that.loading = false;
					}
				})
				.catch(function(error) {
					console.log(error);
					that.loading = false;
				});
		},
		//通用功能 - 关键字搜索
		Search() {
			var that = this;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//新增数据-对话框
		addData() {
			this.formAddDialogVisible = true;
			this.fileList = [];
		},
		//新增数据-提交
		submitAddForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formAddData;
					//添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					fd.append('cm_pk', that.$route.params.id);
					this.$axios({
						url: '/apim/add/equipment/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							// console.log(res.data);
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.resetForm('formAddData');
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//新增或更新数据 - 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.formAddDialogVisible = false;
			this.formUpdateDialogVisible = false;
			this.fileList = [];
		},
		//更新数据 - 对话框
		updateData(index, row) {
			//解除双向绑定
			this.formUpdateData = JSON.parse(JSON.stringify(row));
			this.formUpdateDialogVisible = true;
		},
		//更新数据-提交
		submitUpdateForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var that = this;
					let fd = new FormData();
					let formData = this.formUpdateData;
					//遍历添加表单数据
					for (let key in formData) {
						fd.append(key, formData[key]);
					}
					if(formData.equip_DOM == null) {
						fd.set('equip_DOM', '');
					}
					this.$axios({
						url: '/apim/update/equipment/',
						method: 'post',
						data: fd,
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					})
						.then(function(res) {
							if (res.data.code == '200') {
								that.getData(that.keywords, that.currentPage, that.pageSize);
								that.formUpdateDialogVisible = false;
							} else {
								that.$message({
									message: res.data.message,
									type: 'warning'
								});
							}
						})
						.catch(function(error) {
							console.log(error);
						});
				} else {
					return false;
				}
			});
		},
		//删除数据 - 对话框
		deleteData(index, row) {
			let deleteItemPk = JSON.parse(JSON.stringify(row)).pk;
			this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.submitDeleteData(deleteItemPk);
				})
				.catch(() => {});
		},
		//删除数据-提交
		submitDeleteData(dataPk) {
			var that = this;
			var fd = new FormData();
			fd.append('pk', dataPk);
			this.$axios({
				url: '/apim/delete/equipment/',
				method: 'post',
				data: fd,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
				.then(function(res) {
					// console.log(res.data);
					if (res.data.code == '200') {
						that.getData(that.keywords, that.currentPage, that.pageSize);
					} else {
						that.$message({
							message: res.data.message,
							type: 'warning'
						});
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		//过滤器-产品分类：医用和民用
		filterTag(value, row) {
			return row.type === value;
		},

		//分页跳转
		handleCurrentChange(val) {
			var that = this;
			this.currentPage = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//每页显示条数更改
		handleSizeChange(val) {
			var that = this;
			this.pageSize = val;
			this.getData(that.keywords, that.currentPage, that.pageSize);
		},
		//导出excel
		exportExcel() {
			exportToExcel('distributor', '经销商', this);
		}
	},
	mounted() {
		var that = this;
		this.getData('', that.currentPage, that.pageSize);
		that.ifFullScreen = document.body.clientWidth > 720 ? false : true;
	}
};
</script>

<style></style>
