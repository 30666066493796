<template>
	<div v-loading="loading" element-loading-text="下载中,请稍后" element-loading-spinner="el-icon-loading">
		<el-row>
			<el-row :gutter="15">
				<el-col :xs="24" :sm="8" :md="8">
					<el-input v-model="keywords" size="mini" placeholder="输入关键字搜索" maxlength="30" clearable
						style="margin:5px" @clear="fetchData">
						<el-button slot="append" icon="el-icon-search" @click="fetchData"></el-button>
					</el-input>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" style="margin-bottom: 20px;">
					<el-button style="margin: 5px;" size="mini" type="success"
						@click="openDialog()" :disabled="$route.query.id == undefined">新增医院供货记录</el-button>
				</el-col>
			</el-row>

			<el-table :data="tableData" style="width: 100%; " stripe>
				<el-table-column label="销售方式" prop="supplyment_type" show-overflow-tooltip sortable />
				<el-table-column label="二级代理商名称" prop="supplyment_secondary_agent" sortable />
				<el-table-column label="供货方式" prop="supplyment_method" show-overflow-tooltip sortable />
				<el-table-column label="指定配送商名称" prop="supplyment_provider" show-overflow-tooltip sortable />
				<el-table-column label="备注(联系方式)" prop="supplyment_remark" />
				<el-table-column label="填报人" prop="supplyment_user__person_name" />
				<el-table-column label="最后更新" prop="update_date">
					<template slot-scope="scope">
						{{
							scope.row.update_date
								.split('.')
								.slice(0, 1)
								.join('')
								.replace('T',' ')
						}}
					</template>
				</el-table-column>
				<el-table-column label="变更记录" align="center">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="showHistory(scope.row.id)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column width="150" label="操作" align="center">
					<template slot-scope="scope">
						<el-button-group>
							<el-button size="mini" icon="el-icon-edit" @click="openDialog(scope.row)">改</el-button>
							<el-button size="mini" icon="el-icon-delete" type="danger"
								@click="deleteproductInfo(scope.row)">删
							</el-button>
						</el-button-group>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-row class="paginator hidden-sm-and-down">
				<el-pagination background :current-page.sync="currentPage" layout="total, sizes, prev, pager, next"
					:total="total" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
					@current-change="handleCurrentChange" @size-change="handleSizeChange" />
			</el-row>

			<el-row class="paginator hidden-sm-and-up">
				<el-pagination background :current-page.sync="currentPage" layout="prev, pager, next" :total="total"
					:page-size="pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange"
					prev-text="上一页" next-text="下一页" :pager-count="5" />
			</el-row>
			<!-- </el-card> -->
		</el-row>

		<!-- 数据处理表单 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false"
			:close-on-press-escape="false" :show-close="false" :width="'60%'" :fullscreen="ifFullScreen">
			<el-form ref="form" :rules="rules" :model="formData" :label-width="formLabelWidth" size="medium">
				<el-form-item label="销售方式" prop="supplyment_type">
						<el-select v-model="formData.supplyment_type" filterable placeholder="请选择销售方式">
							<el-option v-for="item in supplymentTypeOption" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>						
				</el-form-item>
				<el-form-item label="二级代理商名称" prop="supplyment_secondary_agent"><el-input
						v-model="formData.supplyment_secondary_agent" clearable /></el-form-item>
				<el-form-item label="供货方式" prop="supplyment_method">
						<el-select v-model="formData.supplyment_method" filterable placeholder="请选择供货方式">
							<el-option v-for="item in supplymentMethodOption" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>				
				</el-form-item>
				<el-form-item label="指定配送商名称" prop="supplyment_provider"><el-input
						v-model="formData.supplyment_provider" clearable /></el-form-item>
				<el-form-item label="备注" prop="supplyment_remark"><el-input v-model="formData.supplyment_remark"
						clearable /></el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm('form')">取 消</el-button>
				<el-button type="danger" @click="saveData">提 交</el-button>
			</div>
		</el-dialog>
		<!-- 历史记录弹窗 -->
		<el-dialog title="历史记录" :visible.sync="dialogHistoryVisible" :width="'80%'" :fullscreen="ifFullScreen">
			<el-table :data="tableHistoryData">
				<el-table-column label="操作时间" prop="history_date" />
				<el-table-column label="操作" prop="history_type" />
				<el-table-column label="数据">
					<el-table-column label="销售方式" prop="supplyment_type" show-overflow-tooltip sortable />
					<el-table-column label="二级代理商名称" prop="supplyment_secondary_agent" sortable />
					<el-table-column label="供货方式" prop="supplyment_method" show-overflow-tooltip sortable />
					<el-table-column label="指定配送商名称" prop="supplyment_provider" show-overflow-tooltip sortable />
					<el-table-column label="备注(联系方式)" prop="supplyment_remark" />
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload', 'logout'],
		data() {
			return {
				tableData: [],
				tableHistoryData: [],
				keywords: '',
				dialogVisible: false,
				dialogHistoryVisible: false,
				ifFullScreen: false,
				formData: {
					id: null,
					supplyment_type: '',
					supplyment_secondary_agent: '',
					supplyment_method: '',
					supplyment_provider: '',
					supplyment_remark: '',
					hospital_id: null
				},
				//新增和更新表单的验证规则
				rules: {
					supplyment_type: [{
						required: true,
						message: '请填写销售方式',
						trigger: 'change'
					}],
					supplyment_method: [{
						required: true,
						message: '请填写供货方式',
						trigger: 'change'
					}],

				},
				supplymentTypeOption: [{
						"label": "总代理",
						"value": "总代理"
					},
					{
						"label": "二级代理商",
						"value": "二级代理商"
					},
				],
				supplymentMethodOption: [{
						"label": "总代理直销",
						"value": "总代理直销"
					},
					{
						"label": "二级代理直销",
						"value": "二级代理直销"
					},
					{
						"label": "医院指定派送商",
						"value": "医院指定派送商"
					},
				],				
				dialogTitle: "新增数据",
				formLabelWidth: '130px',
				total: 100,
				currentPage: 1,
				pageSize: 20,
				loading: false
			};
		},
		methods: {
			// 重置表单
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogVisible = false;
			},
			//获取数据
			fetchData() {
				let that = this;
				this.loading = true;
				this.$axios.get('/apim/retrieve/supplyment/', {
					params: {
						keywords: that.keywords,
						page: that.currentPage,
						pagesize: that.pageSize,
						hospital_id: that.$route.query.id
					}
				}).then(response => {
					if (response.data.code == '401') {
						that.logout();
					}
					if (response.data.data) {
						this.tableData = response.data.data;
						this.total = response.data.totalItemNum;
					} else {
						that.$message({
							message: response.errors,
							type: 'warning',
							onClose: function() {
								that.loading = false;
							}
						});
					}

				}).finally(() => {
					this.loading = false;
				});
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.fetchData();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.fetchData();
			},

			openDialog(row = null) {
				let that = this;
				this.dialogVisible = true;
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});

				if (row) {
					this.dialogTitle = '修改医院供货记录';
					this.formData = {
						...row,
					};
				} else {
					this.dialogTitle = '新增医院供货记录';
					this.formData = {
						id: null,
						supplyment_type: '',
						supplyment_secondary_agent: '',
						supplyment_method: '',
						supplyment_provider: '',
						supplyment_remark: '',
						hospital_id: that.$route.query.id
					};
				}
			},
			// 查看修历史记录
			showHistory(id) {
				let that = this;
				this.dialogHistoryVisible = true;
				this.$axios.get('/apim/history/supplyment/', {
					params: {
						id: id,
					}
				}).then(response => {
					if (response.data.history_data) {
						this.tableHistoryData = response.data.history_data;
					} else {
						that.$message({
							message: response.data.errors,
							type: 'warning',
						});
					}
				}).finally(() => {

				});
			},
			//保存记录
			saveData() {
				let that = this;
				this.$refs.form.validate((valid) => {
					if (valid) {
						const url = this.formData.id ?
							'/apim/update/supplyment/' : '/apim/create/supplyment/';
						let fd = new FormData();
						let formData = that.formData;
						//添加表单数据
						for (let key in formData) {
							fd.append(key, formData[key]);
							// console.log(formData[key])
						}
						this.$axios({
								url: url,
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(response => {
								this.dialogVisible = false;
								this.fetchData();
							})
							.catch(error => {
								if (error.response && error.response.status === 400) {
									console.log(error.response.data.non_field_errors)
									that.$message({
										message: error.response.data.non_field_errors.join(','),
										type: 'warning',
									});
								} else {
									that.$message({
										message: '保存失败，服务器错误',
										type: 'warning',
									});
								}
							});
					}
				});
			},
			// 单个删除
			deleteproductInfo(row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						let that = this;
						let fd = new FormData();
						fd.append('id', row.id);
						this.$axios({
								url: '/apim/delete/supplyment/',
								method: 'post',
								data: fd,
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							})
							.then(function(res) {
								if (res.data.code == '200') {
									that.fetchData();
								} else {
									that.$message({
										message: res.data.message,
										type: 'warning'
									});
								}
							})
							.catch(function(error) {
								console.log(error);
							});
					})
					.catch(() => {});
			},
		},
		mounted() {
			this.fetchData();
			this.ifFullScreen = document.body.clientWidth > 720 ? false : true;
		}
	};
</script>

<style></style>