<template>
	<div>

		<el-row :gutter="10" style="margin-top: 10px;">
			<el-col>
				<el-card>
					<el-tabs v-model="activeName" @tab-click="handleClick" tab-position="top">
						<el-tab-pane name="first">
							<span slot="label">
								<i class="el-icon-s-shop"></i>
								产品使用
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="d" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="second">
							<span slot="label">
								<i class="el-icon-s-custom"></i>
								医院人员
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="a" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="third">
							<span slot="label">
								<i class="el-icon-s-tools"></i>
								医院设备
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="b" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
						<el-tab-pane name="fourth">
							<span slot="label">
								<i class="el-icon-user-solid"></i>
								市场人员
							</span>
							<div>
								<transition name="el-fade-in-linear"><router-view :key="$route.fullPath" name="c" v-if="isRouterAlive" /></transition>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>
<script>
export default {
	data() {
		return {
			activeName: 'first',
			isRouterAlive: true
		};
	},
	mounted() {
	},
	methods: {
		handleClick(tab, event) {
			// console.log(tab, event);
		}
	}
};
</script>

<style>
</style>
